export function checkSymbolId(gameType, item, itemIndex, winLine, symbolId, x, y) {
    if (gameType === 10023) {
        //海盜寶藏
        if (item.current_map_data) {
            let mapLen = item.current_map_data.length
            if (mapLen > 0) {
                let finalMergePos = item.current_map_data
                const isMergePos = (pos) => pos.x === y && pos.y === x
                let changeMap = finalMergePos.findIndex(isMergePos) > -1;
                if (changeMap) {
                    symbolId = "map";
                }
            }
        }
    } else if (gameType === 10009) {
        // 夜上海
        if (item.is_lock_position[y][x]) {
            symbolId = "lock";
        }
    } else if (gameType === 10020) {

        //lock wild
        if (item.lock_position_result[y][x] <= 2) {

            if (winLine) {
                if (winLine.line_no > -1) {
                    symbolId = "lock";
                } else if (symbolId !== 3) {
                    symbolId = "lock";
                }
            } else {
                symbolId = "lock";
            }
        }

    } else if (winLine) {
        let winPosition = winLine.is_win_position;

        //Cute Panda 五神獸
        if (gameType === 10003 || gameType === 10006) {
            if (winLine.win_type === 2 && winPosition[y][x]) {
                return winLine.symbol_id;
            }
        } else if (gameType === 10022 || gameType === 10028) {
            //墮落之書展開
            if (item.expand_reel_list.length > 0 && winLine.win_type === 2 && item.expand_reel_list.includes(y)) {
                return item.expand_symbol;
            }
        }
    } else {
        if (gameType === 10005 && itemIndex > 0 && symbolId === 0) {
            symbolId = symbolId + "-f";
        } else if (gameType === 10002 && itemIndex > 0) {
            let idList = [2, 3, 4, 5];
            if (idList.indexOf(symbolId) > -1) {
                symbolId = symbolId + "-f";
            }
        }
    }

    return symbolId;
}

export function updateGameDate(gameType, gameData) {
    // lock wild 遊戲盤面處理
    let lockWildGameTypeList = [10013, 10015, 10023, 10025];
    if (lockWildGameTypeList.includes(gameType)) {
        for (let index = 2; index < gameData.length; index++) {
            let beforeResult = gameData[index - 1].position_result;
            let nowResult = gameData[index].position_result;

            let length = beforeResult.length;
            for (let i = 0; i < length; i++) {
                let length1 = beforeResult[i].length;
                for (let j = 0; j < length1; j++) {
                    if (beforeResult[i][j] === 0) {
                        nowResult[i][j] = 0;
                    }
                }
            }
        }
    }

    gameData.forEach(value => value.page = 1);

    return gameData;
}

export function betMultiple(gameType) {
    let bet = {
        10001: [25],
        10002: [20],
        10003: [50],
        10004: [50],
        10005: [25],
        10006: [50],
        10007: [5],
        10008: [88, 68, 38, 18, 8],
        10009: [25],
        10010: [25],
        10012: [25],
        10013: [50],
        10014: [20],
        10015: [50],
        10016: [25],
        10017: [20],
        10018: [20],
        10019: [9],
        10020: [30],
        10021: [25],
        10022: [10],
        10023: [25],
        10024: [20],
        10025: [50],
        10026: [20],
        10027: [20],
        10028: [10],
        10029: [20],
        10030: [20],
        10031: [20],
        10032: [20],
        10033: [20],
        10034: [20],
        10036: [20],
        20002: [1],
    };
    return bet[gameType]
}

export function getMaxBet(gameType, item) {
    let multipleElement = betMultiple(gameType)[0];
    let deno = Math.min(...item.rule.deno_list);
    let betIndex = Math.max(...item.chips_range);
    return betIndex * multipleElement / deno;
}

export function getBetList(gameType, item, isArray) {
    let multipleList = betMultiple(gameType);
    let denoLength = item.rule.deno_list.length;
    let multiLength = multipleList.length;
    let strList = [];
    for (let multipleIndex = 0; multipleIndex < multiLength; multipleIndex++) {
        let multipleElement = multipleList[multipleIndex];
        let betList = [];
        for (let i = 0; i < denoLength; i++) {
            let deno = item.rule.deno_list[i];
            let map = item.chips_range.slice(0).map(num => num * multipleElement / deno);
            betList.splice(0, 0, ...map);
        }
        if (isArray) {
            strList.push(betList);
        } else {
            strList.push(betList.join(" , "));
        }
    }
    if (isArray) {
        return strList;
    } else {
        return strList.join("<br>");
    }
}

export function checkExplodeSymbolId(gameType, symbolId, winInfo, x, y) {
    if (gameType === 10017 || gameType === 10018 || gameType === 10034) {
        if (winInfo.turn_wild_frame[y][x]) {
            symbolId = 0;
        }
    } else if (gameType === 10027) {
        if (symbolId === undefined) {
            symbolId = "x";
        }
    }
    return symbolId;
}


export function checkCrushSymbolId(gameType, symbolId, winInfo, x, y) {
    /*
    雷神:10024
    呂布:10026
    七龍珠:10029
    海賊王2:10032
    戰神賽特:10033
     */
    const crushSymbolIdList = [10024, 10026, 10029, 10032, 10033, 10036]

    if (crushSymbolIdList.includes(gameType)) {
        let multiplyList = winInfo.multiply_info_list
        // 倍數symbolID為1
        if (symbolId === 1 && multiplyList.length > 0) {
            for (let i = 0; i < multiplyList.length; i++) {
                const item = multiplyList[i];
                if (item.x === y && item.y === x) {
                    symbolId = `1_${item.color}`
                }
            }
        }

        // 戰神賽特有兩種倍數symbol，第二種symbolId為0
        if (gameType === 10033 && symbolId === 0 && multiplyList.length > 0) {
            for (let i = 0; i < multiplyList.length; i++) {
                const item = multiplyList[i];
                if (item.x === y && item.y === x) {
                    symbolId = `0_${item.color}`
                }
            }
        }
    }
    return symbolId;
}

export function checkMonopolySymbolId(gameType, symbolId, winInfo, x, y) {
    return symbolId;
}