import {countPoint, gameResultToPokerObj, getPokerClass} from "@/utils/common";

export const brnnWinType = {
    // FiveLittleCow 五小牛
    FiveLittleCow: 0,
    // FourSameCard 四炸
    FourSameCard: 1,
    // FiveJQKCard 五花牛
    FiveJQKCard: 2,
    // FourJQKCardOne10Card 四花牛
    FourJQKCardOne10Card: 3,
    // NiuNiu 牛牛
    NiuNiu: 4,
    // Niu9 牛9
    Niu9: 5,
    // Niu8 牛8
    Niu8: 6,
    // Niu7 牛7
    Niu7: 7,
    // Niu6 牛6
    Niu6: 8,
    // Niu5 牛5
    Niu5: 9,
    // Niu4 牛4
    Niu4: 10,
    // Niu3 牛3
    Niu3: 11,
    // Niu2 牛2
    Niu2: 12,
    // Niu1 牛1
    Niu1: 13,
    // NoNiu 沒牛
    NoNiu: 14
};

/**
 * 百人牛牛
 * @param {Object[]} pokerList
 * @param {number} pokerList.point 點數
 * @param {number} pokerList.suit 花色
 */
export function brnnCountWinResult(pokerList) {

    let counts = countPoint(pokerList);

    if (is5LittleCow(pokerList)) {
        return brnnWinType.FiveLittleCow;
    } else if (isFourSameCard(pokerList, counts)) {
        return brnnWinType.FourSameCard;
    } else if (isFiveJQKCard(pokerList)) {
        return brnnWinType.FiveJQKCard;
    } else if (isFourJQKCardOne10Card(pokerList, counts)) {
        return brnnWinType.FourJQKCardOne10Card;
    } else {
        return isNiuNiu(pokerList);
    }
}

export function is5LittleCow(pokerList) {
    let everySmall5 = pokerList.every(poker => poker.point < 5);
    let sum = pokerList
        .map(poker => poker.point)
        .reduce((accumulator, currentValue) => accumulator + currentValue);

    return everySmall5 && sum <= 10;
}

export function isFourSameCard(pokerList, counts) {
    let max = Math.max(...counts);
    return max >= 4;
}

export function isFiveJQKCard(pokerList) {
    return pokerList
        .map(poker => poker.point)
        .every(point => point > 10);
}

export function isFourJQKCardOne10Card(pokerList, counts) {
    let everyOver10 = pokerList
        .map(poker => poker.point)
        .every(point => point >= 10);
    return everyOver10 && counts[10] === 1;
}

export function isNiuNiu(pokerList) {
    let before3Sum = pokerList
        .slice(0, 3)
        .map(poker => poker.point > 10 ? 10 : poker.point)
        .reduce((accumulator, currentValue) => accumulator + currentValue);

    if (before3Sum % 10 === 0) {
        let after2Sum = pokerList
            .slice(3, 5)
            .map(poker => poker.point > 10 ? 10 : poker.point)
            .reduce((accumulator, currentValue) => accumulator + currentValue);
        let number = after2Sum % 10;

        if (number === 0) {
            return brnnWinType.NiuNiu;
        }

        return brnnWinType.NoNiu - number;
    } else {
        return brnnWinType.NoNiu;
    }
}

export function brnnGameResult(gameResult, self) {
    let htmlStr = "";

    htmlStr += "<span class='mb-2 d-block'>" + brnnGameResultStr(0, gameResult, 1, self) + "</span>";
    htmlStr += "<span class='mb-2 d-block'>" + brnnGameResultStr(10, gameResult, 2, self) + "</span>";
    htmlStr += "<span class='mb-2 d-block'>" + brnnGameResultStr(20, gameResult, 3, self) + "</span>";
    htmlStr += "<span class='mb-2 d-block'>" + brnnGameResultStr(30, gameResult, 4, self) + "</span>";
    htmlStr += "<span class='d-block'>" + brnnGameResultStr(40, gameResult, 5, self) + "</span>";

    return htmlStr;
}

export function brnnGameResultStr(start, gameResult, area, self) {
    let pokerList = [
        gameResultToPokerObj(gameResult[start], gameResult[start + 1]),
        gameResultToPokerObj(gameResult[start + 2], gameResult[start + 3]),
        gameResultToPokerObj(gameResult[start + 4], gameResult[start + 5]),
        gameResultToPokerObj(gameResult[start + 6], gameResult[start + 7]),
        gameResultToPokerObj(gameResult[start + 8], gameResult[start + 9]),
    ];
    let winType = brnnCountWinResult(pokerList);

    let html = i18n.t('game.area.106.' + area) + " : " + i18n.t('game.area.106.winType.' + winType);

    let porkCss = [];

    pokerList.forEach(value => {
        porkCss.push(`<span class='mr-1 align-self-center'>${getPokerClass(value.suit -1 , value.point -1)}</span>`)
    })

    let htmlStr = `<div class="d-flex mt-1"><span class="align-self-center mr-1">${html}</span>`;
    htmlStr += porkCss.join("");
    htmlStr += "</div>"

    return htmlStr;
}
