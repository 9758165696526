export default {
    betRang: "Bet Range",
    minBet: "MiniBet",
    maxBet: "MaxBet",
    updateGameBetRule: "Slot Limit",
    updateGameBetRuleLimit: "Update Choosiable Bet Limits of SubAgent",
    winLineCount: "Total Winline",
    baseGame: "MainGame",
    freeGame: "FreeGame",
    get: 'Get ',
    used: 'Use ',
    point: 'Point',
    total: 'Total ',
    hold: 'Hold ',
    multiplier: "Multiplier",
    wagerType: {
        0: "一般",
        1: "<span class='text-danger'>殺數</span>",
        2: "<span class='text-warning'>註銷</span>"
    },
    "10017": {
        box: "Heavenly Blessings"
    },
    payForFreeGame: "Buy Free Games",
    payForSuperSpin: "Buy Super Spin",
    payForDoubleChance: "Increase Chances Of Getting Free Games",
    "10029": {
        dragonBallCollection: "Dragon Ball Collection",
        dragonBallTitle: "Dragon Ball Roulette Awards",
        freeTimes: "Free Times",
        freeMultiplier: "Free Multiplier"
    },
    count: "Count",
    '10031': {
        awardsTitle: {
            'X': 'Out',
            'T': 'Treasure',
            'S': 'Scratch',
            'W': 'Wheel',
            'M': 'Multiple',
        },
        start: 'Start',
        protection: 'Protection',
        dice: 'Dice',
        mapSymbol: 'Map Grid',
        currentRound: 'Current Round',
        specialResult: 'Special Game Results',
    },
    "10032": {
        battleTitle: "Battle Result",
        no: "Round",
        round: "",
        upgrade: "Upgrade",
        gear: "Gear",
        freeEnergy: "Free Energy",
        gear1: "BIG BONE Luffy",
        gear2: "Bounce-man Luffy",
        gear3: "NIKA Luffy",
        energy: "Energy",
    },
    "10033": {
        multiplierUpgrade: "Multiplier Upgrade"
    },
}
