<template>
    <div v-if="gameData">
        <span class="d-block">{{ $t('game.20002Content.first') }}</span>
        <b-table
            :fields="getFields()"
            :items="getItems()"
            striped
            bordered
            hover
        >

        </b-table>
        <span class="d-block mt-2">{{ $t('game.20002Content.second') }}</span>
        <div>
            <div class="text-golden d-block">
                <span class="font-weight-bold collect-word mr-1">{{
                        getCollectWord(this.gameData[0].now_game_data.character - 2)
                    }}</span>
                <span v-html="getCollectWinPointHtml()"></span>
            </div>
            <span>{{ $t('game.20002Content.collect') }}</span> : {{ getAlreadyCollect() }}
        </div>
        <div>
            <span class="d-block mt-2">{{ $t('game.20002Content.third') }}</span>
            <span v-html="getLuckyHtml()"></span>
        </div>

    </div>
</template>

<script>
export default {
    name: "SlotFiveBlessingsDetail",
    props: {
        item: {bet_point: 0},
        gameData: null,
        gameType: {type: Number, default: null, required: true},
        currencyType: {type: String, default: null, required: true}
    },
    methods: {
        getItems() {
            let list = [];
            let data = this.gameData[0].now_game_data;
            for (let key in data.normal_game_result) {
                let result = data.normal_game_result[key];
                let golden = list[0] || {};
                golden[key] = result.compare_arr[0];
                list[0] = golden;
                let black = list[1] || {};
                black[key] = result.compare_arr[1];
                list[1] = black;
                let award = list[2] || {};
                award[key] = this.convertMoney(this.currencyType, result.win_odds * this.item.bet_point);
                if (result.compare_arr[0] > result.compare_arr[1]) {
                    award._cellVariants = award._cellVariants || {};
                    award._cellVariants[key] = 'success';
                }

                list[2] = award;
            }

            return list;
        },
        getFields() {
            return [
                {
                    key: '1',
                    label: this.$t('game.20002Content.1'),
                    sortable: false
                },
                {
                    key: '2',
                    label: this.$t('game.20002Content.2'),
                    sortable: false
                },
                {
                    key: '3',
                    label: this.$t('game.20002Content.3'),
                    sortable: false
                },
                {
                    key: '4',
                    label: this.$t('game.20002Content.4'),
                    sortable: false
                },
                {
                    key: '5',
                    label: this.$t('game.20002Content.5'),
                    sortable: false
                },
            ];
        },
        getCollectWord(index) {
            let wordList = ["五", "福", "臨", "門"];
            return wordList[index];
        },
        getAlreadyCollect() {
            let data = this.gameData[0].now_game_data;
            let keys = Object.keys(data.prev_set_of_characters_map || {});
            let alreadyCollectWordList = keys.map(value => this.getCollectWord(Number(value) - 2));
            let collectWord = this.getCollectWord(data.character - 2);
            if (!alreadyCollectWordList.includes(collectWord)) {
                alreadyCollectWordList.push(collectWord);
            }
            return alreadyCollectWordList.join("、")
        },
        getCollectWinPointHtml() {
            let winLine = this.gameData[0].win_lines.find(winLine => winLine.win_type === 7) || {win_point: 0};
            let str = this.convertMoney(this.currencyType, winLine.win_point);
            let classStr = winLine.win_point > 0 ? "text-success" : ""
            return `<span class="${classStr}">(${str})</span>`;
        },
        getLuckyHtml() {
            let data = this.gameData[0].now_game_data;
            let point = data.lucky_game_result * this.item.bet_point;
            let str = this.convertMoney(this.currencyType, point);
            let classStr = point > 0 ? "text-success" : ""
            return `<span class="${classStr}">${str}</span>`;
        }
    }
}
</script>

<style scoped>
.text-golden {
    color: #FFC105;
}

.collect-word {
    font-size: 2rem;
}
</style>
