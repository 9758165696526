import BaseService from "@/service/BaseService";

export default class GameRoundService {
    static GetDetail(parameter) {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);

        let payload = {
            parameter: params.get("parameter")
        };

        let body = {
            body: JSON.stringify(payload)
        };

        return BaseService.ajax("HGR1", "/api/get_game_detail", "POST", body);
    }


}
