const game = {
    "101": "Baccarat",
    "102": "Dragon Gate Poker",
    "103": "Flame Toad",
    "104": "Dragon Tiger",
    "105": "Golden Flower",
    "106": "Niu Niu",
    "107": "Two Eight Bars",
    "108": "Lucky Wheel",
    "109": "Supercar",
    "110": "Sic Bo",
    "111": "Revolver Baccarat",
    "112": "Dice Baccarat",
    "113": "Push Bars",
    "114": "Namdaobuba",
    "115": "Climb Ladder",
    "116": "Squeeze Baccarat",
    "117": "Lottery Baccarat 5 Tube",
    "118": "Lottery Baccarat Single",
    "119": "Five Star Poker",
    "120": "Red Black War",
    "121": "XOC DIA",
    "122": "Fan Tan",
    "123": "Video Baccarat",
    "124": "Teen Patti",
    "125": "Commission Free Andar Bahar",
    "126": "Andar Bahar",
    "301": "PK Golden Flower",
    "302": "Banker Bull Bull",
    "303": "Speed Golden Flower",
    "304": "1v1 Bull Bull",
    "305": "Casino Bull Bull",
    "306": "Banker Two Eight Bar",
    "307": "Banker Push Bars",
    "308": "San Gong",
    "309": "Pai Gow",
    "310": "Banker Golden Flower",
    "311": "Baicao",
    "312": "Pok Deng",
    "313": "Black Jack",
    "314": "Catch the wave",
    "501": "Dragon Fishing",
    "502": "Aquaman Fishing",
    //火凤凰
    "10001": "Flame Phoenix",
    //埃及艳后
    "10002": "Cleopatra II",
    //Cute Panda
    "10003": "Cute Panda",
    //赢多多
    "10004": "Win Win Win",
    //齐天大圣
    "10005": "Monkey King",
    //五神兽
    "10006": "5 Divine Beasts",
    //Triple Monkey
    "10007": "Triple Monkey",
    //多彩多福
    "10008": "Good Fortune",
    //夜上海
    "10009": "Shanghai Nights",
    //众神
    "10010": "Greek Gods",
    //Ocean Party
    "10012": "Ocean Party",
    //后羿射日
    "10013": "Legend Of Hou Yi",
    //麻將來了
    "10014": "Mahjong Ways",
    //疏果精灵
    "10015": "Ooblets",
    //火凤凰
    "10016": "New Flame Phoenix",
    "10017": "Dragon Legend",
    "10018": "Shanhaizhiyi",
    "10019": "Huga",
    "10020": "Legend of the Sword",
    "10021": "Treasure Bowl",
    "10022": "Eye of Egypt",
    //海盜寶藏
    "10023": "Pirate Treasure",
    "10024": "Thor",
    "10025": "Aladdin's Lamp",
    "10026": "Legend 0f LuBu",
    "10027": "Mahjong Ways 2",
    "10028": "Ninja Warrior",
    "10029": "Dragon Ball",
    "10030": "One Piece",
    '10031': 'Monopoly',
    '10032': 'One Piece 2',
    '10033': 'STORM OF SETH',
    '10034': 'Legend Of Shenlong',
    '10036': 'Chicken Farm',
    "20002": "Five Blessings",
};

const bacc = {
    1: "Player",
    2: "Banker",
    3: "Tie",
    4: "Player Pair",
    5: "Banker Pair",
}

let diceRoadType = {
    0: "Default",
    1: "Big",
    2: "Small",
    3: "Triple",
};

let climbLadder = {
    1: "LeftStart",
    2: "RightStart",
    3: "LeftEnd",
    4: "RightEnd",
    5: "3 Ladders",
    6: "4 Ladders",
    7: "LeftEnd 3 ladders",
    8: "RightEnd 3 ladders",
    9: "LeftEnd 4 ladders",
    10: "RightEnd 4 ladders"
};

export default {
    ...game,
    init: "Obtain Game Setting",
    switchType: {
        0: "Default",
        1: "帶牌",
        2: "殺數",
    },
    gameModule: {
        title: "Game Type",
        0: "No Bet",
        1: "Hundreds",
        2: "Battle",
        3: "Arcade",
        4: "Fishing",
    },
    title: "Game Title",
    win: "Win",
    lose: "Lose",
    pay: "Pay",
    betArea: "Bet Area",
    areaName: "Bet Area",
    moveCard: "Main Card Area",
    backCard: "Extra Card",
    orderOfOpeningCard: "Drawing Order",
    licensingOrder: "Dealing Order",
    state: {
        title: "Game Status",
        sleep: "Game End",
        notEnd: "Game Unfinish"
    },
    banker: "Banker",
    player: "Player",
    tie: "Tie",
    rightStart: "RightStart",
    rightEnd: "RightEnd",
    leftStart: "LeftStart",
    leftEnd: "LeftEnd",
    numberOfLadders: "{n}Ladders",
    seat: "Seat",
    area: {
        //百家樂
        101: bacc,
        //射龍門
        102: {
            1: "Hit",
            2: "Goal",
            3: "Miss",
            4: "Red",
            5: "Black",
            6: "Big",
            7: "Small",
            8: "Odd",
            9: "Even",
        },
        //火蟾蜍
        103: climbLadder,
        //龍虎
        104: {
            1: "Dragon",
            2: "Tiger",
            3: "Tie",
        },
        //炸金花
        105: {
            1: "莊",
            2: "天",
            3: "地",
            4: "玄",
            5: "黃",
            winType: {
                1: "Triple",
                2: "Straight Flush",
                3: "Golden Flower",
                4: "Straight",
                5: "Pair",
                6: "High Card",
                7: "Different Suit 532",
            },
            isLose: "Banker win all"
        },
        //百人牛牛
        106: {
            1: "莊",
            2: "天",
            3: "地",
            4: "玄",
            5: "黃",
            winType: {
                0: "5-Small-Bull",
                1: "Bomb",
                2: "5-Face-Bull",
                3: "4-Face-Bull",
                4: "Bull-Bull",
                5: "Bull 9",
                6: "Bull 8",
                7: "Bull 7",
                8: "Bull 6",
                9: "Bull 5",
                10: "Bull 4",
                11: "Bull 3",
                12: "Bull 2",
                13: "Bull 1",
                14: "No Bull",
            },
            rateRule: {
                1: "No Bull--Bull 6",
                2: "Bull 7--Bull 9",
                3: "Bull-Bull--Bull 9",
                4: "Commission",
            },
            isLose: "Banker Win All"
        },
        //二八槓
        107: {
            2: "上門-Win",
            3: "中門-Win",
            4: "下門-Win",
            5: "上門-Lose",
            6: "中門-Lose",
            7: "下門-Lose",
            rateRule: {
                1: "Point",
                2: "Pair",
                3: "White Dragon",
                4: "Commission (Win)",
                5: "Commission (Lose)",
            },
            winType: {
                1: "0 Point",
                2: "{n}Point",
                3: "Two Eight Bars",
                4: "Pair",
            },
            block: {
                1: "莊",
                2: "上門",
                3: "中門",
                4: "下門",
            },
        },
        //森林大轉輪
        108: {
            1: "Lion (Red)",
            2: "Lion (Green)",
            3: "Lion (Yellow)",
            4: "Panda (Red)",
            5: "Panda (Green)",
            6: "Panda (Yellow)",
            7: "Monkey (Red)",
            8: "Monkey (Green)",
            9: "Monkey (Yellow)",
            10: "Rabbit (Red)",
            11: "Rabbit (Green)",
            12: "Rabbit (Yellow)",
            nowSpecialRule: {
                1: "Big Three Dragons",
                2: "Big four Happiness",
                3: "Bonus Spin",
                4: "Double Fortunate",
            }
        },
        //奔馳寶馬
        109: {
            1: "Farrari",
            2: "Lamborghini",
            3: "Maserati",
            4: "Porsche",
            5: "Lexus",
            6: "Volkswagen",
            7: "Mercedes Benz",
            8: "BMW",
        },
        //骰寶
        110: {
            1: "Triple 1",
            2: "Triple 2",
            3: "Triple 3",
            4: "Triple 4",
            5: "Triple 5",
            6: "Triple 6",
            7: "Any Triple",
            8: "Total 4",
            9: "Total 5",
            10: "Total 6",
            11: "Total 7",
            12: "Total 8",
            13: "Total 9",
            14: "Total 10",
            15: "Total 11",
            16: "Total 12",
            17: "Total 13",
            18: "Total 14",
            19: "Total 15",
            20: "Total 16",
            21: "Total 17",
            22: "Dice 1",
            23: "Dice 2",
            24: "Dice 3",
            25: "Dice 4",
            26: "Dice 5",
            27: "Dice 6",
            28: "Big",
            29: "Small",
            30: "Odd",
            31: "Even",
            roadType: diceRoadType
        },
        //左輪百家
        111: bacc,
        //骰子百家
        112: bacc,
        //推筒子
        113: {
            2: "出-Win",
            3: "川-Win",
            4: "尾-Win",
            5: "出-Lose",
            6: "川-Lose",
            7: "尾-Lose",
            rateRule: {
                1: "Point",
                2: "Pair",
                3: "White Dragon",
                4: "Commission (Win)",
                5: "Commission (Lose)",
            },
            block: {
                1: "莊",
                2: "出",
                3: "川",
                4: "尾",
            },
            winType: {
                1: "0 Point",
                2: "{n} Point",
                3: "Pair",
            },
        },
        //魚蝦蟹
        114: {
            1: "One Point(Fish)-Triple",
            2: "2 Points(Shrimp)-Triple",
            3: "3 Points(Calabash)-Triple",
            4: "4 Points(Money)-Triple",
            5: "5 Points(Crab)-Triple",
            6: "6 Points(Chicken)-Triple",
            7: "Any Triple",
            8: "Three of a color-Red",
            9: "Three of a color-Green",
            10: "Three of a color-Blue",
            11: "Any 3 colors",
            12: "Two of a color-Red",
            13: "Two of a color-Green",
            14: "Two of a color-Blue",
            15: "One of a color-Red",
            16: "One of a color-Green",
            17: "One of a color-Blue",
            18: "Total-4 Points",
            19: "Total-5 Points",
            20: "Total-6 Points",
            21: "Total-7 Points",
            22: "Total-8 Points",
            23: "Total-9 Points",
            24: "Total-10 Points",
            25: "Total-11 Points",
            26: "Total-12 Points",
            27: "Total-13 Points",
            28: "Total-14 Points",
            29: "Total-15 Points",
            30: "Total-16 Points",
            31: "Total-17 Points",
            32: "Three Forces-1 Point",
            33: "Three Forces-2 Points",
            34: "Three Forces-3 Points",
            35: "Three Forces-4 Points",
            36: "Three Forces-5 Points",
            37: "Three Forces-6 Points",
            38: "Big",
            39: "Small",
            40: "Odd",
            41: "Even",
            roadType: diceRoadType
        },
        //爬梯子
        115: climbLadder,
        //眯牌百家
        116: bacc,
        //移牌百家(多管)
        117: bacc,
        //移牌百家(單管)
        118: bacc,
        119: {
            1: "Spades",
            2: "Hearts",
            3: "Dimonds",
            4: "Clubs",
            5: "Joker(Crown)",
        },
        120: {
            1: "Red",
            2: "Black",
            3: "Lucky Hit",
            winType: {
                1: "Triple",
                2: "Straight Flush",
                3: "Flush",
                4: "Straight",
                5: "Pair",
                6: "High Card",
            },
            rateRule: {
                1: "Red",
                2: "Black",
                3: "Pair above 9",
                4: "Straight",
                5: "Golden Flower",
                6: "Straight Flush",
                7: "Triple",
            }
        },
        121: {
            1: "Even",
            2: "Odd",
            3: "One White",
            4: "One Red",
            5: "All White",
            6: "All Red",
            red: "Red",
            white: "White",
            rateRule: {
                1: "Even",
                2: "Odd",
                3: "One White",
                4: "One Red",
                5: "All White",
                6: "All Red",
                7: "Odd/ Even Bonus",
                8: "One White/ One Red Bonus",
                9: "All White/ All Red Bonus",
            }
        },
        122: {
            1: "<i class='icon-tiny fan-tan fan-tan-1'></i> ",
            2: "<i class='icon-tiny fan-tan fan-tan-2'></i> ",
            3: "<i class='icon-tiny fan-tan fan-tan-3'></i> ",
            4: "<i class='icon-tiny fan-tan fan-tan-4'></i> ",
            5: "<i class='icon-tiny fan-tan fan-tan-5'></i> ",
            6: "<i class='icon-tiny fan-tan fan-tan-6'></i> ",
            7: "<i class='icon-tiny fan-tan fan-tan-7'></i> ",
            8: "<i class='icon-tiny fan-tan fan-tan-8'></i> ",
            9: "<i class='icon-tiny fan-tan fan-tan-9'></i> ",
            10: "<i class='icon-tiny fan-tan fan-tan-10'></i> ",
            11: "<i class='icon-tiny fan-tan fan-tan-11'></i> ",
            12: "<i class='icon-tiny fan-tan fan-tan-12'></i> ",
            13: "<i class='icon-tiny fan-tan fan-tan-13'></i> ",
            14: "<i class='icon-tiny fan-tan fan-tan-14'></i> ",
            str: {
                1: "[1]",
                2: "[2]",
                3: "[3]",
                4: "[4]",
                5: "[1、2]",
                6: "[3、1]",
                7: "[4、1]",
                8: "[2、3]",
                9: "[2、4]",
                10: "[3、4]",
                11: "[1、2、3]",
                12: "[1、2、4]",
                13: "[1、3、4]",
                14: "[2、3、4]",
            }
        },
        //視訊百家
        123: bacc,
        124: {
            1: "PlayerA",
            2: "PlayerB",
            3: "Tie",
            4: "PlayerAPlus",
            5: "PlayerBPlus",
            6: "SixCardBonus",
            winType: {
                1: "SixAKQJ10StraightFlush", // 六張的 同花順AKQJ10
                2: "SixStraightFlush",       // 六張的 同花順
                3: "SixFourOfKing",          // 六張的 鐵支
                4: "SixFullHouse",           // 六張的 葫蘆
                5: "SixFlush",               // 六張的 同花
                6: "SixStraight",            // 六張的 順子
                7: "SixThreeOfKing",         // 六張的 三條
                8: "SixNormal",              // 六張的 高牌
                9: "AAAThreeOfKing",         // 三張的 三條AAA
                10: "ThreeOfKing",           // 三張的 三條
                11: "StraightFlush",         // 三張的 同花順
                12: "Straight",              // 三張的 順子
                13: "Flush",                 // 三張的 同花
                14: "TwoPairs",              // 三張的 對子
                15: "HighCard"               // 三張的 高牌
            },
            rateRule: {
                1: "PlayerA",
                2: "PlayerB",
                3: "Tie",
                4: "TwoPairs",
                5: "Flush",
                6: "Straight",
                7: "StraightFlush",
                8: "ThreeOfKing",
                9: "AAAThreeOfKing",
                10: "SixThreeOfKing",
                11: "SixStraight",
                12: "SixFlush",
                13: "SixFullHouse",
                14: "SixFourOfKing",
                15: "SixStraightFlush",
                16: "SixAKQJ10StraightFlush",
            }
        },
        125: {
            1: "Andar",
            2: "Bahar",
            3: "First card wins pays"
        },
        126: {
            1: "Andar",
            2: "Bahar",
            3: "First card wins pays"
        }
    },
    mj: {
        1: "White Dragon",
        2: "One of Circles",
        3: "Two of Circles",
        4: "Three of Circles",
        5: "Four of Circles",
        6: "Five of Circles",
        7: "Six of Circles",
        8: "Seven of Circles",
        9: "Eight of Circles",
        A: "Nine of Circles",
    },
    "20002Content": {
        first: "First prize: Five blessings in a row ",
        second: "Second prize: Collecting letters",
        third: "Third prize: Lucky prize",
        1: "First blessing",
        2: "Second blessing",
        3: "Third blessing",
        4: "Fourth blessing",
        5: "Fifth blessing",
        Collect: "Cumulative so far"
    },
    "10019Content": {
        centerReel: "center reels",
        mari: "mari"
    }
}
