<template>
    <b-form>
        <b-form-group
            label="SID"
            label-for="game-type"
            :label-cols="3"
        >
            <div id="game-type" class="col-form-label">{{ detail.sid }}</div>
        </b-form-group>

        <b-form-group
            :label="$t('gameType')"
            label-for="game-type"
            :label-cols="3"
        >
            <div id="game-type" class="col-form-label">{{ $t('game.' + detail.game_type) }}</div>
        </b-form-group>

        <b-form-group
            :label="$t('account')"
            label-for="account"
            :label-cols="3"
        >
            <div id="account" class="col-form-label">{{ detail.account }}
            </div>
        </b-form-group>

        <b-form-group
            :label="$t('player')"
            label-for="player"
            :label-cols="3"
        >
            <div id="player" class="col-form-label">{{ detail.player_name }}
            </div>
        </b-form-group>

        <b-form-group
            label="IP"
            label-for="account"
            :label-cols="3"
        >
          <div id="ip" class="col-form-label">{{ detail.client_ip }}</div>
        </b-form-group>

        <b-form-group
            :label="$t('device')"
            label-for="account"
            :label-cols="3"
        >
            <div id="ip" class="col-form-label">{{ detail.device }}</div>
        </b-form-group>

        <b-form-group
            :label="$t('gameRound')"
            label-for="game-round"
            :label-cols="3"
        >
            <div id="game-round" class="col-form-label">{{ getGameRoundStr() }}</div>
        </b-form-group>

        <b-form-group
            :label="$t('totalBet')"
            label-for="bet"
            :label-cols="3"
        >
            <div id="bet" class="col-form-label">{{ convertMoney(getCurrency(), detail.bet_point, true) }}
            </div>
        </b-form-group>

        <b-form-group
            :label="$t('totalWin')"
            label-for="win"
            :label-cols="3"
        >
            <div id="win" class="col-form-label">{{ convertMoney(getCurrency(), detail.win_point, true) }}
            </div>
        </b-form-group>

        <b-form-group
            :label="$t('playerProfit')"
            label-for="player-profit"
            :label-cols="3">

            <div v-if="detail.profit >= 0 " class="col-form-label text-success">
                {{ convertMoney(getCurrency(), detail.profit, true) }}
            </div>
            <div v-else class="col-form-label text-danger">
                {{ convertMoney(getCurrency(), detail.profit, true) }}
            </div>
        </b-form-group>

        <!--建立時間-->
        <b-form-group
            :label="$t('createTime')"
            label-for="create-time"
            :label-cols="3"
        >
            <div id="game-create-time" class="col-form-label">{{ changeTimeZone(detail.create_time) }}
            </div>
        </b-form-group>

        <b-form-group
            :label="$t('detail')"
            label-for="create-time"
            :label-cols="3"
        >
            <game-result
                :room-id="detail.room_id"
                :table-id="detail.table_id"
                :game-type="detail.game_type"
                :game-result="detail.game_result"/>
        </b-form-group>

        <game-round-bet-area-table
            v-if="detail.game_type !== 111 && detail.game_type !== 112"
            :game-type="detail.game_type"
            :currency-type="getCurrency()"
            :items="changeMemoToArea()"/>

        <div
            v-else
            v-for="(item, index) in changeMemoToArea()"
            :key="index"
        >
            <p class="mb-2">{{$t('no')}}:{{ detail.room_id + '-' + detail.table_id + '-' + item.id }}</p>
            <game-round-bet-area-table
                class="border-bottom border-success"
                :game-type="detail.game_type"
                :currency-type="getCurrency()"
                :items="item.children"/>
        </div>

    </b-form>
</template>

<script>
import GameRoundBetAreaTable from "@/components/hundred/GameRoundBetAreaTable";
import GameResult from "@/components/hundred/GameResult";
import {convertObjectToArray} from "@/utils/common";

export default {
    name: "HundredGameDetail",
    components: {
        GameRoundBetAreaTable,
        GameResult,
    },
    props: {
        detail: {type: Object, default: {}, required: false}
    },
    methods: {
        getGameRoundStr() {
            return this.detail.room_id + "-" + this.detail.table_id + "-" + this.detail.game_round;
        },
        getCurrency() {
            return this.detail.cur_name;
        },
        changeMemoToArea() {
            let obj = JSON.parse(this.detail.bet_result);
            let array = convertObjectToArray(obj);
            let items = [];
            let length = array.length;
            for (let i = 0; i < length; i++) {
                if (this.detail.game_type !== 111 && this.detail.game_type !== 112) {
                    items.push({
                        win: array[i].win,
                        bet: array[i].bet,
                        profit: Number((array[i].win - array[i].bet).toFixed(2)),
                        area: array[i].id
                    });
                } else {

                    let children = [];

                    for (let key in array[i]) {
                        if (key !== "id") {
                            array[i][key];
                            children.push({
                                win: array[i][key].win,
                                bet: array[i][key].bet,
                                profit: Number((array[i][key].win - array[i][key].bet).toFixed(2)),
                                area: key
                            })
                        }
                    }

                    items.push({
                        id: array[i].id,
                        children: children
                    });
                }
            }

            return items;
        },

    }
}
</script>

<style scoped>

</style>
