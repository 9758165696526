import commonTW from "@/local/common/commonTW";
import gameTw from "@/local/game/gameTw";
import errorTW from "@/local/error/errorTW";
import SlotTW from "@/local/slot/slotTW";
import pkTW from "@/local/pk/pkTW";
import stgTW from "@/local/stg/stgTW";

export default {
    ...commonTW,
    slot: SlotTW,
    pk: pkTW,
    stg: stgTW,
    game: gameTw,
    ...errorTW,

}
