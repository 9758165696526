const nnWinType = {
    1: "五小牛 <span class='text-primary'>4倍</span>",
    2: "四炸 <span class='text-primary'>4倍</span>",
    3: "五花牛 <span class='text-primary'>4倍</span>",
    4: "四花牛 <span class='text-primary'>4倍</span>",
    5: "牛牛 <span class='text-primary'>3倍</span>",
    6: "牛九 <span class='text-primary'>2倍</span>",
    7: "牛八 <span class='text-primary'>2倍</span>",
    8: "牛七 <span class='text-primary'>2倍</span>",
    9: "牛六 <span class='text-primary'>1倍</span>",
    10: "牛五 <span class='text-primary'>1倍</span>",
    11: "牛四 <span class='text-primary'>1倍</span>",
    12: "牛三 <span class='text-primary'>1倍</span>",
    13: "牛二 <span class='text-primary'>1倍</span>",
    14: "牛一 <span class='text-primary'>1倍</span>",
    15: "没牛 <span class='text-primary'>1倍</span>",
};

export default {
    choose1to6:"只能选 1 ~ 6组",
    antes: "底注",
    entranceLimitOdds: "准入倍率",
    maxBetOdds: "限红倍率",
    people: "人数",
    isPVP: "是否真人对战",
    isBot: "机器人",
    yes: "是",
    no: "否",
    isSafe: "买保险",
    isSideBet: "额外押注",
    isSplit: "分牌",
    betRatio:"押注 {num} 倍",
    bankerRatio:"庄家 {num} 倍",
    nonSnatch: "不抢庄",
    reasonForOut: {
        1: "弃牌",
        2: "比牌失败",
        3: "比牌失败"
    },
    riskControl: {
        0: "一般",
        1: "<span class='text-danger'>杀数</span>"
    },
    //对战炸金花
    301: {
        winType: {
            1: "豹子",
            2: "顺金",
            3: "金花",
            4: "顺子",
            5: "对子",
            6: "高牌",
            7: "特殊",
        }
    },
    //抢庄牛牛
    302: {
        winType: nnWinType
    },
    //急速炸金花
    303: {
        winType: {
            1: "豹子 <span class='text-primary'>40倍</span>",
            2: "同花顺 <span class='text-primary'>20倍</span>",
            3: "金花 <span class='text-primary'>10倍</span>",
            4: "顺子 <span class='text-primary'>6倍</span>",
            5: "对子 <span class='text-primary'>4倍</span>",
            6: "散牌 <span class='text-primary'>2倍</span>",
        }
    },
    //单挑牛牛
    304: {
        winType: {
            1: "五小牛 <span class='text-primary'>15倍</span>",
            2: "四炸 <span class='text-primary'>15倍</span>",
            3: "五花牛 <span class='text-primary'>15倍</span>",
            4: "四花牛 <span class='text-primary'>15倍</span>",
            5: "牛牛 <span class='text-primary'>10倍</span>",
            6: "牛九 <span class='text-primary'>9倍</span>",
            7: "牛八 <span class='text-primary'>8倍</span>",
            8: "牛七 <span class='text-primary'>7倍</span>",
            9: "牛六 <span class='text-primary'>6倍</span>",
            10: "牛五 <span class='text-primary'>5倍</span>",
            11: "牛四 <span class='text-primary'>4倍</span>",
            12: "牛三 <span class='text-primary'>3倍</span>",
            13: "牛二 <span class='text-primary'>2倍</span>",
            14: "牛一 <span class='text-primary'>1倍</span>",
            15: "没牛 <span class='text-primary'>1倍</span>",
        }
    },
    //通比牛牛
    305: {
        winType: nnWinType
    },
    //抢庄二八杠
    306: {
        winType: {
            1: "鳖十",
            2: "{n}点",
            3: "二八杠",
            4: "对子",
        },
    },
    //抢庄推筒子
    307: {
        winType: {
            1: "鳖十",
            2: "{n}点",
            3: "对子",
        }
    },
    308: {
        winType: {
            1: "爆玖 <span class='text-primary'>5倍</span>",
            2: "炸弹 <span class='text-primary'>4倍</span>",
            3: "三公 <span class='text-primary'>3倍</span>",
            4: "9点 <span class='text-primary'>2倍</span>",
            5: "8点 <span class='text-primary'>2倍</span>",
            6: "7点 <span class='text-primary'>2倍</span>",
            7: "6点 <span class='text-primary'>1倍</span>",
            8: "5点 <span class='text-primary'>1倍</span>",
            9: "4点 <span class='text-primary'>1倍</span>",
            10: "3点 <span class='text-primary'>1倍</span>",
            11: "2点 <span class='text-primary'>1倍</span>",
            12: "1点 <span class='text-primary'>1倍</span>",
            13: "0点 <span class='text-primary'>1倍</span>",
        }
    },
    309: {
        winType: {
            1: "单牌0点",
            2: "单牌1点",
            3: "单牌2点",
            4: "单牌3点",
            5: "单牌4点",
            6: "单牌5点",
            7: "单牌6点",
            8: "单牌7点",
            9: "单牌8点",
            10: "单牌9点",
            11: "地高九",
            12: "天高九",
            13: "地杠",
            14: "天杠",
            15: "地王",
            16: "天王",
            17: "杂五",
            18: "杂七",
            19: "杂八",
            20: "杂九",
            21: "双零霖",
            22: "双高脚",
            23: "双红头",
            24: "双斧头",
            25: "双板凳",
            26: "双长三",
            27: "双梅",
            28: "双鹅",
            29: "双人",
            30: "双地",
            31: "双天",
            32: "至尊",
        }
    },
    310: {
        winType: {
            1: "豹子",
            2: "顺金",
            3: "金花",
            4: "顺子",
            5: "对子",
            6: "高牌",
            7: "特殊",
        }
    },
    //越南扑克
    311: {
        winType: {
            1: "三张人脸",
            2: "九点",
            3: "八点",
            4: "七点",
            5: "六点",
            6: "五点",
            7: "四点",
            8: "三点",
            9: "二点",
            10: "一点",
            11: "爆牌"
        }
    },
    312: {
        winType: {
            1: "Pok9 X2",
            2: "Pok9",
            3: "Pok8 X2",
            4: "Pok8",
            5: "Tong",
            6: "Straight Flush",
            7: "Straight",
            8: "Sam Lueang",
            9: "Taem X3",
            10: "Taem X2",
            11: "Taem"
        }
    },
    //21点
    313: {
        winType: {
            1: "Black Jack",
            2: "五小龙",
            3: "{n}點",
        }
    },
    //比大小
    314: {
        winType: {
            1: "爆牌",
            2: "过一张",
            3: "过二张",
            4: "过三张",
            5: "过四张",
            6: "过五张",
            7: "过六张",
            8: "过七张",
        }
    },
}
