<template>
    <b-table
        v-if="gameData"
        striped
        hover
        responsive="xl"
        :items="gameData"
        :fields="getFields()">

        <template v-slot:cell(slotGUI)="data">

            <div v-if="data.index === 0" style="white-space:nowrap;" v-for="(xItem,x) in getPositionResult(data.index)"
                 :key="x">
                <i v-for="(id,y) in xItem"
                   :class="getSymbolIcon(data.index,id , x , y)" :key="y"></i>
            </div>

            <div v-if="data.index !== 0">
                <div
                    v-for="(freeGameDate,index) in gameData[data.index].now_game_data"
                    v-if="page === index + 1"
                >
                    <div>{{ $t('game.10019Content.centerReel') }}:</div>
                    <div>
                        <i v-for="(id,i) in freeGameDate.mari_center_reel_list[0]"
                           :class="getFreeGameSymbolIcon(id,freeGameDate.mari_symbol)"></i>
                    </div>
                    <div>{{ $t('game.10019Content.mari') }}:</div>
                    <div>
                        <i :class="getFreeGameSymbolIcon(freeGameDate.mari_symbol)"></i>
                    </div>
                </div>

                <b-pagination
                    class="page mt-2"
                    size="md"
                    :total-rows="gameData[data.index].now_game_data.length"
                    v-model="page"
                    per-page="1"
                    first-number
                    last-number
                ></b-pagination>

            </div>

        </template>

        <template v-slot:cell(detail)="data">
            <p style="white-space:nowrap;" v-html="getSpinTitle(data.item , data.index)">

            </p>
            <p style="white-space:nowrap;">
                {{ $t('totalWin') }} : {{ convertMoney(currencyType, data.item.win_point, true) }}
            </p>
            <p style="white-space:nowrap;">
                {{ $t('slot.winLineCount') }} : {{ getWinLineCount(data.index) }}
            </p>

            <p style="white-space:nowrap;">
                {{ $t('slot.multiplier') }} :{{ data.item.multiplier }}
            </p>

            <b-row class="m-0 p-0 border-top border-dark" v-if="getWinLineCount(data.index) > 0 && data.index === 0"
                   v-html="getOtherHtmlStr(data.index)">
            </b-row>

        </template>

    </b-table>
</template>

<script>
import {checkSymbolId} from "@/utils/Slot";

export default {
    name: "SlotHugaTable",
    props: {
        item: {bet_point: 0},
        gameData: null,
        gameType: {type: Number, default: null, required: true},
        currencyType: {type: String, default: null, required: true}
    },
    mounted() {
        if(this.item.win_point){
            this.startWinBoxAnimation();
        }
    },
    methods: {
        getFields() {
            return [
                {
                    key: 'slotGUI',
                    label: '',
                    sortable: false
                },
                {
                    key: 'detail',
                    label: this.$t('detail'),
                    tdClass: 'align-top',
                    sortable: false
                },
            ];
        },
        getPositionResult(index) {
            let positionResult = this.gameData[index].position_result;
            let symbolList = [];

            let length = positionResult.length;
            for (let i = 0; i < length; i++) {
                let length1 = positionResult[i].length;
                for (let j = 0; j < length1; j++) {
                    symbolList[j] = symbolList[j] || [];
                    symbolList[j][i] = positionResult[i][j];
                }
            }
            return symbolList;
        },
        getSymbolIcon(index, id, x, y) {
            let symbolId = Number(id);
            let item = this.gameData[index];
            let prefix = ""
            let winLines = item.win_lines || [];
            symbolId = checkSymbolId(this.gameType, item, index, null, symbolId, x, y);
            if (winLines.length > 0) {
                let winLine = winLines[this.winLineIndex % item.win_lines.length];
                symbolId = checkSymbolId(this.gameType, item, index, winLine, symbolId, x, y);
                let winPosition = winLine.is_win_position;
                if (winPosition[y][x]) {
                    prefix = this.winLineBoxShow ? "winLineBox win" : "";
                }
            }

            return `icon-small slot-${this.gameType} slot-${this.gameType}-${symbolId}  ${prefix}`;
        },
        getWinLineCount(index) {
            let item = this.gameData[index];
            let winLines = item.win_lines || [];
            return winLines.length;
        },
        getOtherHtmlStr(index) {
            let html = "";

            let item = this.gameData[index];
            let winLines = item.win_lines || [];

            let length = winLines.length;
            let winLineIndex = this.winLineIndex % length;
            for (let i = 0; i < length; i++) {
                let winLine = winLines[i];

                let point = winLine.win_point;
                point = this.convertMoney(this.currencyType, point, true);

                let prefix = "";
                if (winLineIndex === i) {
                    prefix = "winLineBox";
                }

                let lineGameType = [10001, 10002, 10005, 10013, 10015, 10016];

                if (lineGameType.indexOf(this.gameType) > -1) {
                    if (winLine.win_point > 0) {
                        html += `<div class="col col-xl-3 col-lg-4 col-md-7 col-sm-7 mb-2 mt-2">`
                        html += `<i class="row mb-1 icon-big slot-line-${this.gameType} slot-line-${this.gameType}-${winLine.line_no + 1} ${prefix}"></i>`
                        if (winLine.line_no > -1) {
                            html += `<span class="row text-info" style="white-space:nowrap;">LinNo : ${winLine.line_no + 1}</span>`;
                        }
                        html += `<span class="row" style="white-space:nowrap;">${point}</span>`;
                        html += "</div>";
                    }
                } else {
                    if (prefix.length > 0) {
                        prefix = "border-danger border"
                    }

                    html += `<span class="mr-2 mt-1 mb-1 p-2 ${prefix}">${i + 1} : ${point}</span>`
                }
            }

            return html;
        },
        getSpinTitle(item, index) {
            let title = "[ " + item.game_state;
            if (index > 0) {
                title += "<span class='text-success ml-1'>" + index + "</span>";

            }
            return title + " ]";
        },
        updateFrame(timestamp) {
            if (!this.beforeTimestamp) {
                this.beforeTimestamp = timestamp;
            }

            let dt = timestamp - this.beforeTimestamp;

            if (dt > 300) {
                this.beforeTimestamp = timestamp;
                this.winLineBoxShow = !this.winLineBoxShow;
                this.winLineBoxCount++;
            }

            if (this.winLineBoxCount === 6) {
                this.winLineBoxCount = 0;
                this.winLineIndex++;
            }

            this.frameId = window.requestAnimationFrame(this.updateFrame);
        },
        startWinBoxAnimation() {
            this.page = 1;
            this.frameId = window.requestAnimationFrame(this.updateFrame);
        },
        reset() {
            this.frameId = null;
            this.winLineIndex = 0;
            this.beforeTimestamp = null;
            this.winLineBoxShow = true;
            this.winLineBoxCount = 0;
        },
        close() {
            this.frameId && window.cancelAnimationFrame(this.frameId);
            this.frameId = null;
        },
        getFreeGameSymbolIcon(symbolId, mariSymbolId) {
            let css = "";
            if (mariSymbolId) {
                if (symbolId === mariSymbolId) {
                    css = "winLineBox win";
                }
            }

            return `icon-small slot-${this.gameType} slot-${this.gameType}-${symbolId} ${css}`;
        }
    },
    data() {
        return {
            frameId: null,
            beforeTimestamp: null,
            winLineIndex: 0,
            winLineBoxShow: true,
            winLineBoxCount: 0,
            page: 1
        }
    },
    destroyed() {
        this.close();
    }
}
</script>

<style scoped>
.win {
    box-shadow: 0 4px 20px #ff5e5e, 0 4px 20px #ff0000 inset !important;
}
</style>
