import {countPoint, gameResultToPokerObj, getPokerClass} from "@/utils/common";

export const GDFWinType = {
    // ThreeOfKing 豹子(三條)
    ThreeOfKing: 1,
    // StraightFlush 同花順
    StraightFlush: 2,
    // Flush 同花
    Flush: 3,
    // Straight 順子
    Straight: 4,
    // TwoPairs 一對
    TwoPairs: 5,
    // HighCard 高牌
    HighCard: 6,
};

/**
 * 炸金花
 * @param {Object[]} pokerList
 * @param {number} pokerList.point 點數
 * @param {number} pokerList.suit 花色
 */
export function gdfCountWinResult(pokerList) {

    let counts = countPoint(pokerList);

    if (isThreeOfKing(pokerList)) {
        return GDFWinType.ThreeOfKing;
    } else if (isStraightFlush(pokerList, counts)) {
        return GDFWinType.StraightFlush;
    } else if (isFlush(pokerList)) {
        return GDFWinType.Flush;
    } else if (isStraight(pokerList, counts)) {
        return GDFWinType.Straight
    } else if (isTwoPairs(pokerList, counts)) {
        return GDFWinType.TwoPairs;
    } else {
        return GDFWinType.HighCard;
    }

}

export function isThreeOfKing(pokerList) {
    let point = pokerList[0].point
    return pokerList.every(poker => poker.point === point);
}

export function isStraightFlush(pokerList, counts) {
    return isFlush(pokerList) && isStraight(pokerList, counts);
}

export function isFlush(pokerList) {
    let suit = pokerList[0].suit;
    return pokerList.every(poker => poker.suit === suit);
}

export function isStraight(pokerList, counts) {
    let every = pokerList.every((poker, index, arr) => index > 0 ? poker.point === arr[index - 1].point - 1 : true);
    return every || (counts[1] === 1 && counts[12] === 1 && counts[13] === 1);
}

export function isTwoPairs(pokerList, counts) {
    let countA = counts[pokerList[0].point];
    let countB = counts[pokerList[1].point];

    return countA >= 2 || countB >= 2;
}

export function gdfGameResult(gameResult) {
    let htmlStr = "";

    htmlStr += "<span class='mb-2 d-block'>" + gdfGameResultStr(0, gameResult, 1) + "</span>";
    htmlStr += "<span class='mb-2 d-block'>" + gdfGameResultStr(6, gameResult, 2) + "</span>";
    htmlStr += "<span class='mb-2 d-block'>" + gdfGameResultStr(12, gameResult, 3) + "</span>";
    htmlStr += "<span class='mb-2 d-block'>" + gdfGameResultStr(18, gameResult, 4) + "</span>";
    htmlStr += "<span class='d-block'>" + gdfGameResultStr(24, gameResult, 5) + "</span>";

    return htmlStr;
}

export function gdfGameResultStr(start, gameResult, area, self) {
    let pokerList = [
        gameResultToPokerObj(gameResult[start], gameResult[start + 1]),
        gameResultToPokerObj(gameResult[start + 2], gameResult[start + 3]),
        gameResultToPokerObj(gameResult[start + 4], gameResult[start + 5])
    ];
    let winType = gdfCountWinResult(pokerList);

    let html = i18n.t('game.area.105.' + area) + " : " + i18n.t('game.area.105.winType.' + winType);

    let porkCss = [];

    pokerList.forEach(value => {
        porkCss.push(`<span class='mr-1 align-self-center'>${getPokerClass(value.suit -1 , value.point -1)}</span>`)
    })

    let htmlStr = `<div class="d-flex mt-1"><span class="align-self-center mr-1">${html}</span>`;
    htmlStr += porkCss.join("");
    htmlStr += "</div>"

    return htmlStr;
}
