export default {
    choose1to3: "只能選 1 ~ 3組",
    betRang: "押注範圍",
    minBet: "最小押注",
    maxBet: "最大押注",
    bulletCount: "子彈數量",
    updateGameBetRule: "魚機限紅",
    updateGameBetRuleLimit: "更新限制子代理可選限紅",
    winLineCount: "總連線數目",
    baseGame: "一般遊戲",
    freeGame: "免費遊戲",
    multiplier: "倍率",
    refund: "退款(押注失敗)",
    wagerType: {
        0: "一般",
        1: "<span class='text-danger'>殺數</span>",
        2: "<span class='text-warning'>註銷</span>"
    },
    odds: "賠率",
}
