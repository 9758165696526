const account = '帐号';
const password = '密码';
const name = '名称';
const input = '请输入';
const ok = "确定";
const cancel = "取消";
const add = "新增";
const update = "更新";
const agent = "代理";
const detail = "详细";

export const currency = {
    "CNY": "人民币",
    "USD": "美元",
    "EUR": "欧元",
    "JPY": "日圆",
    "VND": "越南盾",
    "AUD": "澳币",
    "TWD": "新台币",
    "MYR": "马来西亚令吉",
    "IDR": "印尼盾",
    "SGD": "新加坡元",
    "GBP": "英镑",
    "THB": "泰铢",
    "TRY": "土耳其里拉",
    "UAH": "乌克兰格里夫纳",
    "mXBT": "比特币",
    "BND": "文莱元",
    "CAD": "加拿大元",
    "MMK*": "缅元",
    "NOK": "挪威克朗",
    "SEK": "瑞典克朗",
    "ZAR": "南非兰德",
    "BDT": "孟加拉塔卡",
    "LKR": "斯里兰卡卢比",
    "INR": "印度卢比",
    "RUB": "俄罗斯卢布",
    "PLN": "波兰兹罗提",
    "AED": "阿联酋迪拉姆",
    "KHR": "柬埔寨瑞尔",
    "BRL": "巴西雷亚尔",
    "XNB": "X币(新台币1:100)",
    "XNB2": "X币(新台币1:130)",
    "VND1000": "越南盾1000倍",
    "IDR1000": "印尼盾1000倍",
    "PHP": "菲律宾披索",
    "XUSD": "XUSD币(美元1:1000)",
    "HKD": "港币",
    "KRW": "南韩元",
    "MMK": "缅甸币",
    "MMK1000": "缅甸币1000倍",
};

export default {
    credit: "额度",
    content: "內容",
    killPage: "杀数",
    keyword: "关键字",
    operationRecord: "操作纪录",
    unblock: "解除封锁",
    default: "预设",
    api: "Api",
    apiInfo: "Api讯息",
    apiDownload: "下载API({version})说明",
    sdkDownload: "下载SDK({version})",
    playerCount: "玩家数量",
    dailyReport: "报表",
    type: "类型",
    features: "功能",
    memo: "备注",
    exchangePosition: "仓位",
    showTimeZone: {
        title: "显示时区",
        detail: "美东 UTC-4"
    },
    line: "线",
    userInfo: "使用者资讯",
    unit: "单位",
    kick: "踢除",
    onlinePlayer: "在线玩家",
    currencyTable: "游戏币转换表",
    currencySign: "符号",
    realMoney: "真实货币",
    recovery: "复原",
    recoveryAgentGameSetting: "复原成代理游戏设定",
    gameMoney: "游戏币",
    reportProfit: "输赢报表(日期时间搜寻)",
    reportProfitV3: "输赢报表V3(日期时间搜寻)",
    reportProfitDay: "输赢报表(日期搜寻)",
    all: "全部",
    open: "开启",
    close: "关闭",
    success: "成功",
    failed: "失敗",
    rate: "赔率",
    update: update,
    add: add,
    detail: detail,
    time: "时间(美东 UTC-4)",
    totalValidBet: "总有效押注",
    totalBet: "总押注",
    totalBetCount: "总押注次数",
    totalWin: "总赢分",
    siteCode: "玩家帐号后缀码(Site Code)",
    siteCodeLabel: "玩家帐号后缀码Site Code(2~5英数混合)",
    validBet: "有效押注",
    profit: "代理输赢",
    playerProfit: "玩家输赢",
    hundred: "百人棋牌",
    gamePlayer: "玩家下注纪录",
    gameRound: "局号",
    machineGameRound: "机器局号",
    gameResult: "游戏结果",
    openingResult: "开奖结果",
    no: "编号",
    notAdd: "尚未" + add,
    enable: "是否启用",
    delete: "删除",
    isClose: "是否停用",
    clientGameShow: "是否开启",
    isShowLobby: "是否大厅显示",
    operating: "操作",
    defaultClientLanguage: "游戏端预设语言",
    percent: "占成",
    payMoneyForUp: "交上线",
    float: "小数点第{n}位",
    rangeError: "范围 {min}~{max}",
    ok: ok,
    zhTW: "繁體中文",
    zhCN: "简体中文",
    vi: "越南语",
    hi: "印度语",
    required: "必填",
    loadingTable: "资料载入中...",
    updateData: "资料更新中...",
    cancel: cancel,
    noData: "目前没有资料...",
    search: "搜寻",
    lan: '语言',
    balance: "余额",
    account: account,
    password: password,
    rePassword: "重复" + password,
    rePasswordError: "重复" + password + "跟" + password + "不一样",
    newPassword: "新" + password,
    reNewPassword: "重复" + "新" + password,
    reNewPasswordError: "重复" + "新" + password + "跟" + password + "不一样",
    updatePassword: update + password,
    formError: "格式错误",
    name: name,
    input: input,
    createTime: "建立时间(美东 UTC-4)",
    loginTime: "登入时间(美东 UTC-4)",
    agent: agent,
    subAgent: "子" + agent,
    addAgent: add + "子" + agent,
    updateAgent: update + "子" + agent,
    updateMyselfAgent: update + "使用者",
    agentLevel: "代理层级",
    player: "玩家",
    subAccount: "子帐号",
    addSubAccount: add + "子帐号",
    updateSubAccount: update + "子帐号",
    currency: "货币",
    device: "装置",
    gameType: "游戏类型",
    gameTypeOptions: "选择游戏类型",
    betRuleSetting: "限红设定",
    gameSetting: "游戏设定",
    gameHundredBetRuleDetail: "百人棋牌限红" + detail,
    updateGameHundredBetRule: "百人棋牌限红",
    updateGameHundredBetRuleLimit: update + "限制子代理可选限红",
    gamePkBetRuleDetail: "对战棋牌限红" + detail,
    updateGamePkBetRule: "对战棋牌限红",
    updateGamePkBetRuleLimit: update + "限制子代理可选限红",
    playerHundredBetRule: "限红设定",
    updatePlayerHundredBetRule: update + "限红设定",
    addPlayerHundredBetRule: add + "限红设定",
    chip: "筹码",
    logout: "登出",
    login: {
        title: '',
        submit: '登入'
    },
    form: {
        totalElements: "总笔数",
        size: "每页笔数",
        page: "页码"
    },
    report: {
        total: "加总",
        pageTotal: "本页加总",
        subAgentTotal: "子代理加总",
        underAgentTotal: "下线代理输赢总汇",
        playerTotal: "直属玩家输赢总汇",
        revenue: "我的营利"
    },
    minBet: "最小押注",
    maxBet: "最大押注",
    unKnowError: "发生不明错误",
    error: "发生错误",
    warning: "警告",
    az09: "必须英数(0~9 a~z A~Z)",
    noHaveGame: "没有游戏",
    playerState: {
        title: "状态",
        1: "正常",
        2: "禁止下注",
        3: "黑名单"
    },
    singleWallet: "是否单一钱包",
    singleWalletUrl: "单一钱包 url",
    singleWalletUrlError: "单一钱包 url 格式错误",

    401: "没有权限",

    version: "代理版本",
    home: "首页",
    dailyRtpReport: "RTP 报表",
    treeSearch: "包含子代理",
    count: "数量",
    list: "列表",
    maintenanceTime: "维护时间",
    maintenance: "维护",
    maintenanceTimeContent: "每周一 00:00 ~ 03:00 ",

    gameTotalReport: "游戏总报表",
    gameDailyReport: "游戏每日报表",
    playerTotalReport: "玩家总报表",
    playerTotalReportV2: "指定玩家总报表",

    ...currency,

    controlSwitch: {
        0: "一般",
        1: "<span class='text-warning'>风控</span>",
        2: "<span class='text-danger'>杀数</span>"
    },
    closeAll: "关闭全部",
    closeOther: "关闭其他",

    gameRoundMultiAccount: "局號-多帳號查詢",
    gameRoundMultiAgent: "局號-多代理查詢",
    immediateRtpList: "即時RTP",
    selectAgent: "選擇代理",
    selectSubAgent: "選擇子代理",

    grant: "授权",
    grantGame: "授权游戏",
    cancelGrant: "取消授权",
    batchGrantBtn: "批量授权游戏/取消授权游戏",
    pleaseSelect: "请选择",
    playerAccountChangeAgentIdAlert : "帐号的代理与你目前的查询代理不同<br>是否要更换代理?",

    tg: "TG",
    tgChatList: "聊天室列表",
    tgGroupSend: "发送讯息",

    selectAll:"选择全部",
    unSelectAll:"取消全部",

    hedgingTransactionSearch:"对押查询",
    repeatRange: "游戏重复出现对比率(%)",
    totalGameRound: "总局数",

    notGrant: "未授权游戏",
    autoGrantGame: "自动授权游戏",
    ignoreOpenGame: "忽略检查开启游戏",
    closeGame: "未开启游戏",
    agreeAutoGrantGameQA: "已选择的代理将会自动授权游戏，是否同意?",
    agreeIgnoreOpenGameQA: "已选择的代理将会忽略检查开启游戏，是否同意?",

    gameFirewall: "游戏防火墙",

    slotMachine: '机台',
    multiplierTable: "倍数Symbol对照表",
    gameTotalMultiplier: '赢得倍数符号加总',
    baseGameTotalMultiplier: "一般游戏累积倍数",
    freeGameTotalMultiplier: "免费游戏累积倍数",

    cascadeMultiply: "消除倍数",
};
