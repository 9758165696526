<template>
    <b-container fluid>

        <div v-if="!isLoading" class="mt-2 justify-content-end text-right">
            <b-dropdown variant="outline-primary" right toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                    <div>
                        <i class="fas fa-globe mr-1"></i>
                        <span>{{ $t('lan') }}</span>
                    </div>
                </template>
                <b-dropdown-item href="#" @click="()=>onLang('zh-CN')">
                    {{ $t('zhCN') }}
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="()=>onLang('zh-TW')">
                    {{ $t('zhTW') }}
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="()=>onLang('en')">
                    English
                </b-dropdown-item>
            </b-dropdown>
        </div>

        <div v-if="isLoading">
            <div class="text-center">
                <b-spinner label="Spinning"></b-spinner>
            </div>
        </div>

        <b-card v-else border-variant="secondary" class="mt-1 mb-3">
            <hundred-game-detail v-if="detail.game_module_type === 1" :detail="detail"/>
            <pk-game-detail v-if="detail.game_module_type === 2" :detail="detail"/>
            <slot-game-detail v-if="detail.game_module_type === 3" :detail="detail"/>
            <stg-game-detail v-if="detail.game_module_type === 4" :detail="detail"/>
            <div v-else></div>
        </b-card>
    </b-container>
</template>

<script>

import GameRoundService from "@/service/GameRoundService";
import {showError} from "@/utils/error";
import HundredGameDetail from "@/components/hundred/HundredGameDetail";
import PkGameDetail from "@/components/pk/PkGameDetail";
import StgGameDetail from "@/components/stg/StgGameDetail";
import SlotGameDetail from "@/components/slot/SlotGameDetail";

export default {
    name: 'App',
    components: {
        HundredGameDetail,
        PkGameDetail,
        SlotGameDetail,
        StgGameDetail
    },
    data() {
        return {
            isLoading: true,
            detail: {}
        }
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        onLang(lan) {
            this.$i18n.locale = lan;
            localStorage.setItem('lang', lan);
        },
        getDetail() {
            this.isLoading = true;

            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            let parameter = params.get("parameter");

            if (!parameter) {
                showError(this, new Error("Search parameter not found!"), true);
                return;
            }

            GameRoundService.GetDetail()
                .then(res => {
                    this.detail = res.data;
                })
                .catch(error => showError(this, error))
                .finally(() => {
                    this.$nextTick(() => {
                        this.isLoading = false
                    })
                });
        }
    }
}
</script>

<style>
.icon-huge {
    zoom: 1;
    -moz-transform: scale(1);
    -moz-transform-origin: 0 0;
}

.icon-big {
    zoom: 0.75;
    -moz-transform: scale(0.8);
    -moz-transform-origin: 0 0;
}

.icon-small {
    zoom: 0.55;
    -moz-transform: scale(0.6);
    -moz-transform-origin: 0 0;
}

.icon-tiny {
    zoom: 0.35;
    -moz-transform: scale(0.35);
    -moz-transform-origin: 0 0;
}

.winLineBox {
    box-shadow: 0 5px 20px #ff5e5e, 0 2px 10px #ff0000 inset;
    border-radius: 10px;
}

.alerts-border {
    box-shadow: 0 2px 10px #ff5e5e, 0 2px 10px #ff0000 inset;

    border-radius: 10px;
    animation: blink 1s;
    animation-iteration-count: infinite;
}

@keyframes blink {
    50% {
        box-shadow: 0 2px 10px #f5caca, 0 2px 10px #f66b6b inset;
    }
}

.icon-super-tiny {
    zoom: 0.3;
    -moz-transform: scale(0.3);
    -moz-transform-origin: 0 0;
}

.icon-stg {
    zoom: 0.4;
    -moz-transform: scale(0.45);
    -moz-transform-origin: 0 0;
}

</style>
