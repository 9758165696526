<template>
  <b-table
      v-if="gameData"
      striped
      hover
      responsive="xl"
      :items="gameData"
      :fields="getFields()">

    <template v-slot:cell(slotGUI)="data">
      <div
          class="fade-in"
          v-for="(winInfo,index) in gameData[data.index].win_info_list"
          v-if="gameData[data.index].page === index + 1"
      >
        <div
            v-if="winInfo.reel_position"
            style="white-space:nowrap;"
            v-for="(xItem,x) in getPositionResult(data.index)"
            :key="x"
        >

          <i v-for="(id,y) in xItem"
             :class="getSymbolIcon(winInfo, x , y)"
             :key="y"
          >
          </i>
        </div>


        <div class="mt-2">
          {{ $t('totalWin') }} : {{ convertMoney(currencyType, getWinLinesTotalWin(winInfo), true) }}
        </div>

        <div class="mt-2">
          <div v-if="isShowMultiplier() && getWinLinesMultiplier(winInfo, data.index)">
            <div>
              {{ $t('multiplierTable') }} :
            </div>
            <div class="h-25 w-25">
              <table class="table table-striped">
                <thead>
                </thead>
                <tbody>
                <tr v-for="(row,i) in getWinLinesMultiplier(winInfo, data.index)" :key="i">
                  <td v-for="(mul,j) in row" :key="j">{{ mul }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <b-pagination
          v-if="gameData[data.index].win_info_list.length > 1"
          class="page mt-2"
          size="md"
          :total-rows="gameData[data.index].win_info_list.length"
          v-model="gameData[data.index].page"
          per-page="1"
          first-number
          last-number
      ></b-pagination>
    </template>

    <template v-slot:cell(detail)="data">
      <div class="side-detail">
        <!--  標題    -->
        <p style="white-space:nowrap;" v-html="getSpinTitle(data.item , data.index)" />

        <!--  贏得分數    -->
        <p style="white-space:nowrap;">
          {{ $t('totalWin') }} : {{ convertMoney(currencyType, data.item.win_point, true) }}
        </p>

        <!--  獲獎倍率(一般遊戲)    -->
        <div v-if="data.item.game_state === 'BaseGame'" style="white-space:nowrap;">

          <p v-if="justShowMulti()">
            {{ $t('gameTotalMultiplier') }} : {{ data.item.multiplier }}
          </p>
          <p v-else>
            {{ $t('baseGameTotalMultiplier') }} : {{ data.item.multiplier }}
          </p>

        </div>


        <!--  獲獎倍率(免費遊戲)    -->
        <div v-if="data.item.game_state === 'FreeGame'" style="white-space:nowrap;">

          <p v-if="justShowMulti()">
            {{ $t('gameTotalMultiplier') }} : {{ data.item.multiplier }}
          </p>
          <p v-else>
            {{ $t('freeGameTotalMultiplier') }} : {{ getFreeGameMultiplier(data.item) }}
          </p>

        </div>

        <!--  海賊王2獨有的細單 (免費遊戲)    -->
        <div v-if="isOnePiece2() && data.item.game_state === 'FreeGame'">

          <p style="white-space:nowrap;">
            {{ $t('slot.10032.gear') }} : {{ getHeroGear(data.item.sp_gear) }}
          </p>

          <p style="white-space:nowrap;">
            {{ $t('slot.10032.freeEnergy') }} : {{ data.item.sp_energy }}
          </p>

          <div v-if="data.item.game_state === 'FreeGame' && gameData[data.index].sp_game_result">
            <p class="mt-2 p-0 border-top border-dark">
              {{ $t('slot.10032.battleTitle') }} :
            </p>
            <b-row class="ml-1 p-0" v-html="showBattleResult(gameData[data.index])" />
          </div>

        </div>

        <!--  七龍珠獨有的細單 (免費遊戲)    -->
        <div v-if="isDragonBallGame() && data.item.game_state === 'FreeGame'" >
          <p style="white-space:nowrap;">
            {{ $t('slot.10029.dragonBallCollection') }} :
          </p>
          <i v-for="(sid,index) in dgSymbolList"
             style="white-space:nowrap;"
             :class="getDragonBallCollectionSymbolIcon(gameData[data.index].dragon_ball_collection, sid)"
             :key="index"
          />
        </div>
        <b-row class="mt-2 p-0 border-top border-dark"
               v-html="showLine(gameData[data.index])" />
      </div>
    </template>


  </b-table>
</template>

<script>

import {checkCrushSymbolId} from "@/utils/Slot";

export default {
  name: "SlotCrushTable",
  props: {
    item: {bet_point: 0},
    gameData: null,
    gameType: {type: Number, default: null, required: true},
    currencyType: {type: String, default: null, required: true}
  },
  data(){
    return {
      // 龍珠symbolID編號
      dgSymbolList: [12,13,14,15,16,17,18],
    }
  },
  methods: {
    isShowMultiplier() {
      return [10024, 10026, 10029, 10032, 10033, 10036].includes(this.gameType);
    },
    isDragonBallGame() {
      return [10029].includes(this.gameType);
    },
    isOnePiece2() {
      return [10032].includes(this.gameType);
    },
    isStormOfSeth() {
      return [10033].includes(this.gameType);
    },
    justShowMulti() {
      return [10036].includes(this.gameType);
    },
    getFields() {
      return [
        {
          key: 'slotGUI',
          label: '',
          sortable: false
        },
        {
          key: 'detail',
          label: this.$t('detail'),
          tdClass: 'align-top',
          sortable: false
        },
      ];
    },
    getSpinTitle(item, index) {
      let title = "[ " + item.game_state;
      if (index > 0) {
        title += "<span class='text-success ml-1'>" + index + "</span>";

      }
      return title + " ]";
    },
    getPositionResult(index) {
      let positionResult = this.gameData[index].position_result;
      let symbolList = [];

      let length = positionResult.length;
      for (let i = 0; i < length; i++) {
        let length1 = positionResult[i].length;
        for (let j = 0; j < length1; j++) {
          symbolList[j] = symbolList[j] || [];
          symbolList[j][i] = positionResult[i][j];
        }
      }
      return symbolList;
    },
    getSymbolIcon(winInfo, x, y) {
      let symbolId = winInfo.origin_frame[y][x];
      let prefix = ""
      symbolId = checkCrushSymbolId(this.gameType, symbolId, winInfo, x, y);

      let winLines = winInfo.win_lines || [];
      let isWin = winLines.some(winLine => winLine.is_win_position[y][x]);
      if (isWin) {
        prefix = "alerts-border";
      }

      return `icon-small slot-${this.gameType} slot-${this.gameType}-${symbolId}  ${prefix}`;
    },
    getWinLinesTotalWin(winInfo) {
      let winLines = winInfo.win_lines || [];
      if (winLines.length > 0) {
        return winInfo.win_lines
            .map(winLine => winLine.win_point)
            .reduce((a, b) => a + b)
      } else {
        return 0;
      }
    },
    getWinLinesMultiplier(winInfo, index) {
      let positionResult = this.gameData[index].position_result;
      let collection = [];
      for (let i = 0; i < positionResult.length; i++) {
        let reel = positionResult[i];
        collection.push([]);
        for (let j = 0; j < reel.length; j++) {
          collection[i].push("-");
        }
      }

      let hasMul = false;
      let mulInfoList = winInfo.multiply_info_list || [];
      for (let i = 0; i < mulInfoList.length; i++) {
        hasMul = true;
        collection[mulInfoList[i].x][mulInfoList[i].y] = mulInfoList[i].multiply;
      }

      return hasMul ? this.reverseArrayXY(collection) : null;
    },
    showLine(data) {
      let winInfo = data.win_info_list[data.page - 1];

      let htmlStr = "";

      if (winInfo.win_lines) {
        let length = winInfo.win_lines.length;
        for (let i = 0; i < length; i++) {
          let winLine = winInfo.win_lines[i];
          if (winLine.win_point > 0) {
            htmlStr += `<div class="col col-xl-3 col-lg-4 col-md-7 col-sm-7 mb-2 mt-2">`
            htmlStr += `<i class="row mb-1 icon-big icon-small slot-${this.gameType} slot-${this.gameType}-${winLine.symbol_id}"></i>`
            htmlStr += `<span class="row text-info" style="white-space:nowrap;">${this.$t('slot.count')} : ${winLine.count}</span>`;
            let point = this.convertMoney(this.currencyType, winLine.win_point, true);
            htmlStr += `<span class="row" style="white-space:nowrap;">${point}</span>`;
            htmlStr += "</div>";
          }
        }
      }


      // 海賊王2-魯夫獲得能量
      if (this.isOnePiece2() && winInfo.sp_symbol_info_list.length > 0) {
        let length = winInfo.sp_symbol_info_list.length;
        for (let i = 0; i < length; i++) {
          let spSymbolInfo = winInfo.sp_symbol_info_list[i];
          htmlStr += `<div class="col col-xl-3 col-lg-4 col-md-7 col-sm-7 mb-2 mt-2">`
          htmlStr += `<i class="row mb-1 icon-big icon-small slot-${this.gameType} slot-${this.gameType}-${spSymbolInfo.symbol_id}"></i>`
          htmlStr += `<span class="row text-info" style="white-space:nowrap;">${this.$t('slot.10032.energy')}: +${spSymbolInfo.energy_point}</span>`;
          htmlStr += "</div>";
        }
      }

      // 海賊王2-魯夫換檔獎勵
      if (this.isOnePiece2() && winInfo.sp_gear_info_list.length > 0) {
        let length = winInfo.sp_gear_info_list.length;
        for (let i = 0; i < length; i++) {
          let gearUpgradeInfo = winInfo.sp_gear_info_list[i];
          htmlStr += `<div class="col col-xl-3 col-lg-4 col-md-7 col-sm-7 mb-2 mt-2">`
          htmlStr += `<i class="row mb-1 icon-big icon-small slot-${this.gameType} slot-${this.gameType}-lv_up"></i>`
          htmlStr += `<span class="row text-info" style="white-space:nowrap;">${this.$t('slot.10032.upgrade')}${this.$t('slot.10032.gear')} :</span>`;
          htmlStr += `<span class="row text-info" style="white-space:nowrap;">${this.$t('slot.10032.gear')}: ${gearUpgradeInfo.mode}</span>`;
          htmlStr += `<span class="row text-info" style="white-space:nowrap;">${this.$t('slot.multiplier')}: ${gearUpgradeInfo.multiply}</span>`;
          htmlStr += "</div>";
        }
      }

      // 戰神賽特-倍數升級資訊
      if (this.isStormOfSeth() && winInfo.multiply_upgrade_info_list.length > 0) {
        let length = winInfo.multiply_upgrade_info_list.length;
        for (let i = 0; i < length; i++) {
          let upgradeInfo = winInfo.multiply_upgrade_info_list[i];
          let oldInfo = {
            color: 0,
            multiply: 0,
          };

          for (let j = 0; j < winInfo.multiply_info_list.length; j++) {
            let mInfo = winInfo.multiply_info_list[j];
            if (mInfo.x === upgradeInfo.x && mInfo.y === upgradeInfo.y) {
              oldInfo.color = mInfo.color;
              oldInfo.multiply = mInfo.multiply;
            }
          }

          htmlStr += `<div class="col col-xl-3 col-lg-4 col-md-7 col-sm-7 mb-2 mt-2">`
          htmlStr += `<i class="row mb-1 icon-big icon-small slot-${this.gameType} slot-${this.gameType}-${upgradeInfo.symbol_id}_${upgradeInfo.color}"></i>`
          htmlStr += `<span class="row text-info" style="white-space:nowrap;">${this.$t('slot.10033.multiplierUpgrade')} :</span>`;
          htmlStr += `<span class="row text-info" style="white-space:nowrap;">×${oldInfo.multiply} ➠ ×${upgradeInfo.multiply}</span>`;
          htmlStr += "</div>";

        }
      }

      // 七龍珠-龍珠輪盤結果
      if (this.isDragonBallGame() && data.game_state === 'FreeGame' && data.dragon_ball_game_result) {
        let result = data.dragon_ball_game_result
        let rewardTypeMap = {
          1: this.$t('slot.10029.freeTimes'),
          2: this.$t('slot.10029.freeMultiplier'),
        }

        let rewardType = rewardTypeMap[result.pickup_awards.awards_type]
        htmlStr += `<div class="col col-xl-3 col-lg-4 col-md-7 col-sm-7 mb-2 mt-2">`
        htmlStr += `<span class="row" style="white-space:nowrap;">${this.$t('slot.10029.dragonBallTitle')} : </span>`;
        htmlStr += `<i class="row mb-1 icon-big icon-small slot-${this.gameType} slot-${this.gameType}-${result.pickup_awards.symbol_id}"></i>`
        htmlStr += `<span class="row text-info" style="white-space:nowrap;">${rewardType} : ${result.pickup_awards.content}</span>`;
        htmlStr += "</div>";
      }

      return htmlStr;
    },
    reverseArrayXY(arr) {
      return arr[0].map((col, i) => arr.map(row => row[i]));
    },
    // 龍珠蒐集
    getDragonBallCollectionSymbolIcon(collection, symbolId) {
      let iconClass = '';
      if (collection[symbolId]) {
        iconClass = `icon-small slot-${this.gameType} slot-${this.gameType}-${symbolId}`
      } else {
        iconClass = `icon-small slot-${this.gameType} slot-${this.gameType}-${symbolId}-gray`
      }
      return iconClass;
    },
    getFreeGameMultiplier(data) {
      // 七龍珠倍數顯示: 原始免費遊戲累積倍數 = 當前免費累積倍數 - 龍珠輪盤中獎倍數
      if (this.isDragonBallGame()) {
        let dgRes = data.dragon_ball_game_result;
        if (dgRes && dgRes.pickup_awards.awards_type === 2) {
          return data.free_multiplier - dgRes.pickup_awards.content;
        }
      }
      return data.free_multiplier;
    },
    // 海賊王2-魯夫凱多對決
    showBattleResult(data){
      let result = data.sp_game_result;
      let htmlStr = "";
      let hitTypeStrMap = {
        0: "boss",
        1: "hero",
      };

      if (result) {
        let length = result.awards_list.length;
        for (let i = 0; i < length; i++) {
          let awardsInfo = result.awards_list[i];
          htmlStr += `<div class="col col-xl-3 col-lg-4 col-md-7 col-sm-7 mb-2 mt-2">`
          htmlStr += `<i class="row mb-1 icon-big icon-small slot-${this.gameType} slot-${this.gameType}-${hitTypeStrMap[awardsInfo.hit_type]}"></i>`
          htmlStr += `<span class="row text-info" style="white-space:nowrap;">${this.$t("slot.10032.no")}${i+1}${this.$t("slot.10032.round")}:</span>`;
          htmlStr += `<span class="row text-info" style="white-space:nowrap;">${this.$t("slot.multiplier")}: ${awardsInfo.awards}</span>`;
          htmlStr += "</div>";
        }
      }

      return htmlStr;
    },
    // 海賊王2-取得檔次
    getHeroGear(gearCode){
      let gearMap = {
        0: this.$t("slot.10032.gear1"),
        1: this.$t("slot.10032.gear2"),
        2: this.$t("slot.10032.gear3"),
      };

      return gearMap[gearCode];
    },
  }
}
</script>

<style scoped>
.side-detail {
//display: inline-block;
  float:right;
  width: 520px;
}
</style>
