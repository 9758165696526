const account = 'Account ';
const password = 'Password ';
const name = 'Name';
const input = 'Input ';
const ok = "Ok ";
const cancel = "Cancel ";
const add = "Create ";
const update = "Update ";
const agent = "Agent ";
const detail = "Detail ";

export const currency = {
    "CNY": "CNY",
    "USD": "USD",
    "EUR": "EUR",
    "JPY": "JPY",
    "VND": "VND",
    "AUD": "AUD",
    "TWD": "TWD",
    "MYR": "MYR",
    "IDR": "IDR",
    "SGD": "SGD",
    "GBP": "GBP",
    "THB": "THB",
    "TRY": "TRY",
    "UAH": "UAH",
    "mXBT": "mXBT",
    "BND": "BND",
    "CAD": "CAD",
    "MMK*": "MMK",
    "NOK": "NOK",
    "SEK": "SEK",
    "ZAR": "ZAR",
    "BDT": "BDT",
    "LKR": "LKR",
    "INR": "INR",
    "RUB": "RUB",
    "PLN": "PLN",
    "AED": "AED",
    "KHR": "KHR",
    "BRL": "BRL",
    "XNB": "XNB(TWD 1:100)",
    "XNB2": "XNB(TWD 1:130)",
    "VND1000": "VNDx1000",
    "IDR1000": "IDRx1000",
    "PHP": "PHP",
    "XUSD": "XUSD(USD 1:1000)",
    "HKD": "HKD",
    "KRW": "KRW",
    "MMK": "MMK",
    "MMK1000": "MMKx1000",
};

export default {
    credit: "credit",
    content: "content",
    killPage: "Kill",
    keyword: "Keyword",
    operationRecord: "Operation Record",
    unblock: "Unblock",
    default: "Default",
    api: "Api",
    apiInfo: "Api Info",
    apiDownload: "Download API({version})Instruction",
    sdkDownload: "Download SDK({version})",
    playerCount: "Number of Players",
    dailyReport: "Daily Report",
    type: "Type",
    features: "Features",
    memo: "Memo",
    exchangePosition: "Position",
    showTimeZone: {
        title: "Time Zone Display",
        detail: "Eastern US UTC-4"
    },
    line: "Line",
    userInfo: "User Info",
    unit: "Unit",
    kick: "Kick",
    onlinePlayer: "Online Users",
    currencyTable: "Currency Table",
    currencySign: "Dollar Sign",
    realMoney: "Currency",
    recovery: "Recovery",
    recoveryAgentGameSetting: "Recover Default",
    gameMoney: "Game point",
    reportProfit: "Profit Report(DayTime)",
    reportProfitV3: "Profit ReportV3(DayTime)",
    reportProfitDay: "Profit Report(Day)",
    all: "All",
    open: " Open",
    close: " Close",
    success: "Success",
    failed: "Failed",
    rate: "Odds",
    update: update,
    add: add,
    detail: detail,
    time: "Time(UTC-4)",
    totalValidBet: "Total Valid Bet",
    totalBet: "Total Bet",
    totalBetCount: "Total Number of Bets",
    totalWin: "Total Win",
    siteCode: "Site Code",
    siteCodeLabel: "Site Code (2~5 digits of A-Z+0-9)",
    validBet: "Valid Bet",
    profit: "Agent Profit",
    playerProfit: "Player Profit",
    hundred: "Hundreds",
    gamePlayer: "Bet Record",
    gameRound: "Game Round",
    machineGameRound: "Machine Game Round",
    gameResult: "Game Result",
    openingResult: "Game Result",
    no: "Number",
    notAdd: "Not added yet",
    enable: "Enable?",
    delete: "Delete",
    isClose: "Disable?",
    clientGameShow: "Display Game to Client?",
    isShowLobby: "Display in Lobby?",
    operating: "Operating",
    defaultClientLanguage: "Game Default Language",
    percent: "Percent",
    payMoneyForUp: "Profit Share",
    float: "{n}decimal place",
    rangeError: "Range {min}~{max}",
    ok: ok,
    zhTW: "繁體中文",
    zhCN: "简体中文",
    vi: "越南语",
    hi: "印度语",
    required: "Required",
    loadingTable: "Loading Data...",
    updateData: "Updating Data...",
    cancel: cancel,
    noData: "Currently No Data...",
    search: "search",
    lan: 'Language',
    balance: "Balance",
    account: account,
    password: password,
    rePassword: "Password(repeat)",
    rePasswordError: "Password(repeat) is different from Password",
    newPassword: "New Password",
    reNewPassword: "New Password(repeat)",
    reNewPasswordError: "New Password(repeat) is different from New Password",
    updatePassword: update + password,
    formError: "Form Error",
    name: name,
    input: input,
    createTime: "Create Time(UTC-4)",
    loginTime: "Login Time(UTC-4)",
    agent: agent,
    subAgent: "Sub" + agent,
    addAgent: add + "Sub" + agent,
    updateAgent: update + "Sub" + agent,
    updateMyselfAgent: update + "User",
    agentLevel: "Agent Level",
    player: "Player ",
    subAccount: "SubAccount",
    addSubAccount: add + "SubAccount",
    updateSubAccount: update + "SubAccount",
    currency: "Currency",
    device: "Device",
    gameType: "Game Type",
    gameTypeOptions: "Select Game Type",
    betRuleSetting: "Bet Limit Setting",
    gameSetting: "Game Setting",
    gameHundredBetRuleDetail: "Bet Limit of Hundreds" + detail,
    updateGameHundredBetRule: "Bet Limit of Hundreds",
    updateGameHundredBetRuleLimit: update + "Restrict SubAgent Bet Limit Option",
    gamePkBetRuleDetail: "Bet Limit of Battle" + detail,
    updateGamePkBetRule: "Bet Limit of Battle",
    updateGamePkBetRuleLimit: update + "Restrict SubAgent Bet Limit Option",
    playerHundredBetRule: "Bet Limit Setting",
    updatePlayerHundredBetRule: update + "Bet Limit Setting",
    addPlayerHundredBetRule: add + "Bet Limit Setting",
    chip: "Chip",
    logout: "Log Out",
    login: {
        title: '',
        submit: 'Log in '
    },
    form: {
        totalElements: "Total Records",
        size: "Records each page",
        page: "page"
    },
    report: {
        total: "Total",
        pageTotal: "Page Total",
        subAgentTotal: "SubAgent Total",
        underAgentTotal: "Downline Agent Total",
        playerTotal: "Total Profit of Direct Player",
        revenue: "My Revenue"
    },
    minBet: "Min Bet",
    maxBet: "Max Bet",
    unKnowError: "Unknown Error",
    error: "Error",
    warning: "Warning",
    az09: "Specific Characters(0~9 a~z A~Z)",
    noHaveGame: "No Game",
    playerState: {
        title: "Status",
        1: "Normal",
        2: "No Betting",
        3: "Black List"
    },
    singleWallet: "Need Single Wallet or Not",
    singleWalletUrl: "Single Wallet url",
    singleWalletUrlError: "Single Wallet Url Format Error",

    401: "Permission Denied",

    dailyRtpReport: "RTP Report",
    treeSearch: "Include SubAgent",
    count: "Amount",
    version: "Version",
    home: "Home",
    list: "List",
    maintenanceTime: "Maintenance Time",
    maintenance: "Maintenance",
    maintenanceTimeContent: "Each Monday 00:00 ~ 03:00 ",

    gameTotalReport: "Game Total Report",
    gameDailyReport: "Game Daily Report",
    playerTotalReport: "Player Total Report",
    playerTotalReportV2: "Assign Player Total Report",

    ...currency,

    controlSwitch: {
        0: "一般",
        1: "<span class='text-warning'>风控</span>",
        2: "<span class='text-danger'>杀数</span>"
    },
    closeAll: "Close All",
    closeOther: "Close Other",

    gameRoundMultiAccount: "局號-多帳號查詢",
    gameRoundMultiAgent: "局號-多代理查詢",
    immediateRtpList: "即時RTP",
    selectAgent: "Select Agents",
    selectSubAgent: "Select SubAgents",

    grant: "allow",
    grantGame: "allow game",
    cancelGrant: "disallowed game",
    batchGrantBtn: "batch allow/disallowed game",
    pleaseSelect: "Please Select",
    playerAccountChangeAgentIdAlert: "Account's agent ID is different from search agent ID<br>Change search agent ID?",

    tg: "TG",
    tgChatList: "Chat List",
    tgGroupSend: "Send Message",

    selectAll: "Select All",
    unSelectAll: "Un-select All",

    hedgingTransactionSearch: "Hedging Transaction",
    repeatRange: "Game Repeat Ratio(%)",
    totalGameRound: "Total Game Round Count",

    notGrant: "allow game",
    autoGrantGame: "auto allow game",
    ignoreOpenGame: "ignore open game",
    closeGame: "close game",
    agreeAutoGrantGameQA: "The selected agents will automatically allow game, do you agree?",
    agreeIgnoreOpenGameQA: "The selected agents will ignore check open game, do you agree?",

    gameFirewall: "Game Firewall",

    slotMachine: 'Slot Machine',
    multiplierTable: "Multiplier Symbol Chart",
    gameTotalMultiplier: 'Total Of Win Multiplier',
    baseGameTotalMultiplier: "Base Game Accumulated Multiplier",
    freeGameTotalMultiplier: "Free Game Accumulated Multiplier",

    cascadeMultiply: "Cascade Multiply",
};
