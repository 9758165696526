<template>
    <b-form>
        <b-form-group
            label="SID"
            label-for="game-type"
            :label-cols="3"
        >
            <div id="game-type" class="col-form-label">{{ detail.sid }}</div>
        </b-form-group>

        <b-form-group
            :label="$t('gameType')"
            label-for="game-type"
            :label-cols="3"
        >
            <div id="game-type" class="col-form-label">{{ $t('game.' + detail.game_type) }}</div>
        </b-form-group>

        <b-form-group
            :label="$t('account')"
            label-for="account"
            :label-cols="3"
        >
            <div id="account" class="col-form-label">{{ detail.account }}
            </div>
        </b-form-group>

        <b-form-group
            :label="$t('player')"
            label-for="player"
            :label-cols="3"
        >
            <div id="player" class="col-form-label">{{ detail.player_name }}
            </div>
        </b-form-group>

        <b-form-group
            label="IP"
            label-for="account"
            :label-cols="3"
        >
            <div id="ip" class="col-form-label">{{ detail.client_ip }}</div>
        </b-form-group>

        <b-form-group
            :label="$t('device')"
            label-for="account"
            :label-cols="3"
        >
            <div id="ip" class="col-form-label">{{ detail.device }}</div>
        </b-form-group>

        <b-form-group
            :label="$t('gameRound')"
            label-for="game-round"
            :label-cols="3"
        >
            <div id="game-round" class="col-form-label">{{ getGameRoundStr() }}</div>
        </b-form-group>

        <!--建立時間-->
        <b-form-group
            :label="$t('createTime')"
            label-for="create-time"
            :label-cols="3"
        >
          <div id="game-create-time" class="col-form-label">{{ changeTimeZone(detail.create_time) }}
          </div>
        </b-form-group>

        <b-form-group
            label=""
            label-for="ruleRate"
            :label-cols="2"
        >
            <div v-for="item in getItems() ">
                <span class="text-danger">{{ $t('pk.antes') }}:{{ item.antes }}</span>
                <b-table
                    class="mt-2"
                    sticky-header="50vh"
                    striped
                    hover
                    responsive="xl"
                    :items="item.profits"
                    :fields="getFields()">


                    <template v-slot:cell(account)="row">
                        <div v-html="row.value + getAccountSuffix(row.item) + getBetMultiple(row.item)"></div>
                    </template>

                    <template v-slot:cell(customResult.bet)="row">
                        {{ convertMoney(getCurrency(), row.value) }}
                    </template>

                    <template v-slot:cell(customResult.profit)="row">
                        <div style="display: inline-block"
                             :class="{'text-success':row.value>=0,'text-danger':row.value<0}">
                            {{ convertMoney(getCurrency(), row.value, true) }}
                        </div>
                    </template>

                    <template v-slot:cell(result)="row">
                        <div style="font-size: 1rem;" v-html="getGameResult(row.item)"></div>
                    </template>

                </b-table>
            </div>

        </b-form-group>
    </b-form>
</template>

<script>
import {getMjClass, getPaiGowCssString, getPokerCssString} from "@/utils/common";

export default {
    name: "PkGameDetail",
    props: {
        detail: {type: Object, default: {}, required: false}
    },
    data() {
        return {
            gameType: null,
            gameData: [{}],
            itemList:[]
        }
    },
    mounted() {
        this.gameType = this.detail.game_type;
        this.gameData = JSON.parse(this.detail.bet_result);
        let itemList = this.gameData[this.gameData.length - 1].data || [];

        itemList.forEach((item, index) => {
            item.profits.forEach((value, i) => {
                if (value.result) {
                    let keys = Object.keys(value.result);
                    if (value.is_bot) {
                        value.user_id *= -1;
                    }
                    value.customResult = value.result[keys[0]];
                } else {
                    value.customResult = {
                        bet: 0,
                        commission: 0,
                        profit: 0,
                        result: 0,
                        valid_bet: 0,
                        win: 0
                    }
                }
            });

            item.profits.sort(function (a, b) {
                return b.user_id - a.user_id //順序反轉
            });
        });

        this.itemList = itemList;
    },
    methods: {
        getGameRoundStr() {
            return this.detail.room_id + "-" + this.detail.table_id + "-" + this.detail.game_round;
        },
        getItems() {
            return this.itemList;
        },
        getFields() {
            return [
                {
                    key: 'account',
                    label: this.$t('account'),
                    sortable: true
                },
                {
                    key: 'customResult.bet',
                    label: this.$t('totalBet'),
                    thClass: "text-right",
                    tdClass: "text-right",
                    sortable: true
                },
                {
                    key: 'customResult.profit',
                    label: this.$t('playerProfit'),
                    thClass: "text-right",
                    tdClass: "text-right",
                    sortable: true
                },
                {
                    key: 'result',
                    label: this.$t('gameResult'),
                    sortable: true
                },
            ]
        },
        getAccountSuffix(item) {
            let str = "";
            if (item.is_banker) {
                let num = this.convertNumberWithCommas(item.bid_score || 1);

                let content = "";
                if (item.bid_score === 0) {
                    content = this.$t('nonSnatch');
                } else {
                    content = this.$tc('pk.bankerRatio', 0, {num: num});
                }

                str += "<span class='ml-1 text-primary'>(" + content + " )</span>";
            }

            if (item.is_safe === 1) {
                str += "<span class='ml-1 text-info'>(" + this.$t('pk.isSafe') + ")</span>";
            }

            if (item.is_split) {
                str += "<span class='ml-1 text-danger'>(" + this.$t('pk.isSplit') + ")</span>";
            }

            if (item.is_side_bet) {
                str += "<span class='ml-1 text-danger'>(" + this.$t('pk.isSideBet') + ")</span>";
            }

            return str;
        },
        getBetMultiple(item) {
            let str = ""

            if (item.bet_list && this.gameType !== 301) {
                if (item.bet_list > 0) {
                    let num = this.convertNumberWithCommas(item.bet_list.reduce((a, b) => a + b));
                    str = ` <span class='ml-1 text-success'>(${this.$tc('pk.betRatio', 0, {num: num})} )</span>`;
                }
            }

            return str;
        },
        getGameResult(item) {
            let html = "";
            switch (this.gameType) {
                case 301:
                case 302:
                case 303:
                case 304:
                case 305:
                case 308:
                case 310:
                case 311:
                case 312:
                case 313:
                case 314:
                    html += this.getPokerCss(item);
                    break;
                case 306:
                case 307:
                    html += this.getMjCass(item);
                    break;
                case 309:
                    html += this.getPaiGowCss(item);
                    break;
            }
            let winType = this.getWinTypeStr(item);
            html += ` (${winType})`;
            if (item.reason_for_out) {
                html += `<span class="text-danger"> (${this.$t('pk.reasonForOut.' + item.reason_for_out)})</span>`
            }

            return html;
        },
        getWinTypeStr(item) {
            let mj = [306, 307];
            if (mj.includes(this.gameType)) {
                if (item.hand_type === 2) {
                    let sum = item.hands
                        .slice(0)
                        .map(hand => {
                            if (hand === 1) {
                                return 0.5;
                            } else {
                                return hand - 1;
                            }
                        })
                        .reduce((a, b) => a + b);
                    return this.$tc('pk.' + this.gameType + '.winType.' + item.hand_type, sum % 10);
                } else {
                    return this.$t('pk.' + this.gameType + '.winType.' + item.hand_type);
                }
            } else if (this.gameType === 313) {
                if (item.hand_type === 3) {
                    let sum = item.hands
                        .slice(0)
                        .map(hand => {
                            return hand.point > 10 ? 10 : hand.point;
                        })
                        .reduce((a, b) => a + b);
                    let sum2 = item.hands
                        .slice(0)
                        .map(hand => {
                            if (hand.point === 1) {
                                return 11;
                            } else {
                                return hand.point > 10 ? 10 : hand.point;
                            }
                        })
                        .reduce((a, b) => a + b);

                    if (sum === sum2) {
                        return this.$tc('pk.' + this.gameType + '.winType.' + item.hand_type, sum + "");
                    } else {
                        return this.$tc('pk.' + this.gameType + '.winType.' + item.hand_type, sum + "/" + sum2);
                    }
                }

                return this.$t('pk.' + this.gameType + '.winType.' + item.hand_type);
            } else {
                return this.$t('pk.' + this.gameType + '.winType.' + item.hand_type);
            }
        },
        getPokerCss(item) {
            let html = "";
            let length = item.hands.length;
            for (let i = 0; i < length; i++) {
                let poke = item.hands[i];
                html += `<i class="${getPokerCssString(poke.suits, poke.point, this.gameType)}" ></i>`
            }
            return html;
        },
        getPaiGowCss(item) {
            let html = "";
            let length = item.hands.length;
            for (let i = 0; i < length; i++) {
                html += `<i class="${getPaiGowCssString(item.hands[i])}" ></i>`
            }
            return html;
        },
        getMjCass(item) {
            let html = "";
            let length = item.hands.length;
            for (let i = 0; i < length; i++) {
                html += `${getMjClass(item.hands[i])}`;
            }
            return html;
        },
        getCurrency() {
            return this.detail.cur_name;
        },
    }
}
</script>

<style scoped>

</style>
