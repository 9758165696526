export default {
    // 共用
    111090001: "伺服器设定错误",
    111090002: "登入失败",
    111090003: "没有开放此功能权限",
    111090004: "传入参数错误",
    111090005: "使用者Account不存在",
    111090006: "检查Token时发生错误",
    111090007: "Token已到期",
    111090008: "UserToken已到期",
    111090009: "UserToken格式错误",
    111090010: "没有权限",
    111090011: "代理UserToken传入参数错误",
    111090012: "代理SecretKey传入参数错误",
    111090013: "取得游戏网域失败",
    111090014: "维护中",

// 代理共用
    113000001: "取得代理Token错误",
    113000002: "取得代理详细资料错误错误",
    113000003: "取得代理限红失败",
    113000004: "查无角色",
    113000005: "根据权限集合取得权限失败",
    113000006: "角色名称已存在",
    113000007: "取得代理资料失败",
    113000008: "角色不包含于上层ID角色",
    113000009: "可分配的百分比不足",
    113000010: "父代理ID没有在代理层级列表内",
    113000011: "查无父代理",
    113000012: "查无代理",
    113000013: "选择限红 超越上层代理的限红设定",
    113000014: "查无游戏设定厅数量",
    113000015: "已选择的限红设定数量与游戏厅数不符合",
    113000016: "已选择的限红 不包含在限红设定里",
    113000017: "时间格式转换错误",
    113000018: "代理必须是父节点且不能为总代理",
    113000019: "查无代理限红",
    113000020: "取得父代理限红设定失败",
    113000021: "限红设定 只能增加不能删除",
    113000022: "根​​据角色名称取得角色失败",
    113000023: "可选择限红 必须包含预设值",
    113000024: "根据游戏类型取得预设限红失败",
    113000025: "查无游戏类型",
    113000026: "取得代理限红设定失败",
    113000027: "取得预设角色数量失败",
    113000028: "子帐号角色阵列不得包含总代理角色或代理角色",
    113000029: "权限不得多于父代理权限",
    113000030: "查无总代游戏类型",
    113000031: "超越父代理游戏类型",
    113000032: "取得玩家理红设定失败",
    113000033: "违法的状态种类",
    113000034: "解析字串失败",
    113000035: "转换为Int型态错误",
    113000036: "预设角色不得修改权限",
    113000037: "取得登入的线上玩家ID阵列失败",
    113000038: "已选择的限红不得为空",
    113000039: "写入操作纪录失败",
    113000040: "取得游戏详细资料失败",
    113000041: "验证信箱格式失败",
    113000042: "没有在代理白名单内 <br> 您的IP : ",
    113000043: "验证客户端IP格式失败",
    113000044: "取得代理登入失败次数失败",
    113000045: "设置代理登入锁定时间失败",
    113000046: "取得游戏类型列表错误",
    113000049: "取得杀数设定失败",
    113110050: "单一钱包网址格式错误",
    113110051: "设置风险控制错误(格式错误)",
    113110052: "设置风险控制错误(长度不对)",
    113110053: "设置风险控制错误(Redis还有资料没处理完)",
    113110054: "设置风险有逻辑错误",
    113110055: "取得玩家资料错误",
    113110056: "取得代理白名单失败",
    113110057: "取得活动key值错误",

// 代理
    113010001: "帐号密码错误",
    113010002: "设置代理Token过期时间错误",
    113010004: "该帐户验证失败超过5次, 请稍后再试",
    113020001: "代理登出失败",
    113060001: "子帐号不能删除角色",
    113060002: "删除角色失败",
    113070001: "子帐号不得新增角色和权限",
    113070002: "角色和权限新增失败",
    113080001: "只有父代理能够更新子帐号角色",
    113080002: "角色和权限更新失败",
    113090001: "取得代理层级错误",
    113100001: "取得代理单层级错误",
    113110001: "代理帐号已存在",
    113110002: "子帐号不得创建代理",
    113110003: "新增代理失败",
    113110004: "取得全部游戏预设限红失败",
    113110005: "代理层级最多四层",
    113110006: "后缀码仅能有英文或数字且要2~5个字符",
    113110007: "取得后缀码数量错误",
    113110008: "后缀码已存在",
    113120001: "更新代理失败",
    113130001: "新增代理子帐号失败",
    113130002: "代理子帐号已存在",
    113130003: "新增代理子帐号失败",
    113140001: "更新代理子帐号失败",
    113150001: "查无代理限红设定",
    113160001: "更新限红失败",
    113170001: "子代理不能更新自己的可选限红",
    113170002: "更新代理限红设定失败",
    113180001: "子代理限红设定已存在",
    113180002: "子代理不能新增自己的可选限红",
    113180003: "新增可选择限红失败",
    113200001: "取得角色权限失败",
    113210001: "子帐号管理设定失败",
    113220001: "查无子帐号",
    113230001: "查无游戏局号纪录",
    113240001: "查无游戏局号详细纪录",
    113250001: "查无代理底下玩家下注纪录",
    113250002: "查无玩家帐号",
    113260001: "查无代理底下玩家下注输赢金额",
    113270001: "查无代理底下已下注局号纪录",
    113280001: "查无代理底下已下注输赢金额",
    113290001: "查无该局某玩家下注区下注纪录",
    113300001: "查无该局号玩家结算纪录",
    113310001: "查无该局号某座位玩家结算纪录",
    113320001: "增加玩家错误",
    113320002: "此玩家帐号已存在",
    113330001: "取得代理玩家错误",
    113340001: "更新玩家的代理ID错误",
    113340002: "此玩家帐号不存在",
    113350001: "更新密码失败",
    113360001: "更新代理游戏开关失败",
    113360002: "父代理尚未开启限红设定",
    113360003: "违法的启用状态",
    113370001: "新增代理限红设定失败",
    113380001: "更新代理限红设定失败",
    113380002: "设定RTP不符合范围",
    113390001: "违法的启用状态",
    113390002: "管理玩家限红设定",
    113400001: "取得获利表失败",
    113410001: "删除玩家限红失败",
    113420001: "取得现金操作纪录失败",
    113430001: "取得登入的线上玩家列表失败",
    113440001: "取得币别列表失败",
    113450001: "更新玩家状态失败",
    113460001: "取得子代理输赢报表失败",
    113470001: "得代理ID失败",
    113480001: "更新代理API金钥失败",
    113490001: "更新代理白名单失败",
    113500001: "删除代理白名单失败",
    113510001: "新增代理白名单失败",
    113510002: "新增代理白名单失败 重复新增",
    113520001: "取得每日分析报表总计错误",
    113520002: "取得每日分析报表总计错误,错误的参数",
    113530001: "取得操作纪录失败",
    113540001: "更新代理RTP失败",
    113540002: "取得代理限红设定RTP失败",
    113540003: "设定[RTP/最高倍率/Extra Rtp]不符合范围",
    113540004: "無法更新總代理rtp設定",
    113550001: "取得全部总代理杀数设定失败",
    113560001: "取得总代理杀数设定失败",
    113560002: "更新总代理杀数设定失败",
    113570001: "设置风险控制错误(查无此桌)",
    113570002: "设置风险从DB取得游戏最大回合数错误",
    113570003: "设置风险控制开关错误",
    113580001: "取得游戏控牌中列表错误",
    113590001: "取得游戏桌设定错误",
    113600001: "取得代理广播错误",
    113610001: "取得老虎机重播设定错误",
    113610002: "取得代理白名单错误",
    113620001: "取得代理活动设定列表错误",
    113630001: "新增代理活动设定错误",
    113630002: "新增代理活动设定错误(时间格式不对)",
    113630003: "新增代理活动设定错误(设定档格式错误)",
    113630004: "新增代理活动设定错误(活动ID错误)",
    113630005: "新增代理活动设定错误(活动时间必须在系统活动时间之间)",
    113640001: "修改代理活动设定错误",
    113640002: "修改代理活动设定错误(时间格式不对)",
    113640003: "修改代理活动设定错误(设定档格式错误)",
    113640004: "修改代理活动设定错误(活动ID错误)",
    113640005: "修改代理活动设定错误(活动时间必须在系统活动时间之间)",
    113650001: "取得代理活动设定错误",
    113650002: "删除代理活动设定错误",
    113660001: "取得代理活动记录错误",
    113670001: "取得代理活动领取详细记录错误",
    113680001: "取得代理活动总奖励错误",
    113690001: "取得代理活动报表错误",
    113700001: "取得子代理代理活动报表错误",

    113720001: "更新自定义图片错误",
    113730001: "复原自定义图片错误",
    113740001: "取得自定义图片错误",

    113750001: "找不到视讯游戏",
    113750002: "取得视讯报表错误",

    113120004: "无法更新总代理额度开关",
    113120005: "权限不足无法更新子代理额度开关",

    113760001: "更新代理开分额度失败",
    113760002: "代理额度为锁定状态(无法存入提取)",
    113760003: "子代分额度为锁定状态(无法存入提取)",
    113760004: "不能更新子帐号代理额度",
    113760005: "不能更新总代理额度",
    113760006: "不能更新子代理自己额度",
    113760007: "额度不得为0",
    113760008: "代理层级(只开放上层对下层一个层级操作)更新额度错误",
    113760009: "额度不足",
    113760010: "超出最大长度: 整数位14位:小数点后2位",
    113760011: "代理额度为关闭状态(无限制额度)",
    113760012: "上层与下层皆为关闭状态(无限制额度)更新失败",
    113760013: "下层额度不足更新失败",
    113760014: "下层额度为关闭状态(无限制额度)更新失败",

    113770001: "取得鱼机下住详细失败",

    114010001: "取得即時RTP失敗",
    114020001: "取得計算殺率失敗",
    //取得多代理取得局號失敗
    114030001: "查詢失敗",

    113110061: "取出Slot類系統最高倍率設定失敗",
    114090001: "更新系統Rtp失敗",

    114050001 :"新增telegram对应资讯失败",
    114060001 :"取得所有telegram对应资讯失败",
    114070001 :"更新telegram对应资讯失败",
    114080001 :"删除telegram对应资讯失败",
    114120001 :"telegram对应资讯绑代理失败",
    114140001 :"根据分类取出所有telegram失败",
    // 共用
    113000059 :"ChatID已经存在",
    113000060 :"群发tg讯息设定已经存在",


    //测试单一钱包
    121010001: "单一钱包测试余额失败",
    121010002: "取得当前状态时失败",
    121010003: "设定当前状态时失败",
    121010004: "包类型错误必须为单一钱包",
    121020001: "当前测试还在运行中",
    121020002: "测试帐号的余额必须等于500",
    121020003: "删除先前资料时出错",
    121020004: "在开始测试前发生错误",
    121020005: "错误的测试方法名称",
    121030001: "取得当前的进程列表失败间请求, 请求的时间不能大于等于当前时间",

    114270001: "錯誤的佔成請求 必須設訂0~100的趴數",
    114270002: "取得所有子代理佔成趴數錯誤",
    114270003: "設定的佔成趴數不足以分配給代理",
    114270004: "更新代理佔成失敗",

    114280001: "取得代理游戏防火墙错误",
    114290001: "更新代理游戏强火墙错误"

}
