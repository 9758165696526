export default {
    // 共用
    111090001: "Server Setting error",
    111090002: "Login Failed",
    111090003: "No Permission for the Function",
    111090004: "Input Parameter Error",
    111090005: "User Account doesn't exist",
    111090006: "Error occurred while checking the token",
    111090007: "Token Expired",
    111090008: "UserToken Expired",
    111090009: "UserToken Format Error",
    111090010: "No Permission",
    111090011: "Agent UserToken Input Parameter Error",
    111090012: "Agent SecretKey Input Parameter Error",
    111090013: "Failed to Obtain Game Domain",
    111090014: "Under Maintenance",

// 代理共用
    113000001: "Obtain Agent Token Error",
    113000002: "Obtain Agent Detail Error",
    113000003: "Failed to Obtain Agent Limit",
    113000004: "No Role Found",
    113000005: "Failed to obtain permission based on permission set",
    113000006: "Role Name Already Exists",
    113000007: "Failed to obtain agent information",
    113000008: "The role is not included in upper ID role",
    113000009: "Insufficient allocable percentage",
    113000010: "The parent agent ID is not in agent level lists",
    113000011: "No Parent Agent",
    113000012: "No Agent",
    113000013: "Select Bet Limit: Exceeding the bet limit setting of the upper agent",
    113000014: "Unable to find the number of game room setting",
    113000015: "The number of selected bet limit settings does not match the number of game rooms",
    113000016: "The selected bet limit is not included in bet limit setting",
    113000017: "Time Format Conversion Error",
    113000018: "The agent must be parent node and cannot be the general agent",
    113000019: "No Agent Bet Limit",
    113000020: "Failed to obtain the parent agent's bet limit setting",
    113000021: "Bet limit setting can only be added but not deleted",
    113000022: "Failed to obtain role based on role name",
    113000023: "Choosable bet limit must contain default value",
    113000024: "Failed to obtain the default bet limit according to the game type",
    113000025: "No Game Type",
    113000026: "Failed to Obtain Agent Limit Setting",
    113000027: "Failed to obtain the default number of roles",
    113000028: "The sub-account role array cannot contain general agent roles or agent roles",
    113000029: "No more permissions than parent agent permissions",
    113000030: "No Generation Agent Game Type",
    113000031: "Exceeding the parent agent game type",
    113000032: "Failed to obtain player's bet limit setting",
    113000033: "Types of illegal status",
    113000034: "Failed to analyze string",
    113000035: "Convert to Int Type Error",
    113000036: "Authority of default Role cannot be Modified",
    113000037: "Failed to obtain the logged-in player ID array",
    113000038: "The selected bet limit cannot be empty",
    113000039: "Failed to Write Operation Record",
    113000040: "Failed to Obtain Game Details",
    113000041: "Failed to Verify Mailbox Format",
    113000042: "Not In Agent Allow List  <br> Your IP : ",
    113000043: "Failed to Verify Client IP Format",
    113000044: "Failed to Obtain Frequency of Agent Login Failure ",
    113000045: "Failed to set Agent login lock time",
    113000046: "Obtain game type list error",
    113000049: "Failed to obtain kill counts setting",
    113110050: "Single wallet URL is malformed",
    113110051: "Setting risk control error (format error)",
    113110052: "Setting risk control error (wrong length)",
    113110053: "Setting risk control error (Redis still got unprocessed data)",
    113110054: "Logical error in setting risk control",
    113110055: "Obtain Player Information Error",
    113110056: "Failed to Obtain Agent Allow List",
    113110057: "Error of Obtaining Activity Key Value",

// 代理
    113010001: "Incorrect Password",
    113010002: "Error of Setting Agent Token Expiry Time",
    113010004: "Failed ro verify the account over 5 times, please try again later",
    113020001: "Agent logout failed",
    113060001: "Sub-account cannot delete role",
    113060002: "Failed to Delete Role",
    113070001: "Sub-accounts cannot add roles and permissions",
    113070002: "Failed to add roles and permissions",
    113080001: "Only the parent agent can update the sub-account role",
    113080002: "Failed to update roles and permissions",
    113090001: "Error of obtaining agent level",
    113100001: "Error of obtaining agent single level",
    113110001: "Agent Account is occupied",
    113110002: "Sub-account is not allowed to create agent",
    113110003: "Add New Agent Failed",
    113110004: "Failed to obtain default bet limit of all games",
    113110005: "Up to four agent levels",
    113110006: "Suffix code can only be English or numbers with 2~5 letters",
    113110007: "Error of obtaining numbers of suffix codes",
    113110008: "Suffix code already exists",
    113120001: "Failed to update agent",
    113130001: "Failed to add the agent's sub-account",
    113130002: "Agent sub-account already exists",
    113130003: "Failed to add the agent's sub-account",
    113140001: "Failed to update agent sub-account",
    113150001: "No bet limit setting of agent",
    113160001: "Failed to update bet limit",
    113170001: "Sub-agents cannot update its own optional bet limit",
    113170002: "Failed to update bet limit setting of agent",
    113180001: "Bet limit of sub-agent already exist",
    113180002: "Sub-agents cannot add its own optional bet limit",
    113180003: "Failed to add optional bet limit",
    113200001: "Failed to obtain role permissions",
    113210001: "Failed to set Sub-account management",
    113220001: "Sub-account is not found",
    113230001: "Game ID record is not found",
    113240001: "Detailed game ID record is not found",
    113250001: "Check no betting records of players under the agent",
    113250002: "Player Account not found",
    113260001: "Check no bet Win/ Loss amount of players' under the agent",
    113270001: "Check no betting record of game ID under the agent",
    113280001: "Betting Win/ Loss amount under the agent is not found",
    113290001: "Betting record of the player in the game round is not found",
    113300001: "Settlement records in the round is not found",
    113310001: "Settlement record of the player in the round is not found",
    113320001: "Failed to add player",
    113320002: "The player account already exist",
    113330001: "Error of obtaining agent player",
    113340001: "Error of updating player's agent ID ",
    113340002: "The player account does not exist",
    113350001: "Failed to update Password",
    113360001: "Failed to update agent game switch",
    113360002: "Parent agent has not opened the bet limit setting",
    113360003: "Illegal activation status",
    113370001: "Failed to add bet limit setting of agent",
    113380001: "Failed to update bet limit setting of agent",
    113380002: "设定[RTP/最高倍率/Extra Rtp]不符合范围",
    113390001: "Illegal activation status",
    113390002: "Manage players' bet limit settings",
    113400001: "Failed to obtain profit table",
    113410001: "Failed to delete player bet limit",
    113420001: "Failed to obtain cash operation record",
    113430001: "Failed to obtain the list of login players",
    113440001: "Failed to obtain list of currencies",
    113450001: "Failed to update player status",
    113460001: "Failed to obtain profit table of Sub-agent ",
    113470001: "Failed obtain agent ID",
    113480001: "Failed to update agent API token",
    113490001: "Failed to update agent allow list",
    113500001: "Failed to delete agent allow list",
    113510001: "Failed to add agent allow list",
    113510002: "Failed to add agent allow list: Duplicated add",
    113520001: "Failed to obtain daily total analysis report",
    113520002: "Failed to obtain daily total analysis report: Wrong parameter",
    113530001: "Failed to obtain operation record",
    113540001: "Failed to update agent RTP",
    113540002: "Failed to obtain RTP of agent bet limit setting",
    113540003: "RTP setting does not meet the range",
    113540004: "無法更新總代理rtp設定",
    113550001: "Failed to obtain all agents' kills setting",
    113560001: "Failed to obtain agent's kills setting",
    113560002: "Failed to update agent's kills setting",
    113570001: "Error of setting risk control(the table is not found)",
    113570002: "Error of setting the risk from DB to obtain the maximum number of game rounds",
    113570003: "Wrong setting of risk control switch",
    113580001: "Error of obtaining the list in game control ",
    113590001: "Error of obtaining game table setting",
    113600001: "Error of obtaining agent broadcast",
    113610001: "Error of obtaining slot replay setting",
    113610002: "Obtain agent allow list error",
    113620001: "Error of obtaining agent activity setting list",
    113630001: "Add agent activity setting error",
    113630002: "Add agent activity setting error(Wrong Time Format)",
    113630003: "Add agent activity setting error(Configuration file format error)",
    113630004: "Add agent activity setting error(Wrong Activity ID)",
    113630005: "Add agent activity setting error(Activity period should correspond to system's)",
    113640001: "Error of updating agent activity setting",
    113640002: "Error of updating agent activity setting(Wrong time format)",
    113640003: "Error of updating agent activity setting(Configuration file format error)",
    113640004: "Error of updating agent activity setting(Wrong Activity ID)",
    113640005: "Error of updating agent activity setting(Activity period should correspond to system's)",
    113650001: "Error of obtaining agent activity setting",
    113650002: "Error of deleting agent activity setting",
    113660001: "Error of obtaining agent activity setting",
    113670001: "Error of obtaining detailed awarded record of agent activity",
    113680001: "Failed to obtain total awards of agent activity",
    113690001: "Failed to obtain agent activity report",
    113700001: "Failed to obtain activity report of sub-agent",
    113710001: "Error of obtaining bet limit setting switch of agent",
    113710002: "Error of updating bet limit setting: game switch",

    113720001: "更新自定义图片错误",
    113730001: "复原自定义图片错误",
    113740001: "取得自定义图片错误",

    113750001: "Cannot find streaming game",
    113750002: "Fail to obtain report of streaming game",

    113120004: "无法更新总代理额度开关",
    113120005: "权限不足无法更新子代理额度开关",

    113760001: "更新代理开分额度失败",
    113760002: "代理额度为锁定状态(无法存入提取)",
    113760003: "子代分额度为锁定状态(无法存入提取)",
    113760004: "不能更新子帐号代理额度",
    113760005: "不能更新总代理额度",
    113760006: "不能更新子代理自己额度",
    113760007: "额度不得为0",
    113760008: "代理层级(只开放上层对下层一个层级操作)更新额度错误",
    113760009: "额度不足",
    113760010: "超出最大长度: 整数位14位:小数点后2位",
    113760011: "代理额度为关闭状态(无限制额度)",
    113760012: "上层与下层皆为关闭状态(无限制额度)更新失败",
    113760013: "下层额度不足更新失败",
    113760014: "下层额度为关闭状态(无限制额度)更新失败",

    113770001: "取得鱼机下住详细失败",

    114010001: "取得即時RTP失敗",
    114020001: "取得計算殺率失敗",
    //取得多代理取得局號失敗
    114030001: "查詢失敗",

    113110061: "取出Slot類系統最高倍率設定失敗",
    114090001: "更新系統Rtp失敗",

    114050001 :"新增telegram对应资讯失败",
    114060001 :"取得所有telegram对应资讯失败",
    114070001 :"更新telegram对应资讯失败",
    114080001 :"删除telegram对应资讯失败",
    114120001 :"telegram对应资讯绑代理失败",
    114140001 :"根据分类取出所有telegram失败",
    // 共用
    113000059 :"ChatID已经存在",
    113000060 :"群发tg讯息设定已经存在",

    //测试单一钱包
    121010001: "单一钱包测试Balance失败",
    121010002: "取得当前状态时失败",
    121010003: "设定当前状态时失败",
    121010004: "包类型错误必须为单一钱包",
    121020001: "当前测试还在运行中",
    121020002: "测试帐号的余额必须等于500",
    121020003: "删除先前资料时出错",
    121020004: "在开始测试前发生错误",
    121020005: "错误的测试方法名称",
    121030001: "取得当前的进程列表失败间请求, 请求的时间不能大于等于当前时间",

    114270001: "錯誤的佔成請求 必須設訂0~100的趴數",
    114270002: "取得所有子代理佔成趴數錯誤",
    114270003: "設定的佔成趴數不足以分配給代理",
    114270004: "更新代理佔成失敗",

    114280001: "取得代理游戏防火墙错误",
    114290001: "更新代理游戏强火墙错误"
}
