export default {
    // 共用
    111090001: "伺服器設定錯誤",
    111090002: "登入失敗",
    111090003: "沒有開放此功能權限",
    111090004: "傳入參數錯誤",
    111090005: "使用者Account不存在",
    111090006: "檢查Token時發生錯誤",
    111090007: "Token已到期",
    111090008: "UserToken已到期",
    111090009: "UserToken格式錯誤",
    111090010: "沒有權限",
    111090011: "代理UserToken傳入參數錯誤",
    111090012: "代理SecretKey傳入參數錯誤",
    111090013: "取得遊戲網域失敗",
    111090014: "維護中",

// 代理共用
    113000001: "取得代理Token錯誤",
    113000002: "取得代理詳細資料錯誤錯誤",
    113000003: "取得代理限紅失敗",
    113000004: "查無角色",
    113000005: "根據權限集合取得權限失敗",
    113000006: "角色名稱已存在",
    113000007: "取得代理資料失敗",
    113000008: "角色不包含於上層ID角色",
    113000009: "可分配的百分比不足",
    113000010: "父代理ID沒有在代理層級列表內",
    113000011: "查無父代理",
    113000012: "查無代理",
    113000013: "選擇限紅 超越上層代理的限紅設定",
    113000014: "查無遊戲設定廳數量",
    113000015: "已選擇的限紅設定數量與遊戲廳數不符合",
    113000016: "已選擇的限紅 不包含在限紅設定裡",
    113000017: "時間格式轉換錯誤",
    113000018: "代理必須是父節點且不能為總代理",
    113000019: "查無代理限紅",
    113000020: "取得父代理限紅設定失敗",
    113000021: "限紅設定 只能增加不能刪除",
    113000022: "根據角色名稱取得角色失敗",
    113000023: "可選擇限紅 必須包含預設值",
    113000024: "根據遊戲類型取得預設限紅失敗",
    113000025: "查無遊戲類型",
    113000026: "取得代理限紅設定失敗",
    113000027: "取得預設角色數量失敗",
    113000028: "子帳號角色陣列不得包含總代理角色或代理角色",
    113000029: "權限不得多於父代理權限",
    113000030: "查無總代遊戲類型",
    113000031: "超越父代理遊戲類型",
    113000032: "取得玩家理紅設定失敗",
    113000033: "違法的狀態種類",
    113000034: "解析字串失敗",
    113000035: "轉換為Int型態錯誤",
    113000036: "預設角色不得修改權限",
    113000037: "取得登入的線上玩家ID陣列失敗",
    113000038: "已選擇的限紅不得為空",
    113000039: "寫入操作紀錄失敗",
    113000040: "取得遊戲詳細資料失敗",
    113000041: "驗證信箱格式失敗",
    113000042: "沒有在代理白名單內  <br> 您的IP : ",
    113000043: "驗證客戶端IP格式失敗",
    113000044: "取得代理登入失敗次數失敗",
    113000045: "設置代理登入鎖定時間失敗",
    113000046: "取得遊戲類型列表錯誤",
    113000049: "取得殺數設定失敗",
    113110050: "單一錢包網址格式錯誤",
    113110051: "設置風險控制錯誤(格式錯誤)",
    113110052: "設置風險控制錯誤(長度不對)",
    113110053: "設置風險控制錯誤(Redis還有資料沒處理完)",
    113110054: "設置風險有邏輯錯誤",
    113110055: "取得玩家資料錯誤",
    113110056: "取得代理白名單失敗",
    113110057: "取得活動key值錯誤",

// 代理
    113010001: "帳號密碼錯誤",
    113010002: "設置代理Token過期時間錯誤",
    113010004: "該帳戶驗證失敗超過5次, 請稍後再試",
    113020001: "代理登出失敗",
    113060001: "子帳號不能刪除角色",
    113060002: "刪除角色失敗",
    113070001: "子帳號不得新增角色和權限",
    113070002: "角色和權限新增失敗",
    113080001: "只有父代理能夠更新子帳號角色",
    113080002: "角色和權限更新失敗",
    113090001: "取得代理層級錯誤",
    113100001: "取得代理單層級錯誤",
    113110001: "代理帳號已存在",
    113110002: "子帳號不得創建代理",
    113110003: "新增代理失敗",
    113110004: "取得全部遊戲預設限紅失敗",
    113110005: "代理層級最多四層",
    113110006: "後綴碼僅能有英文或數字且要2~5個字符",
    113110007: "取得後綴碼數量錯誤",
    113110008: "後綴碼已存在",
    113120001: "更新代理失敗",
    113130001: "新增代理子帳號失敗",
    113130002: "代理子帳號已存在",
    113130003: "新增代理子帳號失敗",
    113140001: "更新代理子帳號失敗",
    113150001: "查無代理限紅設定",
    113160001: "更新限紅失敗",
    113170001: "子代理不能更新自己的可選限紅",
    113170002: "更新代理限紅設定失敗",
    113180001: "子代理限紅設定已存在",
    113180002: "子代理不能新增自己的可選限紅",
    113180003: "新增可選擇限紅失敗",
    113200001: "取得角色權限失敗",
    113210001: "子帳號管理設定失敗",
    113220001: "查無子帳號",
    113230001: "查無遊戲局號紀錄",
    113240001: "查無遊戲局號詳細紀錄",
    113250001: "查無代理底下玩家下注紀錄",
    113250002: "查無玩家帳號",
    113260001: "查無代理底下玩家下注輸贏金額",
    113270001: "查無代理底下已下注局號紀錄",
    113280001: "查無代理底下已下注輸贏金額",
    113290001: "查無該局某玩家下注區下注紀錄",
    113300001: "查無該局號玩家結算紀錄",
    113310001: "查無該局號某座位玩家結算紀錄",
    113320001: "增加玩家錯誤",
    113320002: "此玩家帳號已存在",
    113330001: "取得代理玩家錯誤",
    113340001: "更新玩家的代理ID錯誤",
    113340002: "此玩家帳號不存在",
    113350001: "更新密碼失敗",
    113360001: "更新代理遊戲開關失敗",
    113360002: "父代理尚未開啟限紅設定",
    113360003: "違法的啟用狀態",
    113370001: "新增代理限紅設定失敗",
    113380001: "更新代理限紅設定失敗",
    113380002: "設定RTP不符合範圍",
    113390001: "違法的啟用狀態",
    113390002: "管理玩家限紅設定",
    113400001: "取得獲利表失敗",
    113410001: "刪除玩家限紅失敗",
    113420001: "取得現金操作紀錄失敗",
    113430001: "取得登入的線上玩家列表失敗",
    113440001: "取得幣別列表失敗",
    113450001: "更新玩家狀態失敗",
    113460001: "取得子代理輸贏報表失敗",
    113470001: "得代理ID失敗",
    113480001: "更新代理API金鑰失敗",
    113490001: "更新代理白名單失敗",
    113500001: "刪除代理白名單失敗",
    113510001: "新增代理白名單失敗",
    113510002: "新增代理白名單失敗 重複新增",
    113520001: "取得每日分析報表總計錯誤",
    113520002: "取得每日分析報表總計錯誤,錯誤的參數",
    113530001: "取得操作紀錄失敗",
    113540001: "更新代理RTP失敗",
    113540002: "取得代理限紅設定RTP失敗",
    113540003: "設定[RTP/最高倍率/Extra Rtp]不符合範圍",
    113540004: "無法更新總代理rtp設定",
    113550001: "取得全部總代理殺數設定失敗",
    113560001: "取得總代理殺數設定失敗",
    113560002: "更新總代理殺數設定失敗",
    113570001: "設置風險控制錯誤(查無此桌)",
    113570002: "設置風險從DB取得遊戲最大回合數錯誤",
    113570003: "設置風險控制開關錯誤",
    113580001: "取得遊戲控牌中列表錯誤",
    113590001: "取得遊戲桌設定錯誤",
    113600001: "取得代理廣播錯誤",
    113610001: "取得老虎機重播設定錯誤",
    113610002: "取得代理白名單錯誤",
    113620001: "取得代理活動設定列表錯誤",
    113630001: "新增代理活動設定錯誤",
    113630002: "新增代理活動設定錯誤(時間格式不對)",
    113630003: "新增代理活動設定錯誤(設定檔格式錯誤)",
    113630004: "新增代理活動設定錯誤(活動ID錯誤)",
    113630005: "新增代理活動設定錯誤(活動時間必須在系統活動時間之間)",
    113640001: "修改代理活動設定錯誤",
    113640002: "修改代理活動設定錯誤(時間格式不對)",
    113640003: "修改代理活動設定錯誤(設定檔格式錯誤)",
    113640004: "修改代理活動設定錯誤(活動ID錯誤)",
    113640005: "修改代理活動設定錯誤(活動時間必須在系統活動時間之間)",
    113650001: "取得代理活動設定錯誤",
    113650002: "刪除代理活動設定錯誤",
    113660001: "取得代理活動記錄錯誤",
    113670001: "取得代理活動領取詳細記錄錯誤",
    113680001: "取得代理活動總獎勵錯誤",
    113690001: "取得代理活動報表錯誤",
    113700001: "取得子代理代理活動報表錯誤",
    113710001: "取得代理限紅設定開關錯誤",
    113710002: "更新代理限紅設定遊戲開關錯誤",

    113720001: "更新自定義圖片錯誤",
    113730001: "復原自定義圖片錯誤",
    113740001: "取得自定義圖片錯誤",

    113750001: "找不到視訊遊戲",
    113750002: "取得視訊報表錯誤",

    113120004: "無法更新總代理額度開關",
    113120005: "權限不足無法更新子代理額度開關",

    113760001: "更新代理開分額度失敗",
    113760002: "代理額度為鎖定狀態(無法存入提取)",
    113760003: "子代分額度為鎖定狀態(無法存入提取)",
    113760004: "不能更新子帳號代理額度",
    113760005: "不能更新總代理額度",
    113760006: "不能更新子代理自己額度",
    113760007: "額度不得為0",
    113760008: "代理層級(只開放上層對下層一個層級操作)更新額度錯誤",
    113760009: "額度不足",
    113760010: "超出最大長度: 整數位14位:小數點後2位",
    113760011: "代理額度為關閉狀態(無限制額度)",
    113760012: "上層與下層皆為關閉狀態(無限制額度)更新失敗",
    113760013: "下層額度不足更新失敗",
    113760014: "下層額度為關閉狀態(無限制額度)更新失敗",

    113770001: "取得魚機下住詳細失敗",

    114010001: "取得即時RTP失敗",
    114020001: "取得計算殺率失敗",
    //取得多代理取得局號失敗
    114030001: "查詢失敗",

    113110061: "取出Slot類系統最高倍率設定失敗",
    114090001: "更新系統Rtp失敗",

    114050001: "新增telegram對應資訊失敗",
    114060001: "取得所有telegram對應資訊失敗",
    114070001: "更新telegram對應資訊失敗",
    114080001: "刪除telegram對應資訊失敗",
    114120001: "telegram對應資訊綁代理失敗",
    114140001: "根據分類取出所有telegram失敗",
    // 共用
    113000059: "ChatID已經存在",
    113000060: "群發tg訊息設定已經存在",

    //測試單一錢包
    121010001: "單一錢包測試餘額失敗",
    121010002: "取得當前狀態時失敗",
    121010003: "設定當前狀態時失敗",
    121010004: "包類型錯誤必須為單一錢包",
    121020001: "當前測試還在運行中",
    121020002: "測試帳號的餘額必須等於500",
    121020003: "刪除先前資料時出錯",
    121020004: "在開始測試前發生錯誤",
    121020005: "錯誤的測試方法名稱",
    121030001: "取得當前的進程列表失敗間請求, 請求的時間不能大於等於當前時間",
    114100001: "取得玩家同IP交易失敗",
    114110001: "取得對沖交易查詢失敗",
    // 114120001: "取得對沖交易統計結果失敗失敗",

    114270001: "錯誤的佔成請求 必須設訂0~100的趴數",
    114270002: "取得所有子代理佔成趴數錯誤",
    114270003: "設定的佔成趴數不足以分配給代理",
    114270004: "更新代理佔成失敗",

    114280001: "取得代理遊戲防火牆錯誤",
    114290001: "更新代理遊戲強火牆錯誤"
}
