import {IsJsonString} from "@/utils/error";
import JSONbig from "json-bigint"

export default class BaseService {

    static ajax(apiCode, url, method, body = {}, headers = {}) {
        let opts = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache': 'no-cache',
                ...headers
            },
            ...body
        };

        console.log("送出", url, body);
        return fetch(url, opts)
            .then(res => {
                if (res.ok) {
                    return res.text().then(text => {
                        return JSONbig.parse(text);
                    })
                } else {
                    return res.text().then(text => {
                        if (IsJsonString(text)) {
                            let json = JSON.parse(text);
                            json.apiCode = apiCode;
                            return Promise.reject(json);
                        }

                        return Promise.reject(`${apiCode} <br> ${text}`);
                    })
                }
            })
            .then(json => {
                if (json.status === "N") {
                    json.apiCode = apiCode;
                    return Promise.reject(json);
                }
                return json;
            })
    }
}
