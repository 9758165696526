import commonEN from "@/local/common/commonEN";
import errorEN from "@/local/error/errorEN";
import gameEN from "@/local/game/gameEN";
import SlotEN from "@/local/slot/slotEN";
import pkEN from "@/local/pk/pkEN";
import stgEN from "@/local/stg/stgEN";

export default {
    ...commonEN,
    slot: SlotEN,
    pk: pkEN,
    stg: stgEN,
    game: gameEN,
    ...errorEN,
}
