<template>
    <div v-html="getStr()"></div>
</template>

<script>
import {getDiceClass, getFscClass, getFspClass, getMjClass, getPokerClass, getSuperCarClass} from "@/utils/common";
import {brnnGameResult} from "@/utils/Brnn";
import {gdfGameResult} from "@/utils/GDF";

export default {
    name: "GameResult",
    props: {
        gameType: null,
        gameResult: null,
        tableId: null,
        roomId: null
    },
    methods: {
        getStr() {
            let htmlStr = "";

            if (this.gameResult.length === 0) {
                return htmlStr;
            }

            switch (this.gameType) {
                //百家樂
                case 101:
                case 116:
                case 123:
                    htmlStr = this.getBacc(this.gameResult, true);
                    break;
                //設龍門
                case 102:
                    htmlStr = this.getDGP(this.gameResult);
                    break;
                //火蟾蜍
                case 103:
                case 115:
                    htmlStr = this.getLadders(this.gameResult);
                    break;
                //龍虎
                case 104:
                    htmlStr = this.getDT(this.gameResult);
                    break;
                //炸金花
                case 105:
                    htmlStr = this.getGDF(this.gameResult);
                    break;
                //百人牛牛
                case 106:
                    htmlStr = this.getBrnn(this.gameResult);
                    break;
                //森林大轉輪
                case 108:
                    htmlStr = this.getFWH(this.gameResult);
                    break;
                //奔馳寶馬
                case 109:
                    htmlStr = this.getSuperCar(this.gameResult);
                    break;
                //骰寶
                case 110:
                    htmlStr = this.getSibo(this.gameResult);
                    break;
                //左輪百家
                case 111:
                //骰子百家
                case 112:
                case 117:
                case 118:
                    htmlStr = this.getMoveCardBacc(this.gameResult);
                    break;
                case 107:
                case 113:
                    htmlStr = this.getMjClass(this.gameResult);
                    break;
                case 114:
                    htmlStr = this.getFsc(this.gameResult);
                    break;
                //五星宏輝
                case 119:
                    htmlStr = this.getFsp(this.gameResult);
                    break;
                //紅黑
                case 120:
                    htmlStr = this.getRedBlackWar(this.gameResult);
                    break;
                //色碟
                case 121:
                    htmlStr = this.getSEDIC(this.gameResult);
                    break;
                //番攤
                case 122:
                    htmlStr = this.getFanTan(this.gameResult);
                    break;
                //Teen Patti
                case 124:
                    htmlStr = this.getTeenPattiStr(this.gameResult);
                    break;
                //安達巴哈
                case 125:
                case 126:
                    htmlStr = this.getAndarBahar(this.gameResult);
                    break;
            }

            return htmlStr;
        },
        //色碟
        getSEDIC(gameResult) {
            let htmlStr = "";
            let length = gameResult.length;
            for (let i = 0; i < length; i++) {
                if (gameResult[i] === "1") {
                    htmlStr += `<i class="far fa-circle" style="color: #9a9a9a"></i> `;
                } else {
                    htmlStr += `<i class="fas fa-circle" style="color: red"></i> `;
                }
            }
            return htmlStr;
        },
        //番攤
        getFanTan(gameResult) {
            let htmlStr = "";
            let length = Number(gameResult);
            for (let i = 0; i < length; i++) {
                htmlStr += `<i class="far fa-circle" style="color: #9a9a9a;"></i> `;
            }

            return htmlStr;
        },
        //百家
        getBacc(gameResult, isShowCard) {
            let bankerNum = this.changePointThan10(parseInt(gameResult[1], 16))
                + this.changePointThan10(parseInt(gameResult[3], 16))
                + this.changePointThan10(parseInt(gameResult[5], 16));
            let bankerPoint = bankerNum % 10;

            let playerNum = this.changePointThan10(parseInt(gameResult[7], 16))
                + this.changePointThan10(parseInt(gameResult[9], 16))
                + this.changePointThan10(parseInt(gameResult[11], 16));
            let playerPoint = playerNum % 10;

            let bankerStr = this.$t('game.banker');
            let playerStr = this.$t('game.player');
            let winStr = this.$t('game.win');

            let htmlStr = "";
            if (bankerPoint > playerPoint) {
                htmlStr = `<span class="text-danger mr-1">${bankerStr}${winStr}</span>`;
            } else if (bankerPoint < playerPoint) {
                htmlStr = `<span class="text-info mr-1">${playerStr}${winStr}</span>`;
            } else {
                htmlStr = `<span class="text-success mr-1">${this.$t('game.tie')}</span>`;
            }

            htmlStr += `<span>[${bankerStr} : ${bankerPoint} `;
            htmlStr += `${playerStr} : ${playerPoint}]</span>`;

            if (isShowCard) {
                htmlStr += `<div class="d-flex mt-1"><span class="align-self-center mr-1">${bankerStr}</span>`;
                htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[0] - 1, parseInt(gameResult[1], 16) - 1) + "</span>";
                htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[2] - 1, parseInt(gameResult[3], 16) - 1) + "</span>";
                if (gameResult[4] !== "0") {
                    htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[4] - 1, parseInt(gameResult[5], 16) - 1) + "</span>";
                }
                htmlStr += "</div>";

                htmlStr += `<div class="d-flex mt-1"><span class="align-self-center mr-1">${playerStr}</span>`;
                htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[6] - 1, parseInt(gameResult[7], 16) - 1) + "</span>";
                htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[8] - 1, parseInt(gameResult[9], 16) - 1) + "</span>";
                if (gameResult[10] !== "0") {
                    htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[10] - 1, parseInt(gameResult[11], 16) - 1) + "</span>";
                }
                htmlStr += "</div>";
            }

            return htmlStr;
        },
        changePointThan10(point) {
            return point > 10 ? 10 : point;
        },
        //龍虎
        getDT(gameResult) {
            let dragonPoint = parseInt(gameResult[1], 16);
            let dragon = gameResult[0] * -1 + 5 + dragonPoint * 100;
            let tigerPoint = parseInt(gameResult[3], 16);
            let tiger = gameResult[2] * -1 + 5 + tigerPoint * 100;

            let htmlStr = "";
            let winStr = this.$t('game.win');
            let dragonStr = this.$t('game.area.104.1');
            let tigerStr = this.$t('game.area.104.2');

            if (dragon > tiger) {
                htmlStr = `<span class="text-primary mr-1">${dragonStr + winStr}</span>`;
            } else if (dragon < tiger) {
                htmlStr = `<span class="text-danger mr-1">${tigerStr + winStr}</span>`;
            } else {
                htmlStr = `<span class="text-success mr-1">${this.$t('game.tie')}</span>`;
            }

            htmlStr += `<span>[${dragonStr} : ${dragonPoint} `;
            htmlStr += `${tigerStr} : ${tigerPoint}]</span>`;

            htmlStr += `<div class="d-flex mt-1"><span class="align-self-center mr-1">${dragonStr}</span>`;
            htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[0] - 1, parseInt(gameResult[1], 16) - 1) + "</span></div>";
            htmlStr += `<div class="d-flex mt-1"><span class="align-self-center mr-1">${tigerStr}</span>`;
            htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[2] - 1, parseInt(gameResult[3], 16) - 1) + "</span></div>";
            return htmlStr;
        },
        //設龍門
        getDGP(gameResult) {
            let htmlStr = "";
            htmlStr += getPokerClass(gameResult[0] - 1, parseInt(gameResult[1], 16) - 1) + "&nbsp;";
            htmlStr += getPokerClass(gameResult[2] - 1, parseInt(gameResult[3], 16) - 1) + "&nbsp;";
            htmlStr += getPokerClass(gameResult[4] - 1, parseInt(gameResult[5], 16) - 1);

            return htmlStr;
        },
        //火蟾蜍
        getLadders(gameResult) {
            let htmlStr = "";

            if (gameResult[0] === "1") {
                htmlStr += `<span class="text-primary mr-1">${this.$t('game.leftStart')}</span>`;
            } else {
                htmlStr += `<span class="text-danger mr-1">${this.$t('game.rightStart')}</span>`;
            }

            if (gameResult[1] === "3") {
                htmlStr += `<span class="text-info mr-1">${this.$t('game.leftEnd')}</span>`;
            } else {
                htmlStr += `<span class="text-warning mr-1">${this.$t('game.rightEnd')}</span>`;
            }

            htmlStr += this.$tc('game.numberOfLadders', Number(gameResult[2]) - 2);
            return htmlStr;
        },
        //骰寶
        getSibo(gameResult) {
            let htmlStr = "";

            htmlStr += `${getDiceClass(gameResult[0])}` + "&nbsp;";
            htmlStr += getDiceClass(gameResult[1]) + "&nbsp;";
            htmlStr += getDiceClass(gameResult[2]);

            return htmlStr;
        },
        getFsc(gameResult) {
            let htmlStr = "";

            htmlStr += `${getFscClass(gameResult[0])}` + "&nbsp;";
            htmlStr += getFscClass(gameResult[1]) + "&nbsp;";
            htmlStr += getFscClass(gameResult[2]);

            return htmlStr;
        },
        //百人牛牛
        getBrnn(gameResult) {
            return brnnGameResult(gameResult, this);
        },
        //炸金花
        getGDF(gameResult) {
            return gdfGameResult(gameResult, this);
        },
        //森林大轉輪
        getFWH(gameResult) {
            let htmlStr = "";

            let type = Number(gameResult[0]);
            if (type > 0) {
                htmlStr += this.$t('game.area.108.nowSpecialRule.' + type) + " ";
            }

            htmlStr += "[";

            let length = gameResult.length;
            let awardList = []
            for (let i = 2; i < length; i++) {
                awardList.push(this.$t('game.area.108.' + parseInt(gameResult[i], 16)));
            }

            htmlStr += awardList.join(",") + "]";

            return htmlStr;
        },
        getMoveCardBacc(gameResult) {
            let htmlStr = "";

            let noStr = this.roomId + "-" + this.tableId + "-";

            htmlStr += `<div class="d-block mb-2"><span>${this.$t('no')}:${noStr}1 </span>`;
            htmlStr += this.getBacc(gameResult.substring(0, 12), true) + "</div>";
            htmlStr += `<div class="d-block mb-2 border-top border-info"><span>${this.$t('no')}:${noStr}2 </span>`;
            htmlStr += this.getBacc(gameResult.substring(12, 24), true) + "</div>";
            htmlStr += `<div class="d-block mb-2 border-top border-info"><span>${this.$t('no')}:${noStr}3 </span>`;
            htmlStr += this.getBacc(gameResult.substring(24, 36), true) + "</div>";
            htmlStr += `<div class="d-block mb-2 border-top border-info"><span>${this.$t('no')}:${noStr}4 </span>`;
            htmlStr += this.getBacc(gameResult.substring(36, 48), true) + "</div>";
            return htmlStr;
        },
        getSuperCar(gameResult) {
            return getSuperCarClass(gameResult);
        },
        getMjClass(gameResult) {
            let htmlStr = "";

            htmlStr += this.getMjStr(gameResult, 1);
            htmlStr += this.getMjStr(gameResult, 2);
            htmlStr += this.getMjStr(gameResult, 3);
            htmlStr += this.getMjStr(gameResult, 4);

            return htmlStr;
        },
        getMjStr(gameResult, num) {
            let htmlStr = "";

            htmlStr += `<div class="d-flex mt-1"><span class="align-self-center mr-1">${this.getBlockStr(num)}</span>`;
            let first = (num - 1) * 2;
            htmlStr += "<span class='mr-1 align-self-center'>" + getMjClass(parseInt(gameResult[first], 16)) + "</span>";
            htmlStr += "<span class='mr-1 align-self-center'>" + getMjClass(parseInt(gameResult[first + 1], 16)) + "</span>";
            htmlStr += "</div>";

            return htmlStr;
        },
        getBlockStr(num) {
            return this.$t('game.area.' + this.gameType + '.block.' + num);
        },
        getFsp(gameResult) {
            return getFspClass(gameResult[0]);
        },
        getRedBlackWar(gameResult) {
            let htmlStr = "";

            htmlStr += "<div class='d-flex mt-1'>" + `<span class='align-self-center mr-1'>${this.$t("game.area.120.1")}</span>`;
            htmlStr += getPokerClass(gameResult[0] - 1, parseInt(gameResult[1], 16) - 1) + "&nbsp;";
            htmlStr += getPokerClass(gameResult[2] - 1, parseInt(gameResult[3], 16) - 1) + "&nbsp;";
            htmlStr += getPokerClass(gameResult[4] - 1, parseInt(gameResult[5], 16) - 1);
            htmlStr += "</div>";

            htmlStr += "<div class='d-flex mt-1'>" + `<span class='align-self-center mr-1'>${this.$t("game.area.120.2")}</span>`;
            htmlStr += getPokerClass(gameResult[6] - 1, parseInt(gameResult[7], 16) - 1) + "&nbsp;";
            htmlStr += getPokerClass(gameResult[8] - 1, parseInt(gameResult[9], 16) - 1) + "&nbsp;";
            htmlStr += getPokerClass(gameResult[10] - 1, parseInt(gameResult[11], 16) - 1);
            htmlStr += "</div>"

            return htmlStr;
        },
        getAndarBahar(gameResult) {

            let andarPokerList = [];
            let baharPokerList = [];

            let htmlStr = "";


            let list = gameResult.match(/.{1,2}/g) || [];
            let length = list.length;
            for (let i = 0; i < length; i++) {
                let item = list[i];
                let poker = getPokerClass(item[0] - 1, parseInt(item[1], 16) - 1);
                poker = `<span class="ml-n2">${poker}</span>`;
                if (i === 0) {
                    htmlStr += "<div class='d-flex mt-1'>" + `<span class='align-self-center mr-1 mr-3'>${this.$t("game.banker")}</span>`;
                    htmlStr += poker;
                    htmlStr += "</div>";
                } else if (i % 2 === 1) {
                    andarPokerList.push(poker);
                } else {
                    baharPokerList.push(poker);
                }
            }

            htmlStr += "<div class='d-flex mt-1'>" + `<span class='align-self-center mr-1 mr-3'>${this.$t("game.area.125.1")}</span>`;
            htmlStr += andarPokerList.join("");
            htmlStr += "</div>";

            htmlStr += "<div class='d-flex mt-1'>" + `<span class='align-self-center mr-1 mr-3'>${this.$t("game.area.125.2")}</span>`;
            htmlStr += baharPokerList.join("");
            htmlStr += "</div>"


            return htmlStr;
        },
        getTeenPattiStr(gameResult) {
            let htmlStr = "";

            htmlStr += `<div class="d-flex mt-1"><span class="align-self-center mr-1">${this.$t('game.area.124.1')}</span>`;
            htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[0] - 1, parseInt(gameResult[1], 16) - 1) + "</span>";
            htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[2] - 1, parseInt(gameResult[3], 16) - 1) + "</span>";
            htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[4] - 1, parseInt(gameResult[5], 16) - 1) + "</span>";
            htmlStr += "</div>";

            htmlStr += `<div class="d-flex mt-1"><span class="align-self-center mr-1">${this.$t('game.area.124.2')}</span>`;
            htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[6] - 1, parseInt(gameResult[7], 16) - 1) + "</span>";
            htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[8] - 1, parseInt(gameResult[9], 16) - 1) + "</span>";
            htmlStr += "<span class='mr-1 align-self-center'>" + getPokerClass(gameResult[10] - 1, parseInt(gameResult[11], 16) - 1) + "</span>";
            htmlStr += "</div>";

            return htmlStr
        }
    }
}
</script>

<style scoped>

</style>
