import Vue from 'vue'
import App from './App.vue'
import {i18n} from './local'
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import JSONbig from "json-bigint";
import commonMixin from "@/mixin/commonMixin";

JSON = JSONbig;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueSweetalert2);

Vue.mixin(commonMixin);

Vue.config.productionTip = false

new Vue({
    i18n,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
