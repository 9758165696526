import {dateFormat, numberWithCommas} from "@/utils/common";

export default {
    data: function () {
        return {};
    },
    methods: {
        convertNumberWithCommas(number) {
            return numberWithCommas(number);
        },
        convertFloatNumberWithCommas(number, fixed = 2) {
            return numberWithCommas(number.toFixed(fixed));
        },
        convertMoney(currency, point, isFloat = false) {
            return this.convertFloatNumberWithCommas(point);
        },
        changeTimeZone(time) {
            return dateFormat(time);
        }
    }
}
