const account = '帳號';
const password = '密碼';
const name = '名稱';
const input = '請輸入';
const ok = "確定";
const cancel = "取消";
const add = "新增";
const update = "更新";
const agent = "代理";
const detail = "詳細";

export const currency = {
    "CNY": "人民幣",
    "USD": "美元",
    "EUR": "歐元",
    "JPY": "日圓",
    "VND": "越南盾",
    "AUD": "澳幣",
    "TWD": "新台幣",
    "MYR": "馬來西亞令吉",
    "IDR": "印尼盾",
    "SGD": "新加坡元",
    "GBP": "英鎊",
    "THB": "泰銖",
    "TRY": "土耳其里拉",
    "UAH": "烏克蘭格里夫納",
    "mXBT": "比特幣",
    "BND": "文萊元",
    "CAD": "加拿大元",
    "MMK*": "緬元",
    "NOK": "挪威克朗",
    "SEK": "瑞典克朗",
    "ZAR": "南非蘭德",
    "BDT": "孟加拉塔卡",
    "LKR": "斯里蘭卡盧比",
    "INR": "印度盧比",
    "RUB": "俄羅斯盧布",
    "PLN": "波蘭茲羅提",
    "AED": "阿聯酋迪拉姆",
    "KHR": "柬埔寨瑞爾",
    "BRL": "巴西雷亞爾",
    "XNB": "X幣(新台幣1:100)",
    "XNB2": "X幣(新台幣1:130)",
    "VND1000": "越南盾1000倍",
    "IDR1000": "印尼盾1000倍",
    "PHP": "菲律賓披索",
    "XUSD": "XUSD幣(美元1:1000)",
    "HKD": "港幣",
    "KRW": "南韓元",
    "MMK": "緬甸幣",
    "MMK1000": "緬甸幣1000倍",
};

export default {
    credit: "額度",
    content: "內容",
    killPage: "殺數",
    keyword: "關鍵字",
    operationRecord: "操作紀錄",
    unblock: "解除封鎖",
    default: "預設",
    api: "Api",
    apiInfo: "Api訊息",
    apiDownload: "下載API({version})說明",
    sdkDownload: "下載SDK({version})",
    playerCount: "玩家數量",
    dailyReport: "報表",
    type: "類型",
    features: "功能",
    memo: "備註",
    exchangePosition: "倉位",
    showTimeZone: {
        title: "顯示時區",
        detail: "美東 UTC-4"
    },
    line: "線",
    userInfo: "使用者資訊",
    unit: "單位",
    kick: "踢除",
    onlinePlayer: "在線玩家",
    currencyTable: "遊戲幣轉換表",
    currencySign: "符號",
    realMoney: "真實貨幣",
    recovery: "復原",
    recoveryAgentGameSetting: "復原成代理遊戲設定",
    gameMoney: "遊戲幣",
    reportProfit: "輸贏報表(日期時間搜尋)",
    reportProfitV3: "輸贏報表V3(日期時間搜尋)",
    reportProfitDay: "輸贏報表(日期搜尋)",
    all: "全部",
    open: "開啟",
    close: "關閉",
    success: "成功",
    failed: "失敗",
    rate: "賠率",
    update: update,
    add: add,
    detail: detail,
    time: "時間(美東 UTC-4)",
    totalValidBet: "總有效押注",
    totalBet: "總押注",
    totalBetCount: "總押注次數",
    totalWin: "總贏分",
    siteCode: "玩家帳號後綴碼(Site Code)",
    siteCodeLabel: "玩家帳號後綴碼Site Code(2~5英數混合)",
    validBet: "有效押注",
    profit: "代理輸贏",
    playerProfit: "玩家輸贏",
    hundred: "百人棋牌",
    gamePlayer: "玩家下注紀錄",
    gameRound: "局號",
    machineGameRound: "機器局號",
    gameResult: "遊戲結果",
    openingResult: "開獎結果",
    no: "編號",
    notAdd: "尚未" + add,
    enable: "是否啟用",
    delete: "刪除",
    isClose: "是否停用",
    clientGameShow: "是否開啟",
    isShowLobby: "是否大廳顯示",
    operating: "操作",
    defaultClientLanguage: "遊戲端預設語言",
    percent: "占成",
    payMoneyForUp: "交上線",
    float: "小數點第{n}位",
    rangeError: "範圍 {min}~{max}",
    ok: ok,
    zhTW: "繁體中文",
    zhCN: "簡體中文",
    vi: "越南語",
    hi: "印度語",
    required: "必填",
    loadingTable: "資料載入中...",
    updateData: "資料更新中...",
    cancel: cancel,
    noData: "目前沒有資料...",
    search: "搜尋",
    lan: '語言',
    balance: "餘額",
    account: account,
    password: password,
    rePassword: "重複" + password,
    rePasswordError: "重複" + password + "跟" + password + "不一樣",
    newPassword: "新" + password,
    reNewPassword: "重複" + "新" + password,
    reNewPasswordError: "重複" + "新" + password + "跟" + password + "不一樣",
    updatePassword: update + password,
    formError: "格式錯誤",
    name: name,
    input: input,
    createTime: "建立時間(美東 UTC-4)",
    loginTime: "登入時間(美東 UTC-4)",
    agent: agent,
    subAgent: "子" + agent,
    addAgent: add + "子" + agent,
    updateAgent: update + "子" + agent,
    updateMyselfAgent: update + "使用者",
    agentLevel: "代理層級",
    player: "玩家",
    subAccount: "子帳號",
    addSubAccount: add + "子帳號",
    updateSubAccount: update + "子帳號",
    currency: "貨幣",
    device: "裝置",
    gameType: "遊戲類型",
    gameTypeOptions: "選擇遊戲類型",
    betRuleSetting: "限紅設定",
    gameSetting: "遊戲設定",
    gameHundredBetRuleDetail: "百人棋牌限紅" + detail,
    updateGameHundredBetRule: "百人棋牌限紅",
    updateGameHundredBetRuleLimit: update + "限制子代理可選限紅",
    gamePkBetRuleDetail: "對戰棋牌限紅" + detail,
    updateGamePkBetRule: "對戰棋牌限紅",
    updateGamePkBetRuleLimit: update + "限制子代理可選限紅",
    playerHundredBetRule: "限紅設定",
    updatePlayerHundredBetRule: update + "限紅設定",
    addPlayerHundredBetRule: add + "限紅設定",
    chip: "籌碼",
    logout: "登出",
    login: {
        title: '',
        submit: '登入'
    },
    form: {
        totalElements: "總筆數",
        size: "每頁筆數",
        page: "頁碼"
    },
    report: {
        total: "加總",
        pageTotal: "本頁加總",
        subAgentTotal: "子代理加總",
        underAgentTotal: "下線代理輸贏總匯",
        playerTotal: "直屬玩家輸贏總匯",
        revenue: "我的營利"
    },
    minBet: "最小押注",
    maxBet: "最大押注",
    unKnowError: "發生不明錯誤",
    error: "發生錯誤",
    warning: "警告",
    az09: "必須英數(0~9 a~z A~Z)",
    noHaveGame: "沒有遊戲",
    playerState: {
        title: "狀態",
        1: "正常",
        2: "禁止下注",
        3: "黑名單"
    },
    singleWallet: "是否單一錢包",
    singleWalletUrl: "單一錢包 url",
    singleWalletUrlError: "單一錢包 url 格式錯誤",

    401: "沒有權限",

    dailyRtpReport: "RTP 報表",
    treeSearch: "包含子代理",
    count: "數量",
    version: "代理版本",
    home: "首頁",
    list: "列表",
    maintenanceTime: "維護時間",
    maintenance: "維護",
    maintenanceTimeContent: "每周一 00:00 ~ 03:00 ",

    gameTotalReport: "遊戲總報表",
    gameDailyReport: "遊戲每日報表",
    playerTotalReport: "玩家總報表",
    playerTotalReportV2: "指定玩家總報表",

    ...currency,

    controlSwitch: {
        0: "一般",
        1: "<span class='text-warning'>風控</span>",
        2: "<span class='text-danger'>殺數</span>"
    },

    closeAll: "關閉全部",
    closeOther: "關閉其他",

    gameRoundMultiAccount: "局號-多帳號查詢",
    gameRoundMultiAgent: "局號-多代理查詢",
    immediateRtpList: "即時RTP",
    selectAgent: "選擇代理",
    selectSubAgent: "選擇子代理",

    grant: "授權",
    grantGame: "授權遊戲",
    cancelGrant: "取消授權",
    batchGrantBtn: "批量授權遊戲/取消授權遊戲",
    pleaseSelect: "請選擇",
    playerAccountChangeAgentIdAlert: "帳號的代理與你目前的查詢代理不同<br>是否要更換代理?",

    tg: "TG",
    tgChatList: "聊天室列表",
    tgGroupSend: "發送訊息",

    selectAll: "選擇全部",
    unSelectAll: "取消全部",

    hedgingTransactionSearch: "對押查詢",
    repeatRange: "遊戲重複出現對比率(%)",
    totalGameRound: "總局數",

    notGrant: "未授權遊戲",
    autoGrantGame: "自動授權遊戲",
    ignoreOpenGame: "忽略檢查開啟遊戲",
    closeGame: "未開啟遊戲",
    agreeAutoGrantGameQA: "已選擇的代理將會自動授權遊戲，是否同意?",
    agreeIgnoreOpenGameQA: "已選擇的代理將會忽略檢查開啟遊戲，是否同意?",

    gameFirewall: "遊戲防火牆",

    slotMachine: '機台',
    multiplierTable: "倍數Symbol對應表",
    gameTotalMultiplier: '贏得倍数符號加總',
    baseGameTotalMultiplier: "一般遊戲累積倍數",
    freeGameTotalMultiplier: "免費遊戲累積倍數",

    cascadeMultiply: "消除倍數",
};
