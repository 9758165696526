<template>
    <div>
        <b-form>

            <b-form-group
                label="SID"
                label-for="game-type"
                :label-cols="3"
            >
                <div id="sid" class="col-form-label">{{ detail.sid }}</div>
            </b-form-group>

            <b-form-group
                :label="$t('gameType')"
                label-for="game-type"
                :label-cols="3"
            >
                <div id="game-type" class="col-form-label">{{ $t('game.' + detail.game_type) }}</div>
            </b-form-group>

            <b-form-group
                :label="$t('slotMachine')"
                label-for="slot-machine"
                :label-cols="3"
                v-if="detail.machine_table_id>0"
            >
              <div id="machine-table-id" class="col-form-label">{{ detail.machine_table_id }}</div>
            </b-form-group>

            <b-form-group
                :label="$t('account')"
                label-for="account"
                :label-cols="3"
            >
                <div id="account" class="col-form-label">{{ detail.account }}
                </div>
            </b-form-group>

            <b-form-group
                :label="$t('player')"
                label-for="player"
                :label-cols="3"
            >
                <div id="player" class="col-form-label">{{ detail.player_name }}
                </div>
            </b-form-group>

            <b-form-group
                label="IP"
                label-for="ip"
                :label-cols="3"
            >
                <div id="ip" class="col-form-label">{{ detail.client_ip }}</div>
            </b-form-group>

            <b-form-group
                :label="$t('device')"
                label-for="account"
                :label-cols="3"
            >
                <div id="device" class="col-form-label">{{ detail.device }}</div>
            </b-form-group>

            <b-form-group
                :label="$t('gameRound')"
                label-for="game-round"
                :label-cols="3"
            >
                <div id="game-round" class="col-form-label">{{ getGameRoundStr() }}</div>
            </b-form-group>

            <b-form-group
                :label="$t('totalBet')"
                label-for="bet"
                :label-cols="3"
            >
                <div id="bet" class="col-form-label">{{ convertMoney(getCurrency(), detail.bet_point, true) }}
                </div>
            </b-form-group>

            <b-form-group
                :label="$t('totalWin')"
                label-for="win"
                :label-cols="3"
            >
                <div id="win" class="col-form-label">{{ convertMoney(getCurrency(), detail.win_point, true) }}
                </div>
            </b-form-group>

            <b-form-group
                :label="$t('playerProfit')"
                label-for="player-profit"
                :label-cols="3">

                <div v-if="detail.profit >= 0 " class="col-form-label text-success">
                    {{ convertMoney(getCurrency(), detail.profit, true) }}
                </div>
                <div v-else class="col-form-label text-danger">
                    {{ convertMoney(getCurrency(), detail.profit, true) }}
                </div>
            </b-form-group>

            <!--建立時間-->
            <b-form-group
                :label="$t('createTime')"
                label-for="create-time"
                :label-cols="3"
            >
                <div id="game-create-time" class="col-form-label">{{ changeTimeZone(detail.create_time) }}
                </div>
            </b-form-group>

            <b-form-group
                v-if="gameData && gameData[0].pay_for_free_game_point"
                :label="$t('slot.payForFreeGame')"
                label-for="pay-for-free-game"
                class="text-info"
                :label-cols="3">
                {{ convertMoney(getCurrency(), gameData[0].pay_for_free_game_point, true) }}
            </b-form-group>

            <b-form-group
                v-if="gameData && gameData[0].pay_for_double_chance_game_point"
                :label="$t('slot.payForDoubleChance')"
                label-for="pay-for-free-game"
                class="text-info"
                :label-cols="3">
                {{ convertMoney(currencyType, gameData[0].pay_for_double_chance_game_point, true) }}
            </b-form-group>

            <b-form-group
                v-if="gameData && gameData[0].pay_for_super_spin_point"
                :label="$t('slot.payForSuperSpin')"
                label-for="pay-for-free-game"
                class="text-info"
                :label-cols="3">
                <i :class="`icon-tiny slot-${detail.game_type} slot-${detail.game_type}-${gameData[0].expand_symbol}`"></i>
<!--                <i :class="`icon-tiny slot-10022 slot-10022-${gameData[0].expand_symbol}`"></i>-->
                <span>
                    {{ convertMoney(getCurrency(), gameData[0].pay_for_super_spin_point, true) }}
                </span>
            </b-form-group>

        </b-form>

        <slot-normal-table
            v-if="checkNormalSlot()"
            :item="detail"
            :game-type="detail.game_type"
            :currency-type="getCurrency()"
            :game-data="gameData"
            ref="slotNormalTable"
        />

        <slot-huga-table
            v-else-if="detail.game_type === 10019"
            :item="detail"
            :game-type="detail.game_type"
            :currency-type="getCurrency()"
            :game-data="gameData"
            ref="slotHugaTable"
        />

        <slot-five-blessings-detail
            v-else-if="detail.game_type === 20002"
            :item="detail"
            :game-type="detail.game_type"
            :currency-type="getCurrency()"
            :game-data="gameData"
            ref="slotFiveBlessingsDetail"
        />

        <slot-crush-table
            v-else-if="checkCrushSlot()"
            :item="detail"
            :game-type="detail.game_type"
            :currency-type="getCurrency()"
            :game-data="gameData"
            ref="slotCrushTable"
        />

        <slot-monopoly-table
            v-else-if='checkMonopolySlot()'
            :item='item'
            :game-type='gameType'
            :currency-type='currencyType'
            :game-data='gameData'
            ref='slotMonopolyTable'
        />

        <slot-explode-table
            v-else
            :item="detail"
            :game-type="detail.game_type"
            :currency-type="getCurrency()"
            :game-data="gameData"
            ref="slotExplodeTable"
        />

    </div>
</template>

<script>
import SlotNormalTable from "@/components/slot/SlotNormalTable";
import SlotExplodeTable from "@/components/slot/SlotExplodeTable";
import SlotFiveBlessingsDetail from "@/components/slot/SlotFiveBlessingsDetail";
import SlotHugaTable from "@/components/slot/SlotHugaTable";
import SlotCrushTable from "@/components/slot/SlotCrushTable";
import SlotMonopolyTable from "@/components/slot/SlotMonopolyTable";
import {updateGameDate} from "@/utils/Slot";

export default {
    name: "SlotGameDetail",
    components: {
        SlotNormalTable,
        SlotExplodeTable,
        SlotFiveBlessingsDetail,
        SlotHugaTable,
        SlotCrushTable,
        SlotMonopolyTable
    },
    props: {
        detail: {type: Object, default: {}, required: false}
    },
    data() {
        return {
            gameData: null,
        }
    },
    mounted() {
        let gameData = JSON.parse(this.detail.game_detail);
        this.gameData = updateGameDate(this.detail.game_type, gameData);
        this.$nextTick(() => {
            if (this.detail.win > 0) {
                if (this.checkNormalSlot()) {
                    this.$refs.slotNormalTable.startWinBoxAnimation();
                } else if (this.detail.game_type === 10019) {
                    this.$refs.slotHugaTable.startWinBoxAnimation();
                }
            }
        })
    },
    methods: {
        checkNormalSlot() {
            return ![10014, 10017, 10018, 10019, 10024, 10026, 10027, 10029, 10030, 10032, 10033, 10034, 10036, 20002].includes(this.detail.game_type);
        },
        checkCrushSlot() {
            return [10024, 10026, 10029, 10032, 10033, 10036].includes(this.detail.game_type);
        },
        checkMonopolySlot() {
            return [10031].includes(this.gameType);
        },
        getGameRoundStr() {
            return BigInt(this.detail.id_str).toString(16).toUpperCase();
        },
        getCurrency() {
            return this.detail.cur_name;
        },
    }
}
</script>

<style scoped>

</style>
