<template>
    <b-table
            sticky-header
            striped
            hover
            responsive="xl"
            :items="items"
            :fields="getFields()">

        <template v-slot:cell(area)="data">
            <div v-html="$t('game.area.' + gameType + '.' + data.item.area)"></div>
        </template>

        <template v-slot:cell(bet)="data">
            <div class="text-right">{{ convertMoney(data.item.cur_name || currencyType,data.item.bet) }}</div>
        </template>

        <template v-slot:cell(win)="data">
            <div class="text-right">{{ convertMoney(data.item.cur_name || currencyType,data.item.win,true) }}</div>
        </template>

        <template v-slot:cell(profit)="data" class="text-right">
            <div v-if="data.item.win - data.item.bet >= 0 " class="text-right text-success">
                {{convertMoney(data.item.cur_name || currencyType,data.item.win - data.item.bet,true) }}
            </div>
            <div v-else class="text-right text-danger">
                {{ convertMoney(data.item.cur_name || currencyType,data.item.win - data.item.bet,true) }}
            </div>
        </template>

    </b-table>
</template>
<script>
    export default {
        name: 'gameRoundBetAreaTable',
        props: {
            gameType: {},
            items: {},
            currencyType: null,
        },
        methods: {
            getFields() {
                return [
                    {
                        key: 'area',
                        label: this.$t('game.betArea'),
                        sortable: true
                    },
                    {
                        key: 'bet',
                        label: this.$t('totalBet'),
                        thClass: "text-right",
                        sortable: true
                    },
                    {
                        key: 'win',
                        label: this.$t('totalWin'),
                        thClass: "text-right",
                        sortable: true
                    },
                    {
                        key: 'profit',
                        label: this.$t('playerProfit'),
                        thClass: "text-right",
                        sortable: true
                    }
                ];
            }
        }
    }
</script>
