const nnWinType = {
    1: "五小牛 <span class='text-primary'>4倍</span>",
    2: "四炸 <span class='text-primary'>4倍</span>",
    3: "五花牛 <span class='text-primary'>4倍</span>",
    4: "四花牛 <span class='text-primary'>4倍</span>",
    5: "牛牛 <span class='text-primary'>3倍</span>",
    6: "牛九 <span class='text-primary'>2倍</span>",
    7: "牛八 <span class='text-primary'>2倍</span>",
    8: "牛七 <span class='text-primary'>2倍</span>",
    9: "牛六 <span class='text-primary'>1倍</span>",
    10: "牛五 <span class='text-primary'>1倍</span>",
    11: "牛四 <span class='text-primary'>1倍</span>",
    12: "牛三 <span class='text-primary'>1倍</span>",
    13: "牛二 <span class='text-primary'>1倍</span>",
    14: "牛一 <span class='text-primary'>1倍</span>",
    15: "沒牛 <span class='text-primary'>1倍</span>",
};

export default {
    choose1to6: "只能選 1 ~ 6組",
    antes: "底注",
    entranceLimitOdds: "准入倍率",
    maxBetOdds: "限紅倍率",
    people: "人數",
    isPVP: "是否真人對戰",
    isBot: "機器人",
    yes: "是",
    no: "否",
    isSafe: "買保險",
    isSideBet: "額外押注",
    isSplit: "分牌",
    betRatio: "押注 {num} 倍",
    bankerRatio: "莊家 {num} 倍",
    nonSnatch: "不搶莊",
    reasonForOut: {
        1: "棄牌",
        2: "比牌失敗",
        3: "比牌失敗"
    },
    riskControl: {
        0: "一般",
        1: "<span class='text-danger'>殺數</span>"
    },
    //對戰炸金花
    301: {
        winType: {
            1: "豹子",
            2: "順金",
            3: "金花",
            4: "順子",
            5: "對子",
            6: "高牌",
            7: "特殊",
        }
    },
    //搶莊牛牛
    302: {
        winType: nnWinType
    },
    //急速炸金花
    303: {
        winType: {
            1: "豹子 <span class='text-primary'>40倍</span>",
            2: "同花順 <span class='text-primary'>20倍</span>",
            3: "金花 <span class='text-primary'>10倍</span>",
            4: "順子 <span class='text-primary'>6倍</span>",
            5: "對子 <span class='text-primary'>4倍</span>",
            6: "散牌 <span class='text-primary'>2倍</span>",
        }
    },
    //單挑牛牛
    304: {
        winType: {
            1: "五小牛 <span class='text-primary'>15倍</span>",
            2: "四炸 <span class='text-primary'>15倍</span>",
            3: "五花牛 <span class='text-primary'>15倍</span>",
            4: "四花牛 <span class='text-primary'>15倍</span>",
            5: "牛牛 <span class='text-primary'>10倍</span>",
            6: "牛九 <span class='text-primary'>9倍</span>",
            7: "牛八 <span class='text-primary'>8倍</span>",
            8: "牛七 <span class='text-primary'>7倍</span>",
            9: "牛六 <span class='text-primary'>6倍</span>",
            10: "牛五 <span class='text-primary'>5倍</span>",
            11: "牛四 <span class='text-primary'>4倍</span>",
            12: "牛三 <span class='text-primary'>3倍</span>",
            13: "牛二 <span class='text-primary'>2倍</span>",
            14: "牛一 <span class='text-primary'>1倍</span>",
            15: "沒牛 <span class='text-primary'>1倍</span>",
        }
    },
    //通比牛牛
    305: {
        winType: nnWinType
    },
    //搶莊二八槓
    306: {
        winType: {
            1: "鱉十",
            2: "{n}點",
            3: "二八槓",
            4: "對子",
        },
    },
    //搶莊推筒子
    307: {
        winType: {
            1: "鱉十",
            2: "{n}點",
            3: "對子",
        }
    },
    308: {
        winType: {
            1: "爆玖 <span class='text-primary'>5倍</span>",
            2: "炸彈 <span class='text-primary'>4倍</span>",
            3: "三公 <span class='text-primary'>3倍</span>",
            4: "9點 <span class='text-primary'>2倍</span>",
            5: "8點 <span class='text-primary'>2倍</span>",
            6: "7點 <span class='text-primary'>2倍</span>",
            7: "6點 <span class='text-primary'>1倍</span>",
            8: "5點 <span class='text-primary'>1倍</span>",
            9: "4點 <span class='text-primary'>1倍</span>",
            10: "3點 <span class='text-primary'>1倍</span>",
            11: "2點 <span class='text-primary'>1倍</span>",
            12: "1點 <span class='text-primary'>1倍</span>",
            13: "0點 <span class='text-primary'>1倍</span>",
        }
    },
    309: {
        winType: {
            1: "單牌0點",
            2: "單牌1點",
            3: "單牌2點",
            4: "單牌3點",
            5: "單牌4點",
            6: "單牌5點",
            7: "單牌6點",
            8: "單牌7點",
            9: "單牌8點",
            10: "單牌9點",
            11: "地高九",
            12: "天高九",
            13: "地槓",
            14: "天槓",
            15: "地王",
            16: "天王",
            17: "雜五",
            18: "雜七",
            19: "雜八",
            20: "雜九",
            21: "雙零霖",
            22: "雙高腳",
            23: "雙紅頭",
            24: "雙斧頭",
            25: "雙板凳",
            26: "雙長三",
            27: "雙梅",
            28: "雙鵝",
            29: "雙人",
            30: "雙地",
            31: "雙天",
            32: "至尊",
        }
    },
    //對戰炸金花
    310: {
        winType: {
            1: "豹子",
            2: "順金",
            3: "金花",
            4: "順子",
            5: "對子",
            6: "高牌",
            7: "特殊",
        }
    },
    //越南撲克
    311: {
        winType: {
            1: "三張人臉",
            2: "九點",
            3: "八點",
            4: "七點",
            5: "六點",
            6: "五點",
            7: "四點",
            8: "三點",
            9: "二點",
            10: "一點",
            11: "爆牌"
        }
    },
    312: {
        winType: {
            1: "Pok9 X2",
            2: "Pok9",
            3: "Pok8 X2",
            4: "Pok8",
            5: "Tong",
            6: "Straight Flush",
            7: "Straight",
            8: "Sam Lueang",
            9: "Taem X3",
            10: "Taem X2",
            11: "Taem"
        }
    },
    //21點
    313: {
        winType: {
            1: "Black Jack",
            2: "五小龍",
            3: "{n}點",
        }
    },
    //比大小
    314: {
        sideBet: "額外押注",
        winType: {
            1: "爆牌",
            2: "過一張",
            3: "過二張",
            4: "過三張",
            5: "過四張",
            6: "過五張",
            7: "過六張",
            8: "過七張",
        }
    },
}
