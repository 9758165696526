import commonCN from "@/local/common/commonCN";
import gameCN from "@/local/game/gameCN";
import errorCN from "@/local/error/errorCN";
import SlotCN from "@/local/slot/slotCN";
import pkCN from "@/local/pk/pkCN";
import stgCN from "@/local/stg/stgCN";


export default {
    ...commonCN,
    slot: SlotCN,
    pk: pkCN,
    stg: stgCN,
    game: gameCN,
    ...errorCN,
}
