<template>
  <div class="stg">
    <b-form>
      <b-form-group
          label="SID"
          label-for="game-type"
          :label-cols="3"
      >
        <div id="game-type" class="col-form-label">{{ detail.sid }}</div>
      </b-form-group>

      <b-form-group
          :label="$t('gameType')"
          label-for="game-type"
          :label-cols="3"
      >
        <div id="game-type" class="col-form-label">{{ $t('game.' + detail.game_type) }}</div>
      </b-form-group>

      <b-form-group
          :label="$t('account')"
          label-for="account"
          :label-cols="3"
      >
        <div id="account" class="col-form-label">{{ detail.account }}
        </div>
      </b-form-group>

      <b-form-group
          :label="$t('player')"
          label-for="player"
          :label-cols="3"
      >
        <div id="player" class="col-form-label">{{ detail.player_name }}
        </div>
      </b-form-group>

      <b-form-group
          label="IP"
          label-for="account"
          :label-cols="3"
      >
        <div id="ip" class="col-form-label">{{ detail.client_ip }}</div>
      </b-form-group>

      <b-form-group
          :label="$t('device')"
          label-for="account"
          :label-cols="3"
      >
        <div id="ip" class="col-form-label">{{ detail.device }}</div>
      </b-form-group>

      <b-form-group
          :label="$t('totalBet')"
          label-for="bet"
          :label-cols="3"
      >
        <div id="bet" class="col-form-label">{{ convertMoney(getCurrency(), detail.bet_point, true) }}
        </div>
      </b-form-group>

      <b-form-group
          :label="$t('totalWin')"
          label-for="win"
          :label-cols="3"
      >
        <div id="win" class="col-form-label">{{ convertMoney(getCurrency(), detail.win_point, true) }}
        </div>
      </b-form-group>

      <b-form-group
          :label="$t('playerProfit')"
          label-for="player-profit"
          :label-cols="3">

        <div v-if="detail.profit >= 0 " class="col-form-label text-success">
          {{ convertMoney(getCurrency(), detail.profit, true) }}
        </div>
        <div v-else class="col-form-label text-danger">
          {{ convertMoney(getCurrency(), detail.profit, true) }}
        </div>
      </b-form-group>

      <!--建立時間-->
      <b-form-group
          :label="$t('createTime')"
          label-for="create-time"
          :label-cols="3"
      >
        <div id="game-create-time" class="col-form-label">{{ changeTimeZone(detail.create_time) }}
        </div>
      </b-form-group>

      <b-form-group
          :label="$t('stg.bulletCount')"
          label-for="game-bullet-count"
          :label-cols="3"
      >
        <div id="game-bullet-count" class="col-form-label">
          {{ convertNumberWithCommas(detail.bullet_count) }}
        </div>
      </b-form-group>

      <b-form inline>
        <div class="flex-grow-1 bd-highlight d-inline-block">
        </div>
        <div class="p-2 bd-highlight d-inline-block">
          <label class="mr-sm-2 d-inline-block">{{
              $t('form.totalElements') + ' : ' +
              convertNumberWithCommas(totalRows)
            }} </label>
          <label class="mr-sm-2 d-inline-block">{{ $t('form.size') + ' : ' }} </label>

          <b-form-select
              size="sm"
              :plain="true"
              :options="sizeOptions"
              v-model="perPage"
          >
          </b-form-select>
        </div>
      </b-form>

      <b-table
          class="mt-2"
          :current-page="currentPage"
          :per-page="perPage"
          striped
          hover
          responsive="xl"
          :items="gameData"
          :fields="getFields()">

        <template v-slot:cell(fish_kind)="row">
          <div>
                          <span class="text-danger mr-1" v-if="row.item.action_type === 3">
                            {{ $t('stg.refund') }}
                          </span>
            <i v-else :class="getIconCss(row.value)"></i>
          </div>
        </template>

        <template v-slot:cell(fish_odds)="data">
          {{ convertFishOdds(data.value) }}
        </template>

        <template v-slot:cell(bet_point)="data">
          <div class="text-right">
            {{ convertMoney(getCurrency(), data.value || 0, true) }}
          </div>
        </template>

        <template v-slot:cell(player_profit)="data" class="text-right">
          <div v-if="data.value >= 0 " class="text-right text-success">
            {{ convertMoney(getCurrency(), data.value, true) }}
          </div>
          <div v-else class="text-right text-danger">
            {{ convertMoney(getCurrency(), data.value, true) }}
          </div>
        </template>

        <template v-slot:cell(bet_time)="data">
          {{ changeTimeZone(data.value) }}
        </template>

      </b-table>

      <div class="container">
        <div class="d-flex flex-row justify-content-center ">
          <b-pagination
              size="md"
              first-number
              last-number
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
          ></b-pagination>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>

export default {
  name: "StgGameDetail",
  data() {
    return {
      sizeOptions: [
        {value: 10, text: 10},
        {value: 20, text: 20},
        {value: 30, text: 30},
        {value: 50, text: 50},
        {value: 100, text: 100},
      ],
      totalRows: 1,
      currentPage: 1,
      gameType: null,
      perPage: 10,
      gameData: null,
    }
  },
  props: {
    detail: {type: Object, default: {}, required: false}
  },
  mounted() {
    this.gameType = this.detail.game_type;
    let gameData = JSON.parse(this.detail.game_detail);
    gameData = gameData.filter(item => item.action_type !== 1);
    gameData.forEach((item, index) => {
      item.seq = index + 1;
      item.player_profit = item.win_point - item.bet_point;
    });
    this.gameData = gameData;
    this.totalRows = gameData.length
    this.currentPage = 1
  },
  methods: {
    getItems() {
      let items = this.gameData[this.gameData.length - 1].data;
      return items || [];
    },
    getFields() {
      return [
        {
          key: 'seq',
          label: '#',
          sortable: true
        },
        {
          key: 'fish_kind',
          label: '',
          sortable: true
        },
        {
          key: 'fish_odds',
          label: this.$t('stg.odds'),
          sortable: true
        },
        {
          key: 'bet_point',
          label: this.$t('totalBet'),
          thClass: "text-right",
          sortable: true
        },
        {
          key: 'player_profit',
          label: this.$t('playerProfit'),
          thClass: "text-right",
          sortable: true
        },
        {
          key: 'bet_time',
          label: this.$t('createTime'),
          sortable: true
        },
      ]
    },
    getIconCss(kind) {
      return `icon-stg stg-${this.gameType} stg-${this.gameType}-${kind} `
    },
    getCurrency() {
      return this.detail.cur_name;
    },
    convertFishOdds(fishOdds) {
      return `×${fishOdds || 0}`
    }
  }
}
</script>

<style>
.stg > .table th, .table td {
  vertical-align: middle !important;
}
</style>
