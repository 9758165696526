export default {
    choose1to3: "只能选 1 ~ 3组",
    betRang: "押注范围",
    minBet: "最小押注",
    maxBet: "最大押注",
    bulletCount: "子弹数量",
    updateGameBetRule: "鱼机限红",
    updateGameBetRuleLimit: "更新限制子代理可选限红",
    winLineCount: "总连线数目",
    baseGame: "一般游戏",
    freeGame: "免费游戏",
    multiplier: "倍率",
    refund: "退款(押注失败)",
    wagerType: {
        0: "一般",
        1: "<span class='text-danger'>杀数</span>",
        2: "<span class='text-warning'>注销</span>"
    },
    odds: "赔率",
}
