const nnWinType = {
    1: "5-Small-Bull <span class='text-primary'>x4</span>",
    2: "Bomb <span class='text-primary'>x4</span>",
    3: "5-Face-Bull <span class='text-primary'>x4</span>",
    4: "4-Face-Bull <span class='text-primary'>x4</span>",
    5: "Bull-Bull <span class='text-primary'>x3</span>",
    6: "Bull 9 <span class='text-primary'>x2</span>",
    7: "Bull 8 <span class='text-primary'>x2</span>",
    8: "Bull 7 <span class='text-primary'>x2</span>",
    9: "Bull 6 <span class='text-primary'>x1</span>",
    10: "Bull 5 <span class='text-primary'>x1</span>",
    11: "Bull 4 <span class='text-primary'>x1</span>",
    12: "Bull 3 <span class='text-primary'>x1</span>",
    13: "Bull 2 <span class='text-primary'>x1</span>",
    14: "Bull 1<span class='text-primary'>x1</span>",
    15: "No Bull <span class='text-primary'>x1</span>",
};

export default {
    choose1to6: "Only choose 1 ~ 6 sets of Bet Limit",
    antes: "Antes",
    entranceLimitOdds: "Entrance Limit Odds",
    maxBetOdds: "Max Bet Odds",
    people: "Number of user",
    isPVP: "PVP or not",
    isBot: "AI or not",
    yes: "Yes",
    no: "No",
    isSafe: "Buy Insurance",
    isSideBet: "Side Bet",
    isSplit:"Split",
    betRatio:"Bet {num} times",
    bankerRatio:"Banker {num} times",
    nonSnatch: "non snatch",
    reasonForOut: {
        1: "Fold",
        2: "Compare Lose",
        3: "Compare Lose"
    },
    riskControl: {
        0: "一般",
        1: "<span class='text-danger'>杀数</span>"
    },
    //對戰炸金花
    301: {
        winType: {
            1: "Triple",
            2: "Straight Flush",
            3: "Golden Flower",
            4: "Straight",
            5: "Pair",
            6: "High Card",
            7: "Different Suit 532",
        }
    },
    //搶莊牛牛
    302: {
        winType: nnWinType
    },
    //急速炸金花
    303: {
        winType: {
            1: "Triple <span class='text-primary'>x40</span>",
            2: "Straight Flush <span class='text-primary'>x20</span>",
            3: "Golden Flower <span class='text-primary'>x10</span>",
            4: "Straight <span class='text-primary'>x6</span>",
            5: "Pair <span class='text-primary'>x4</span>",
            6: "High Card <span class='text-primary'>x2</span>",
        }
    },
    //單挑牛牛
    304: {
        winType:nnWinType
    },
    //通比牛牛
    305: {
        winType: nnWinType
    },
    //搶莊二八槓
    306: {
        winType: {
            1: "0 Dots",
            2: "{n}dot",
            3: "Two Eight Bars",
            4: "Pair",
        },
    },
    //搶莊推筒子
    307: {
        winType: {
            1: "0 Dots",
            2: "{n}dot",
            3: "Pair",
        }
    },
    308: {
        winType: {
            1: "Bomb 9 (333) <span class='text-primary'>x5</span>",
            2: "Bomb <span class='text-primary'>x4</span>",
            3: "San Gong <span class='text-primary'>x3</span>",
            4: "9 dots <span class='text-primary'>x2</span>",
            5: "8 dots <span class='text-primary'>x2</span>",
            6: "7 dots <span class='text-primary'>x2</span>",
            7: "6 dots <span class='text-primary'>x1</span>",
            8: "5 dots <span class='text-primary'>x1</span>",
            9: "4 dots <span class='text-primary'>x1</span>",
            10: "3 dots <span class='text-primary'>x1</span>",
            11: "2 dots <span class='text-primary'>x1</span>",
            12: "1 dot <span class='text-primary'>x1</span>",
            13: "0 dot <span class='text-primary'>x1</span>",
        }
    },
    309: {
        winType: {
            1: "Single Tile 0 Dot",
            2: "Single Tile 1 Dot",
            3: "Single Tile 2 Dots",
            4: "Single Tile 3 Dots",
            5: "Single Tile 4 Dots",
            6: "Single Tile 5 Dots",
            7: "Single Tile 6 Dots",
            8: "Single Tile 7 Dots",
            9: "Single Tile 8 Dots",
            10: "Single Tile 9 Dots",
            11: "Day Gow Nine",
            12: "Teen Gow Nine",
            13: "Day Gong",
            14: "Teen Gong",
            15: "Day Wong",
            16: "Teen Wong",
            17: "Pair Chop Ng",
            18: "Pair Chop Chit",
            19: "Pair Chop Bot",
            20: "Pair Chop Gow",
            21: "Pair Look",
            22: "Pair Tit",
            23: "Pair Ping",
            24: "Pair Foo",
            25: "Pair Bon",
            26: "Pair Chong",
            27: "Pair Mooy",
            28: "Pair Gor",
            29: "Pair Yun",
            30: "Pair Day",
            31: "Pair Teen",
            32: "Gee Joon",
        }
    },
    //對戰炸金花
    310: {
        winType: {
            1: "Triple",
            2: "Straight Flush",
            3: "Golden Flower",
            4: "Straight",
            5: "Pair",
            6: "High Card",
            7: "Different Suit 532",
        }
    },
    //越南撲克
    311:{
        winType: {
            1:"Three Face",
            2:"Nine Point",
            3:"Eight Point",
            4:"Seven Point",
            5:"Six Point",
            6:"Five Point",
            7:"Four Point",
            8:"Three Point",
            9:"Two Point",
            10:"One Point",
            11:"Busted"
        }
    },
    312: {
        winType: {
            1: "Pok9 X2",
            2: "Pok9",
            3: "Pok8 X2",
            4: "Pok8",
            5: "Tong",
            6: "Straight Flush",
            7: "Straight",
            8: "Sam Lueang",
            9: "Taem X3",
            10: "Taem X2",
            11: "Taem"
        }
    },
    //21點
    313: {
        winType: {
            1: "Black Jack",
            2: "5-card Charlie",
            3: "{n}point",
        }
    },
    //比大小
    314: {
        winType: {
            1: "Bust",
            2: "one pass",
            3: "two pass",
            4: "three pass",
            5: "four pass",
            6: "five pass",
            7: "six pass",
            8: "seven pass",
        }
    },
}
