import {i18n} from "@/local";
import version from "@/utils/version";
import {getNow} from "@/utils/common";

export function showError(self, error, custom) {
    console.error(error, typeof error);

    let title = "";
    let content = "";
    let code = null;

    if (custom) {
        title = i18n.t('error');
        content = error.message;
    } else if (error instanceof Error) {
        title = i18n.t('unKnowError');
        content = error.message;
    } else if (typeof error === "object") {
        code = error.code;

        title = i18n.t('error') + "-" + code;
        let errorMsg = i18n.t('' + code);

        //沒白名單內
        if(code === "113000042"){
            errorMsg += error.data.client_ip
        }

        content = error.apiCode + "<br><span class='text-danger'><strong>" + errorMsg + "</strong></span>";
    } else {
        title = i18n.t('unKnowError');
        content = error.length > 0 ? error : content;
    }

    self.$swal.fire({
        icon: 'error',
        title: title,
        html: convertErrorContent(content),
        confirmButtonText: i18n.t('ok'),
        cancelButtonText: i18n.t('cancel'),
        reverseButtons: true,
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false
    });
}

export function convertErrorContent(content) {
    content += `<br><span class="text-small">${getNow()}</span>`
    return content;
}


/**
 * @return {boolean}
 */
export function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
