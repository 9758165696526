import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhCN from "@/local/zhCN";
import zhTW from "@/local/zhTW";
import en from "@/local/en";

Vue.use(VueI18n);

const navLang = navigator.language;
const cacheLang = localStorage.getItem('lang');
const localLang = (navLang === 'zh-CN' || navLang === 'zh-TW') ? navLang : false;
let lang = cacheLang || localLang || 'en';

const i18n = new VueI18n({
    locale: lang,
    messages: {
        'zh-CN': zhCN,
        'zh-TW': zhTW,
        'en': en,
    }
});

window.i18n = i18n;

export {i18n}
