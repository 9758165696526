export default {
    choose1to3: "Only choose 1 ~ 3 sets of Bet Limit",
    betRang: "Bet Range",
    minBet: "MiniBet",
    maxBet: "MaxBet",
    bulletCount: "Bullet Count",
    updateGameBetRule: "Slot Limit",
    updateGameBetRuleLimit: "Update Choosiable Bet Limits of SubAgent",
    winLineCount: "Total Winline",
    baseGame: "MainGame",
    freeGame: "FreeGame",
    refund: "Refund(Bet Fail)",
    wagerType: {
        0: "一般",
        1: "殺數",
        2: "註銷"
    },
    odds: "Odds",
}
