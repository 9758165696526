import {i18n} from "@/local";

/**
 * 數字3位一撇 (支持浮點數)
 * @param number
 */
export const numberWithCommas = (number) => {
    let parts = number.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
};

export const moment = require('moment-timezone');

export const dateFormatStr = "YYYY/MM/DD HH:mm:ss";
export const dayFormatStr = "YYYY/MM/DD";

export const dateFormat = (dateTime) => {
    if (!dateTime.includes("T") || !(dateTime.includes("+") || dateTime.includes("-"))) {
        let split = dateTime.split(" ");
        dateTime = split[0] + "T" + split[1] + "+08:00";
    }

    return moment(dateTime).tz("America/Aruba").format(dateFormatStr);
};

export const dateToISOString = (str) => {
    str = str.replace(" ", "T");
    str = str.replace(/\//g, "-");
    return str + "-04:00";
}

export const getNowMonth = () => {
    let check = moment().tz("America/Aruba");
    let startOfMonth = check.startOf('month').format(dateFormatStr);
    let endOfMonth = check.endOf('month').format(dateFormatStr);

    return {
        startTime: startOfMonth, endTime: endOfMonth
    }
}

export const getNowMonthDay = () => {
    let check = moment().tz("America/Aruba");
    let startOfMonth = check.startOf('month').format(dayFormatStr);
    let endOfMonth = check.endOf('month').format(dayFormatStr);

    return {
        startTime: startOfMonth, endTime: endOfMonth
    }
}
export const getNowDay = () => {
    let check = moment().tz("America/Aruba");
    return check.subtract(1, 'd').format(dayFormatStr);
}

export const getYesterday = () => {
    let check = moment().tz("America/Aruba");
    return check.subtract(1, 'd').format(dayFormatStr);
}

export const getNow = () => {
    return moment().tz("America/Aruba").format(dateFormatStr);
}

export const listToTree = (list, parentId, key) => {
    let map = {}, node, roots = [], i;
    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }
    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node[key] !== parentId) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parent_id]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
};

export const validateEmail = (email) => {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
};

export const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
};

export const successAlert = (self, title, content) => {
    self.$swal.fire({
        icon: 'success',
        title: title,
        html: content,
        reverseButtons: true,
        confirmButtonText: i18n.t('ok'),
        showConfirmButton: true,
    })
};

export const waringAlert = (self, content) => {
    return self.$swal.fire({
        title: i18n.t('warning'),
        html: content,
        icon: 'warning',
        showCancelButton: true,
        showCloseButton: true,
        reverseButtons: true,
        confirmButtonText: i18n.t('ok'),
        cancelButtonText: i18n.t('cancel')
    })
};

export const confirmAlert = (self, content) => {
    return self.$swal.fire({
        title: '',
        html: content,
        icon: 'info',
        showCancelButton: true,
        showCloseButton: true,
        reverseButtons: true,
        confirmButtonText: i18n.t('ok'),
        cancelButtonText: i18n.t('cancel')
    })
};

export const convertGameHundredBetRuleList = (json) => {
    let min = null, max = null;
    let newRule = [];
    for (let key in json) {
        let data = json[key];
        let newObj = {};
        newObj.id = Number(key);
        newObj.name = data.name;
        newObj.minBet = data.min_bet;
        newObj.maxBet = data.max_bet;
        if (data.min_bet < min || !min) {
            min = data.min_bet;
        }

        if (data.max_bet > max || !max) {
            max = data.max_bet;
        }

        newRule.push(newObj);
    }

    return {
        min: min, max: max, newRule: newRule
    };
};

export const getPokerClass = (suit, point) => {
    let suits = ["spades", "heart", "diamond", "club"]
    let num = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "j", "q", "k"];

    return `<i class="icon-big sprite sprite-${suits[suit]}_${num[point]}_min"></i>`
}

export const getDiceClass = (point) => {
    return `<i class="icon-big dice dice-${point}"></i>`
}

export const getFscClass = (point) => {
    return `<i class="icon-big fsc fsc-${point}"></i>`
}

export const getMjClass = (point) => {
    return `<i class="icon-big ml-1 mj mj-mah_jong_${point}"></i>`
}

export const getSuperCarClass = (num, big = true) => {
    return `<i class="${big ? 'icon-big' : 'icon-small'} super-car super-car-${num}"></i>`
}

export const getFspClass = (num, big = true) => {
    return `<i class="${big ? 'icon-big' : 'icon-small'} fsp fsp-${num}"></i>`
}

export const gameResultToPokerObj = (suit, point) => {
    return {suit: Number(suit), point: parseInt(point, 16)}
}

export function countPoint(pokerList) {
    let counts = [];
    pokerList
        .map(poker => poker.point)
        .forEach((point) => {
            counts[point] = (counts[point] || 0) + 1;
        });
    return counts;
}

export function countPointObj(boj) {
    let counts = {};
    boj.map(poker => poker.point)
        .forEach((point) => {
            counts[point] = (counts[point] || 0) + 1;
        });
    return counts;
}

export const getPokerCssString = (suits, number, gameType) => {
    let prefix = "sprite";

    let gfGameType = [105, 120, 124, 301, 303, 310, 312];

    if (gfGameType.indexOf(gameType) > -1) {
        let cardSuits = ["", "-spades", "-heart", "-diamond", "-club"];
        let cardNum = ["", "2", "3", "4", "5", "6", "7", "8", "9", "10", "j", "q", "k", "1"];

        return `${prefix} ${prefix + cardSuits[suits]}_${cardNum[number]}_min mr-1`;
    } else {
        let cardSuits = ["", "-spades", "-heart", "-diamond", "-club"];
        let cardNum = ["", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "j", "q", "k"];

        return `${prefix} ${prefix + cardSuits[suits]}_${cardNum[number]}_min mr-1`;
    }
};

export const getFrogCssString = (number) => {
    let prefix = "frog";

    let chartNum = ["", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

    return `${prefix} ${prefix}-${chartNum[number]} mr-1`;
};

export const geClCssString = (number) => {
    let prefix = "cl";

    let chartNum = ["", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

    return `${prefix} ${prefix}-${chartNum[number]} mr-1`;
};

export const getPaiGowCssString = (number) => {
    return `paigow paigow-paigow_${number} mr-1 icon-small`;
};

export const convertObjectToArray = (obj) => {
    let map = Object.keys(obj).map(function (key) {
        let option = obj[key];
        option.id = key;
        return option;
    });
    return CloneDeep(map);
};

export const listGroupBy = (list, key) => {
    return CloneDeep(list).reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

/**
 * 陣列 1 裡面有陣列 2的資料
 * @param {number[]} a1 陣列 1 大
 * @param {number[]} a2 陣列 2 小
 */
export const arrayContainsOtherArray = (a1, a2) => {
    return a2.every(item => a1.includes(item));
}

export const hasOneOf = (list, arr) => {
    return list.some(item => arr.indexOf(item) > -1);
}

export const ClientLanguageOption = () => {
    return [{value: "zh-TW", text: i18n.t('zhTW')}, {value: "zh-CN", text: i18n.t('zhCN')}, {
        value: "en",
        text: "English"
    }, {value: "vi", text: i18n.t('vi')}, {value: "hi", text: i18n.t('hi')},];
}

export const QueryRtpHtmlStr = (query) => {
    let htmlStr = `<span class="d-block text-small">試算擊殺率</span>
           <span class="d-block text-small">遊戲類型 : ${i18n.t('game.' + query.game_type)}</span>
           <span class="d-block text-small">RTP(啟動值) over_rtp : ${query.over_rtp}</span>
           <span class="d-block text-small">共用設定RTP最高值 max_rtp : ${query.max_rtp}</span>
           <span class="d-block text-small">共用設定擊殺率 kill_rate : ${query.kill_rate}</span>
           <span class="d-block text-small">目前RTP current_rtp : ${query.current_rtp}</span>
           <span class="d-block text-small border-top">結果 : ${query.final_kill_rate}%</span>
           `;

    if (query.max_odds) {
        htmlStr += `<span class="d-block text-small">觸發擊殺最高倍率 : ${query.max_odds}倍</span>`
    }

    return htmlStr
}

export const categoryOptions = [{value: 'arcade', text: '電子'}, {value: 'hundred', text: '百人'}, {
    value: 'battle',
    text: '對戰'
}, {value: 'vietnam', text: '越南'}, {value: 'india', text: '印度'},];

export const defaultFrontSetting = {
    is_hot: false, is_hot_icon: false, category_list: []
}

export function CloneDeep(obj){
    return JSON.parse(JSON.stringify(obj))
}
